import klydoApiClient from "../../api/klydo";
import { useMutation } from "@tanstack/react-query";

type FeaturedUpdateInput = {
  featured: boolean;
  featuredTimes: {
    start: number;
    end: number;
  };
};

export type AddFeaturePayload = FeaturedUpdateInput & {
  klydoIds: string[];
};

const useAddFeatureToKlydoMutation = () => {
  return useMutation({
    mutationKey: ["add-featured"],
    mutationFn: ({ klydoIds, featured, featuredTimes }: AddFeaturePayload) =>
      klydoApiClient.addFeaturedToKlydos({ klydoIds, featured, featuredTimes }),
  });
};

export default useAddFeatureToKlydoMutation;
