import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import klydoApiClient, { Klydo, KlydoSearchFilter } from "../../api/klydo";
import { useEffect, useRef } from "react";

export type SearchType = "TEXT" | "SIMILARITY";
export type DisplayType = "LIST" | "GRID";

const useApprovedKlydos = (
  query: string,
  limit: number,
  searchType: SearchType = "TEXT",
  filters?: KlydoSearchFilter,
  includeMeteData?: boolean,
  sortField?: string,
  sortDirection?: number,
) => {
  const queryClient = useQueryClient();
  const dataRef = useRef<Klydo[]>();

  const { data, fetchNextPage, hasNextPage, ...rest } = useInfiniteQuery<
    Klydo[],
    Error
  >({
    queryKey: [
      "klydos",
      query,
      searchType,
      filters?.pool,
      filters?.unlisted,
      filters?.notScheduled,
      filters?.notPool,
      limit,
      sortField,
      sortDirection,
    ],
    queryFn: async ({ pageParam }) => {
      let sortOptions = undefined;
      if (sortField && sortDirection) {
        sortOptions = { [sortField]: sortDirection };
      }
      const res: Klydo[] =
        searchType === "TEXT"
          ? await klydoApiClient.approvedTextSearch(
              query,
              limit,
              (pageParam as { skip?: number })?.skip || 0,
              includeMeteData || false,
              filters || {},
              sortOptions,
            )
          : await klydoApiClient.similaritySearch(query, {
              ...(filters || {}),
              excludeIds:
                (pageParam as { excludeIds: string[] })?.excludeIds || [],
            });

      res.forEach((klydo) => {
        queryClient.setQueryData(["klydo", klydo.id], klydo);
      });

      return res;
    },
    getNextPageParam: (lastPage, allPages) => {
      if (searchType === "TEXT") {
        const currentLength = allPages.flat().length;
        return currentLength < limit ? undefined : { skip: currentLength };
      } else {
        const previous = allPages.flat();
        return { excludeIds: previous.map((klydo) => klydo.id) };
      }
    },
    initialPageParam: searchType === "TEXT" ? { skip: 0 } : { excludeIds: [] },
  });

  const flatData = data?.pages.flat();

  useEffect(() => {
    dataRef.current = flatData;
  }, [flatData]);

  return { data: flatData, dataRef, fetchNextPage, hasNextPage, ...rest };
};

export default useApprovedKlydos;
