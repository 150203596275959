import { useState } from "react";
import { ClientDetails, Device } from "../../../Types";
import DSDialogTitle from "../../system-design/DSDialogTitle";
import DSTypography from "../../system-design/DSTypography";
import DSDialogContent from "../../system-design/DSDialogContent";
import DSBox from "../../system-design/DSBox";
import DSTextField from "../../system-design/DSTextField";
import DSPrimaryButton from "../../system-design/DSPrimaryButtom";
import useUpdateClientDetailsMutation from "../hooks/useUpdateClientDetailsMutaion";
import DSMenuItem from "../../system-design/DSMenuItem";
import DSSelect from "../../system-design/DSSelect";
import { SelectChangeEvent } from "@mui/material";
import { Modal } from "react-bootstrap";

export type RegisterDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  device: Device;
};

const RegisterDetailsModal = ({
  open,
  onClose,
  device,
}: RegisterDetailsModalProps) => {
  const [registerDetailes, setRegisterDetailes] = useState<ClientDetails>({
    email: device?.clientDetails?.email || "",
    name: device?.clientDetails?.name || "",
    city: device?.clientDetails?.city || "",
    state: device?.clientDetails?.state || "",
    country: device?.clientDetails?.country || "",
    accept: device?.clientDetails?.accept || "false",
  });

  const { mutate: updateClientDetailsMutation } =
    useUpdateClientDetailsMutation();

  const handleInputChange =
    (field: keyof ClientDetails) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRegisterDetailes({
        ...registerDetailes,
        [field]: event.target.value,
      });
    };

  const handleSelectChange =
    (field: keyof ClientDetails) => (event: SelectChangeEvent<unknown>) => {
      setRegisterDetailes({
        ...registerDetailes,
        [field]: event.target.value as string,
      });
    };

  const handleSave = () => {
    updateClientDetailsMutation({
      device,
      clientDetails: registerDetailes,
    });
    onClose();
  };

  return (
    <Modal show={open} onHide={onClose} fullWidth maxWidth="sm">
      <DSDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Register Device
      </DSDialogTitle>
      <DSDialogContent>
        <DSTypography variant="subtitle1" gutterBottom>
          Client details (optional):
        </DSTypography>
        <DSBox display="flex" flexDirection="column" gap={2}>
          <DSTextField
            label="Country"
            value={registerDetailes.country}
            onChange={handleInputChange("country")}
            fullWidth
          />
          <DSTextField
            label="State"
            value={registerDetailes.state}
            onChange={handleInputChange("state")}
            fullWidth
          />
          <DSTextField
            label="City"
            value={registerDetailes.city}
            onChange={handleInputChange("city")}
            fullWidth
          />
          <DSTextField
            label="Email"
            value={registerDetailes.email}
            onChange={handleInputChange("email")}
            fullWidth
          />
          <DSTextField
            label="Name"
            value={registerDetailes.name}
            onChange={handleInputChange("name")}
            fullWidth
          />
          <DSSelect
            label="Accept"
            value={registerDetailes.accept}
            onChange={handleSelectChange("accept")}
            fullWidth
          >
            <DSMenuItem value="true">True</DSMenuItem>
            <DSMenuItem value="false">False</DSMenuItem>
          </DSSelect>
        </DSBox>
        <DSBox display="flex" justifyContent="flex-end" mt={2}>
          <DSPrimaryButton onClick={handleSave}>Save</DSPrimaryButton>
        </DSBox>
      </DSDialogContent>
    </Modal>
  );
};

export default RegisterDetailsModal;
