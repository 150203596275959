import { useQuery } from "@tanstack/react-query";
import klydoApiClient from "../../api/klydo";
import { Klydo } from "../../Types";

const useKlydo = (klydoId: string) => {
  return useQuery<Klydo>({
    queryKey: ["klydo", klydoId],
    queryFn: () => klydoApiClient.getKlydoById(klydoId),
  });
};

export default useKlydo;
