import { useState } from "react";
import firebaseService from "../../../firebase_service/firebaseService";
import useDeleteClockGiftMutation from "./useDeleteClockGiftMutation";
import useDeviceGiftQuery from "./useDeviceGiftQuery";
import { Klydo } from "../../../Types";
import useDeviceQuery from "./useDeviceQuery";

const useDeviceModal = (id: string) => {
  const { data: machineData, isLoading } = useDeviceQuery(id);
  const [showKlydoViewingModal, setShowKlydoViewingModal] = useState(false);
  const { data: gift } = useDeviceGiftQuery(id);
  const { mutateAsync: deleteGift } = useDeleteClockGiftMutation(id);
  const [updateDevApp, setUpdateDevApp] = useState(false);
  const [updateVersion, setUpdateVersion] = useState(
    firebaseService.general?.last,
  );
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateAppLoader, setUpdateAppLoader] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [warning, setWarning] = useState("");
  const [klydoAnalyticData, setKlydoAnalyticData] = useState<
    Array<{
      clockTime: Date;
      displayTime: number;
      klydo?: Klydo;
      autoSwitch: boolean;
      favorite: boolean;
      collectionType: string;
    }>
  >();
  const [tableId] = useState("klydo-history");
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);

  function handleRef() {
    const ref = "https://klydo-editor.web.app/klydo/" + machineData?.klydo?.id;
    return ref as string;
  }

  return {
    data: machineData,
    isLoading,
    showKlydoViewingModal,
    setShowKlydoViewingModal,
    gift,
    deleteGift,
    hideDev: machineData?.hideDev?.value === false ? false : true,
    currentKlydo:
      machineData?.klydo?.id || "f90e5246-a4b2-4bf5-81ee-5f8d22241074",
    timeOffset: machineData?.timeOffset ? machineData.timeOffset.value : 0,
    updateDevApp,
    setUpdateDevApp,
    updateVersion,
    setUpdateVersion,
    showUpdateModal,
    setShowUpdateModal,
    updateAppLoader,
    setUpdateAppLoader,
    schedualOn: machineData?.nightMode
      ? machineData.nightMode.value === "SCHEDULE"
      : false,
    showRemoveModal,
    setShowRemoveModal,
    warning,
    setWarning,
    klydoAnalyticData,
    setKlydoAnalyticData,
    tableId,
    showRegisterDialog,
    setShowRegisterDialog,
    handleRef,
  };
};

export default useDeviceModal;
