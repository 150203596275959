import { useMutation, useQueryClient } from "@tanstack/react-query";
import packApiClient from "../../../api/pack";

const useRemovePackMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["packs"],
    mutationFn: (packId: string) => packApiClient.deletePack(packId),
    onSuccess: () => queryClient.refetchQueries({ queryKey: ["packs"] }),
  });
};

export default useRemovePackMutation;
