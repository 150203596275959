import { DraftPack } from "../../../Types";
import DSBox from "../../system-design/DSBox";
import DSModal from "../../system-design/DSModal";
import DSTypography from "../../system-design/DSTypography";
import usePackReviewModal from "../hooks/usePackReviewModal";
import { DSBaseTable, DSTableCell } from "../../system-design/Table";
import KlydoLoopUrl from "../../Klydos/components/KlydoLoopUrl";
import { resizeCloudinary } from "../../../utils/util";
import { Klydo } from "../../../api/klydo";
import { Column } from "../../system-design/Table/DSBaseTable/components/DSBaseTable";
import KlydoReviewFormModal from "../../../components/forms/ReviewFormModal";
import DSSecondaryButton from "../../system-design/DSSecondaryButton";
import DSPrimaryButton from "../../system-design/DSPrimaryButtom";

type PackReviewModalProps = {
  pack: DraftPack;
  onClose: () => void;
  isModalOpen: boolean;
};
const PackReviewModal = ({
  pack,
  isModalOpen,
  onClose,
}: PackReviewModalProps) => {
  const {
    isFormModalOpen,
    handleFormModalClose,
    handleSubmitReview,
    columns,
    handleRejectButtonClick,
    handlePublishPackClick,
  } = usePackReviewModal({ onCloseReviewModal: onClose, pack });

  const buildTableRows = (
    klydo: Klydo,
    columns: Column<Klydo>[],
  ) => (
    <>
      {columns.map((col) => (
        <DSTableCell
          key={col.field as string}
          style={{
            width: col.size || `${100 / columns?.length}%`,
            whiteSpace: "nowrap",
            height: "10%",
          }}
        >
          {col.field === "loopUrl" ? (
            <DSBox
              sx={{
                width: 75,
                height: 75,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <KlydoLoopUrl loopUrl={resizeCloudinary(klydo.loopUrl, 96)} />
            </DSBox>
          ) : (
            String(klydo[col.field])
          )}
        </DSTableCell>
      ))}
    </>
  );
  return (
    <DSModal open={isModalOpen} onClose={onClose}>
      <DSBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          overflowY: "auto",
          height: "85%",
          width: "60%",
          p: 4,
          alignContent: "space-between",
        }}
      >
        <DSTypography variant="h6">{pack.name}</DSTypography>
        <DSTypography variant="subtitle1">
          Status: {pack.reviewStatus?.state}
        </DSTypography>

        <DSBox sx={{ height: "80%", width: "100%", overflowY: "scroll" }}>
          {pack.klydos && (
            <DSBaseTable
              data={pack.klydos || []}
              columns={columns}
              rowBuilder={buildTableRows}
            />
          )}
        </DSBox>
        <DSBox
          sx={{
            marginTop: "5%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: "40%",
            width: "20%",
          }}
        >
          <DSSecondaryButton onClick={() => handleRejectButtonClick()}>
            Reject
          </DSSecondaryButton>
          <DSPrimaryButton onClick={() => handlePublishPackClick()}>
            Publish
          </DSPrimaryButton>
        </DSBox>
        <KlydoReviewFormModal
          open={isFormModalOpen}
          handleClose={handleFormModalClose}
          review={pack.reviewStatus}
          handleSubmitReview={handleSubmitReview}
        />
      </DSBox>
    </DSModal>
  );
};

export default PackReviewModal;
