import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import firebaseService from "../firebase_service/firebaseService";
import { Klydo } from "../Types";
import {
  DeviceCommand,
  isVideo,
  MODAL_ACTIONS_INFO,
  resizeCloudinary,
} from "../utils/util";
import { BsCheck } from "react-icons/bs";
import LogcatStreamModal from "./LogcatStreamModal";
import DSButton from "./system-design/DSButtom";
import KlydosPickDialog from "./Gifts/GiftCategories/components/KlydosPickDialog";
import DSDialog from "./system-design/DSDialog";
import DSDialogTitle from "./system-design/DSDialogTitle";
import DSDialogContent from "./system-design/DSDialogContent";
import DSDialogActions from "./system-design/DSDialogActions";
import useKlydo from "../components/hooks/useKlydo";

function ScheduleBulkTaskModal({
  open,
  handleClose,
  action,
  selectedDevices,
}: {
  open: boolean;
  handleClose: (b: boolean) => void;
  action: DeviceCommand;
  selectedDevices: Array<string>;
}) {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    dateError: "",
    klydoError: "",
    serverError: "",
  });
  const [previewKlydo, setPreviewKlydo] = useState<Klydo>();
  const [params, setParams] = useState<Array<any>>([]);
  const [inputText, setInputText] = useState("");
  const [checkExpire, setCheckExpire] = useState<boolean>(false);
  const [expireDay, setExpireDay] = useState(0);
  const [expireHrs, setExpireHrs] = useState(1);
  const [expireMin, setExpireMin] = useState(0);
  const [showCmdSummary, setShowCmdSummary] = useState(false);
  const [isKlydoModalOpen, setIsKlydoModalOpen] = useState(false);
  const [klydoId, setKlydoId] = useState<string>("");

  const { data: klydo } = useKlydo(klydoId || "");

  useEffect(() => {
    if (klydo) {
      setPreviewKlydo(klydo);
      setValidated(true);
    }
  }, [klydo]);

  const handlePickKlydo = (selectedKlydos: string[]) => {
    setKlydoId(selectedKlydos[0]);
  };

  useEffect(() => {
    if (!open) {
      setParams([]);
      setErrors({ dateError: "", klydoError: "", serverError: "" });
      setPreviewKlydo(undefined);
      setValidated(false);
      setCheckExpire(false);
      setLoading(false);
      setInputText("");
      setExpireDay(0);
      setExpireMin(0);
      setExpireHrs(1);
    }
  }, [open]);

  useEffect(() => {
    let klydo: Klydo | undefined = undefined;
    let validated = false;
    let idf = inputText.split("-").length === 3;
    firebaseService
      .findItem<Klydo>("klydos", idf ? "idf" : "id", inputText)
      .then((v) => {
        klydo = v;
        validated = klydo !== undefined;
        setValidated(validated);

        setErrors((errors) =>
          validated || inputText.length === 0
            ? { ...errors, klydoError: "" }
            : { ...errors, klydoError: "There is no such klydo" },
        );
        if (validated) setPreviewKlydo(klydo!);
      });
  }, [inputText]);
  const [isLogcatOpen, setIsLogcatOpen] = useState(false);

  function getHeadline(action: DeviceCommand) {
    return MODAL_ACTIONS_INFO[action].headline;
  }
  //
  function handleLogcat() {
    setLoading(true);
    let localParams = ["wss://clock-socket-917257785826.us-central1.run.app"];
    let id = firebaseService.genFbID();
    const deviceId = selectedDevices[0];
    firebaseService
      .sendCommandToClocksByBatch({
        id: id,
        name: MODAL_ACTIONS_INFO[action].command,
        local: false,
        devices: [deviceId],
        date: new Date(),
        params: localParams,
      })
      .catch((error) => {
        console.log("Failed to schedule command: " + error);
        setErrors({
          ...errors,
          serverError: "Failed to schedule command: " + error,
        });
        handleClose(true);
      })
      .finally(() => {
        setLoading(false);
      });

    setIsLogcatOpen(true);
  }
  function handleSubmit() {
    setLoading(true);
    let expire;
    let localParams = params;
    if (action === DeviceCommand.PREVIEW || action === DeviceCommand.DELETE)
      localParams = params.map((klydo) => klydo.id);
    if (checkExpire) expire = expireDay * 24 * 60 + expireHrs * 60 + expireMin;
    let id = firebaseService.genFbID();

    firebaseService
      .sendCommandToClocksByBatch({
        id: id,
        name: MODAL_ACTIONS_INFO[action].command,
        local: false,
        devices: selectedDevices,
        expire: expire,
        date: new Date(),
        params: localParams,
      })
      .then(() => {
        handleClose(false);
        setShowCmdSummary(false);
      })
      .catch((error) => {
        console.log("Failed to schedule command: " + error);
        setErrors({
          ...errors,
          serverError: "Failed to schedule command: " + error,
        });
        handleClose(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const ActionButton = () => {
    return (
      <div style={{ width: "fit-content" }}>
        <Button
          disabled={
            action ===
              (DeviceCommand.DELETE || action === DeviceCommand.PREVIEW) &&
            params.length === 0
          }
          onClick={() => setShowCmdSummary(true)}
          style={{
            color: "#0d6efd",
            padding: 3,
            fontSize: "85%",
            height: "fit-content",
            width: "100%",
          }}
        >
          {getHeadline(action)}
        </Button>
      </div>
    );
  };

  return (
    <>
      {!showCmdSummary && !isLogcatOpen && (
        <DSDialog fullWidth open={open} onClose={() => handleClose(false)}>
          <DSDialogTitle>
            <i
              style={{ bottom: "7px", position: "relative" }}
              className="nc-icon nc-simple-remove"
              onClick={() => {
                handleClose(false);
              }}
            ></i>
            <h4 style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}>
              {getHeadline(action)}
            </h4>
          </DSDialogTitle>
          <DSDialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {action === DeviceCommand.DELETE && params.length > 0 && (
              <>
                <ListGroup
                  style={{ width: "90%", borderRadius: 0, border: "none" }}
                  className="border-0"
                >
                  <ListGroup.Item style={{ border: 0, height: "90%" }}>
                    <div
                      style={{ fontSize: "12px", color: "gray", border: 0 }}
                      className="d-flex w-100 justify-content-around"
                    >
                      <div style={{ width: 105 }} className="mb-1">
                        IDF
                      </div>
                      <div style={{ width: 105 }} className="mb-1">
                        Author
                      </div>
                      <div
                        style={{ width: 35, left: "7px", position: "relative" }}
                        className="mb-1"
                      >
                        GIF
                      </div>
                      <div
                        style={{
                          width: 12,
                          right: "13px",
                          position: "relative",
                        }}
                        className="mb-1"
                      >
                        Pool
                      </div>
                      <div style={{ width: 6 }} className="mb-1"></div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup
                  style={{
                    width: "90%",
                    borderRadius: 0,
                    border: "none",
                    maxHeight: "190px",
                    overflowY: "auto",
                  }}
                  className="border-0"
                >
                  {params.map((klydo) => (
                    <ListGroup.Item
                      key={klydo.id}
                      style={{ border: 0, paddingTop: 0, paddingBottom: 0 }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: 105 }} title={klydo.id}>
                          {klydo.idf}
                        </div>
                        <div style={{ width: 105 }} title={klydo.creator}>
                          {klydo.author}
                        </div>
                        {!klydo.loopUrl || isVideo(klydo.loopUrl) === "none" ? (
                          <div
                            style={{
                              width: 35,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p>--</p>
                          </div>
                        ) : (
                          <div
                            style={{
                              borderRadius: "50%",
                              width: 35,
                              height: 35,
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                position: "absolute",
                                top: 0 + "%",
                                left: 0 + "%",
                                width: `${100}%`,
                                aspectRatio: "1/1",
                              }}
                            >
                              {isVideo(klydo.loopUrl) === "image" ? (
                                <img
                                  loading="lazy"
                                  alt="gif"
                                  src={resizeCloudinary(klydo.loopUrl, 48)}
                                  style={{
                                    width: "auto",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <video
                                  muted={true}
                                  src={resizeCloudinary(klydo.loopUrl, 48)}
                                  loop
                                  autoPlay
                                  style={{
                                    width: "auto",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {klydo.pool ? (
                          <BsCheck
                            style={{ width: 12 }}
                            color="black"
                          ></BsCheck>
                        ) : (
                          <div style={{ width: 12 }}></div>
                        )}
                        <i
                          className="nc-icon nc-simple-remove"
                          style={{ width: 6 }}
                          onClick={() => {
                            let tmp = params.filter(
                              (k) => !(k.id === klydo.id),
                            );
                            setParams([...tmp]);
                          }}
                        ></i>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
            {!loading && (
              <Form
                onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                style={{ width: "90%", flexDirection: "column" }}
                className="d-flex justify-content-center"
              >
                {(action === DeviceCommand.PREVIEW ||
                  action === DeviceCommand.DELETE) && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <DSButton
                      type="button"
                      sx={{ color: "black", backgroundColor: "#f0f0f0" }}
                      onClick={() => {
                        setIsKlydoModalOpen(true);
                      }}
                    >
                      Select Klydos
                    </DSButton>
                    {isKlydoModalOpen && (
                      <KlydosPickDialog
                        selectedKlydos={klydoId ? [klydoId] : []}
                        onPickKlydos={handlePickKlydo}
                        onClose={() => setIsKlydoModalOpen(false)}
                        limit={1}
                      />
                    )}
                  </div>
                )}

                <div style={{ display: "flex", flexDirection: "column" }}>
                  {action !== DeviceCommand.LOGCAT && (
                    <label
                      title="Job expires if the clock does not receive it within the specified time frame"
                      style={{ width: "fit-content", marginTop: "7px" }}
                    >
                      <input
                        style={{ marginRight: "5px" }}
                        type="checkbox"
                        checked={checkExpire!}
                        onChange={() => setCheckExpire(!checkExpire)}
                      />
                      Expiration
                    </label>
                  )}
                  {checkExpire && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        width: "35%",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "32%",
                          fontSize: "x-small",
                        }}
                      >
                        <input
                          style={{ fontSize: "small", height: "100%" }}
                          value={expireDay}
                          type="number"
                          title="Days"
                          placeholder="day"
                          onChange={(e) =>
                            setExpireDay(parseInt(e.target.value))
                          }
                        />
                        Days
                      </label>
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "32%",
                          fontSize: "x-small",
                        }}
                      >
                        <input
                          style={{ fontSize: "small", height: "100%" }}
                          value={expireHrs}
                          type="number"
                          title="Hours"
                          defaultValue={expireHrs}
                          placeholder="hrs"
                          onChange={(e) =>
                            setExpireHrs(parseInt(e.target.value))
                          }
                        />
                        Hours
                      </label>
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "32%",
                          fontSize: "x-small",
                        }}
                      >
                        <input
                          style={{ fontSize: "small", height: "100%" }}
                          value={expireMin}
                          type="number"
                          title="Minutes"
                          placeholder="min"
                          onChange={(e) => {
                            setExpireMin(parseInt(e.target.value));
                          }}
                        />
                        Minutes
                      </label>
                    </div>
                  )}
                </div>
                {action === DeviceCommand.LOGCAT && (
                  <div>
                    <p>Are you sure about the action?</p>
                  </div>
                )}
              </Form>
            )}
          </DSDialogContent>

          <DSDialogActions
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div style={{ width: "fit-content" }}>
              <Button
                onClick={() => handleClose(false)}
                variant="outline-dark"
                style={{
                  padding: 3,
                  color: "#000000",
                  fontSize: "85%",
                  height: "fit-content",
                  width: "100%",
                }}
              >
                Cancel
              </Button>
            </div>
            {(action === DeviceCommand.UPDATE ||
              action === DeviceCommand.UPDATE_REMOTE ||
              action === DeviceCommand.ONBOARDING) && <ActionButton />}
            {(action === DeviceCommand.DELETE ||
              action === DeviceCommand.PREVIEW) && (
              <Button
                disabled={!validated || !previewKlydo}
                onClick={() => {
                  if (!params.includes(previewKlydo!)) {
                    params.push(previewKlydo!);
                    setParams((prev) => prev.slice());
                    setInputText("");
                  } else
                    setErrors({
                      ...errors,
                      klydoError: "klydo already listed",
                    });
                  setShowCmdSummary(true);
                }}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                {"send"}
              </Button>
            )}
            {action === DeviceCommand.SYNC && (
              <Button
                onClick={() => setShowCmdSummary(true)}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                Sync
              </Button>
            )}
            {action === DeviceCommand.SYNC_LOGS && (
              <Button
                onClick={() => setShowCmdSummary(true)}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                Sync Logs
              </Button>
            )}
            {action === DeviceCommand.UPDATE_LOG_CONFIG && (
              <Button
                onClick={() => setShowCmdSummary(true)}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                Update Log Config
              </Button>
            )}
            {action === DeviceCommand.BLUETOOTH && (
              <Button
                onClick={() => setShowCmdSummary(true)}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                Reset Bluetooth
              </Button>
            )}
            {action === DeviceCommand.LOGCAT && (
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  handleLogcat();
                }}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                Open Logcat
              </Button>
            )}
            {errors.serverError && <p>{errors.serverError}</p>}
          </DSDialogActions>
        </DSDialog>
      )}
      {isLogcatOpen && (
        <LogcatStreamModal
          deviceId={selectedDevices[0]}
          onClose={() => {
            setIsLogcatOpen(false);
            handleClose(false);
          }}
        />
      )}
      {showCmdSummary && (
        <Modal
          style={{}}
          show={showCmdSummary}
          onHide={() => setShowCmdSummary(false)}
          className="modal-medium"
        >
          <Modal.Body>
            <Row style={{ margin: 25 }}>
              <h4 style={{ textAlign: "center" }}>
                You are about to {getHeadline(action)} on{" "}
                {selectedDevices.length} devices.
              </h4>
              {checkExpire && (
                <p style={{ textAlign: "center" }}>
                  Command will expire for devices who wont receive it within the
                  selected time frame: <br /> {expireDay}d:{expireHrs}h:
                  {expireMin}m
                </p>
              )}
            </Row>
            {
              <Alert
                style={{ textAlign: "center" }}
                show={!!alert}
                variant="danger"
              >
                <h5 style={{ margin: 2 }}>This action cannot be undone</h5>
              </Alert>
            }
            <Row className="justify-content-between">
              <Col sm="5">
                <Button
                  variant="outline-dark"
                  className="w-100 h-100"
                  onClick={() => setShowCmdSummary(false)}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm="5">
                <Button
                  variant="outline-dark"
                  title="Submit cannot be undone"
                  className={`w-100 h-100 form-control ${!loading && "is-invalid"}`}
                  onClick={(event) => {
                    event.preventDefault();
                    handleSubmit();
                  }}
                >
                  {loading && <Spinner animation="border" size="sm" />}Submit
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default ScheduleBulkTaskModal;
