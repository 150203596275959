import { Avatar, useTheme } from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DSBox from "../../system-design/DSBox";
import DSTypography from "../../system-design/DSTypography";

const PackLogo = ({ logo }: { logo: string }) => {
  const { spacing, palette } = useTheme();

  if (!logo) {
    return (
      <DSBox
        sx={{
          display: "flex",
          cursor: "pointer",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: spacing(1),
        }}
      >
        <ImageOutlinedIcon
          sx={{
            height: spacing(5),
            width: spacing(5),
            color: palette.action.active,
          }}
        />
        <DSTypography fontSize={spacing(2)} color={palette.action.active}>
          Add Symbol
        </DSTypography>
      </DSBox>
    );
  }
  return (
    <Avatar
      src={logo}
      sx={{
        cursor: "pointer",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
      }}
    />
  );
};

export default PackLogo;
