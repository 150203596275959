import { useCallback, useState } from "react";
import { Klydo } from "../../../api/klydo";
import { Column } from "../../system-design/Table/DSBaseTable/components/DSBaseTable";
import { DraftPack, PackReviewStatus } from "../../../Types";
import usePublishPackMutation from "./usePublishPackMutation";
import { useUpdateDraftPackMutation } from "./useUpdatePackMutation";

export type UsePackReviewModalProps = {
  onCloseReviewModal: () => void;
  pack: DraftPack;
};

const usePackReviewModal = ({
  onCloseReviewModal,
  pack,
}: UsePackReviewModalProps) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const { mutate: publishPackMutation } = usePublishPackMutation();
  const { mutate: updatePackMutation } = useUpdateDraftPackMutation();
  const handleFormModalClose = useCallback(() => {
    setIsFormModalOpen(false);
  }, []);
  const handleSubmitReview = useCallback(
    async (message: string) => {
      updatePackMutation({
        ...pack,
        reviewStatus: { state: PackReviewStatus.Rejected, message },
      });
      setIsFormModalOpen(false);
    },
    [pack],
  );

  const handleRejectButtonClick = useCallback(() => {
    setIsFormModalOpen(true);
  }, []);

  const handlePublishPackClick = useCallback(async () => {
    try {
      await publishPackMutation({ packId: pack.id });
      onCloseReviewModal();
    } catch (error) {
      onCloseReviewModal();
    }
  }, []);

  const columns: Column<Klydo>[] = [
    { field: "name", label: "Name" },
    { field: "loopUrl", label: "LoopUrl" },
    { field: "authorName", label: "Author" },
    { field: "createdAt", label: "Created At" },
    { field: "id", label: "Klydo Id" },
  ];

  return {
    isFormModalOpen,
    handleFormModalClose,
    handleSubmitReview,
    columns,
    handleRejectButtonClick,
    handlePublishPackClick,
  };
};
export default usePackReviewModal;
