import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import packApiClient from "../../../api/pack";
import { DraftPack, ApprovedPack } from "../../../Types";

const useUpdatePackMutation = <T extends ApprovedPack | DraftPack>(
  updateFn: (packId: string, pack: Omit<T, "type">) => Promise<void>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["update-pack"],
    mutationFn: (pack: Omit<T, "type">) => {
      return updateFn(pack.id, { ...pack, klydos: undefined });
    },
    onMutate: async (pack: Omit<T, "type">) => {
      queryClient.setQueryData(["pack", pack.id], pack);

      const matchingQueries = queryClient
        .getQueryCache()
        .findAll({ queryKey: ["packs"] });

      matchingQueries.forEach(({ queryKey }) => {
        queryClient.setQueryData<InfiniteData<T[]>>(queryKey, (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            pages: oldData.pages.map((page) =>
              page.map((oldPack) =>
                oldPack.id === pack.id ? { ...oldPack, ...pack } : oldPack,
              ),
            ),
          };
        });
      });
    },
    onSettled: () =>
      queryClient.refetchQueries({
        queryKey: ["packs"],
      }),
  });
};

export const useUpdateDraftPackMutation = () => {
  return useUpdatePackMutation<DraftPack>(packApiClient.upsertDraftPack);
};
export const useUpdateApprovedPackMutation = () => {
  return useUpdatePackMutation<ApprovedPack>(packApiClient.upsertApprovedPack);
};
