import { Avatar, useTheme } from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DSBox from "../../system-design/DSBox";
import DSTypography from "../../system-design/DSTypography";

const PackCover = ({ cover }: { cover: string }) => {
  const { spacing, palette } = useTheme();
  if (!cover) {
    return (
      <DSBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          gap: spacing(1),
        }}
      >
        <ImageOutlinedIcon
          sx={{
            height: spacing(7),
            width: spacing(7),
            color: palette.action.active,
          }}
        />
        <DSTypography fontSize={spacing(2.5)} color={palette.action.active}>
          Add Cover
        </DSTypography>
      </DSBox>
    );
  }
  return (
    <Avatar
      src={cover}
      sx={{
        cursor: "pointer",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        borderRadius: spacing(13),
      }}
    />
  );
};
export default PackCover;
