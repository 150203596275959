import React, { useState } from "react";
import { Input, useTheme } from "@mui/material";
import PackCover from "./PackCover";
import PackLogo from "./PackLogo";
import DSBox from "../../system-design/DSBox";
import DSTextField from "../../system-design/DSTextField";
import DSLabelTypography from "../../system-design/DSLabelTypography";
import DSTypography from "../../system-design/DSTypography";
import { ListGroup } from "react-bootstrap";
import KlydoListItem from "../../KlydoListItem";
import DSPrimaryButton from "../../system-design/DSPrimaryButtom";
import KlydosPickDialog from "../../Gifts/GiftCategories/components/KlydosPickDialog";
import packBackgroundLogo from "../images/pack-background.png";
import UserDropdown from "./UserDropdown";
export const PACK_DESCRIPTION_LIMIT = 400;
export const PACK_NAME_LIMIT = 20;

export type PackFormProps = {
  name: string;
  setName: (name: string) => void;
  description: string;
  klydosIds: string[];
  setKlydosIds: React.Dispatch<React.SetStateAction<string[]>>;
  setDescription: (description: string) => void;
  cover: string;
  setCover: (cover: string) => void;
  logo: string;
  setLogo: (logo: string) => void;
  handleFileChange: (
    folder: string,
    event: React.ChangeEvent<HTMLInputElement>,
    setImage: (url: string) => void,
  ) => void;
  onUserSelect: (value: string) => void;
  creatorId: string;
};

const PackForm: React.FC<PackFormProps> = ({
  name,
  setName,
  description,
  setDescription,
  cover,
  klydosIds,
  setKlydosIds,
  setCover,
  logo,
  setLogo,
  handleFileChange,
  onUserSelect,
  creatorId,
}) => {
  const { spacing, palette } = useTheme();
  const [addKlydosDialogOpen, setAddKlydosDialogOpen] = useState(false);

  return (
    <>
      <DSBox
        sx={{
          padding: spacing(3),
          display: "flex",
          flexDirection: "column",
          gap: spacing(3),
        }}
      >
        <DSBox
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: spacing(2),
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DSBox
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: spacing(1),
                alignItems: "center",
              }}
            >
              <DSLabelTypography>Add a Pack Cover</DSLabelTypography>
            </DSBox>
            <DSBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: spacing(40),
                height: spacing(40),
                borderRadius: spacing(13),
                background: palette.action.disabledBackground,
                cursor: "pointer",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <label
                htmlFor="upload-input"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              >
                <PackCover cover={cover} />
              </label>
              <Input
                id="upload-input"
                type="file"
                inputProps={{ accept: ".png, .jpeg, .jpg" }}
                style={{ display: "none" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFileChange("pack_covers", e, setCover);
                }}
              />
            </DSBox>
          </DSBox>
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: spacing(88), lg: spacing(90), xl: spacing(135) },
              gap: spacing(3),
            }}
          >
            <DSBox
              sx={{ display: "flex", flexDirection: "row", gap: spacing(5) }}
            >
              <DSBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: spacing(2),
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <DSBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: spacing(1),
                    alignItems: "center",
                  }}
                >
                  <DSLabelTypography>Add a Pack Symbol</DSLabelTypography>
                </DSBox>

                <DSBox
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: spacing(25),
                    height: spacing(25),
                    borderRadius: "50%",
                    cursor: "pointer",
                    overflow: "hidden",
                    backgroundImage: `url(${packBackgroundLogo})`,
                  }}
                >
                  <label
                    htmlFor="upload-logo-input"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <PackLogo logo={logo} />
                  </label>
                  <Input
                    id="upload-logo-input"
                    type="file"
                    inputProps={{ accept: ".png, .jpeg, .jpg" }}
                    style={{ display: "none" }}
                    onChange={(e) =>
                      handleFileChange(
                        "pack_logos",
                        e as React.ChangeEvent<HTMLInputElement>,
                        setLogo,
                      )
                    }
                  />
                </DSBox>
              </DSBox>
              <DSBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: spacing(1),
                  width: "100%",
                  height: "auto",
                }}
              >
                <DSBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: spacing(1),
                  }}
                >
                  <DSLabelTypography>Name Your Pack</DSLabelTypography>
                  <DSTextField
                    value={name}
                    placeholder="My Klydo Pack"
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    slotProps={{
                      input: {
                        inputProps: { maxLength: PACK_NAME_LIMIT },
                      },
                    }}
                  />
                </DSBox>
                <DSLabelTypography>Pack Description</DSLabelTypography>
                <DSTextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  rows={6}
                  fullWidth
                  placeholder="My Pack is special since..."
                  slotProps={{
                    input: {
                      inputProps: { maxLength: PACK_DESCRIPTION_LIMIT },
                    },
                  }}
                />
              </DSBox>
            </DSBox>
          </DSBox>
        </DSBox>
        <DSBox
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            gap: spacing(4),
          }}
        >
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: spacing(1),
              width: "100%",
            }}
          >
            <DSLabelTypography>Price</DSLabelTypography>
            <DSTextField disabled fullWidth placeholder="$ --" />
          </DSBox>
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: spacing(1),
              width: "100%",
            }}
          >
            <DSLabelTypography>Location</DSLabelTypography>
            <DSTextField disabled fullWidth placeholder="Global" />
          </DSBox>
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: spacing(1),
              width: "100%",
            }}
          >
            <DSLabelTypography>Duration</DSLabelTypography>
            <DSTextField disabled fullWidth placeholder="Always" />
          </DSBox>
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: spacing(1),
              width: "100%",
            }}
          >
            <DSLabelTypography>Visibility</DSLabelTypography>
            <DSTextField disabled fullWidth placeholder="Public" />
          </DSBox>
        </DSBox>
        <DSBox>
          <DSTypography>Klydos:</DSTypography>
          <ListGroup
            style={{
              width: "50%",
              borderRadius: 0,
              border: "none",
              maxHeight: "190px",
              overflowY: "auto",
            }}
            className="border-0"
          >
            {klydosIds?.map((klydoId) => (
              <KlydoListItem
                canRemove
                key={klydoId}
                klydoId={klydoId}
                onRemove={(klydoId) =>
                  setKlydosIds((prev) => prev.filter((id) => id !== klydoId))
                }
              />
            ))}
          </ListGroup>
          <DSPrimaryButton
            onClick={() => setAddKlydosDialogOpen(true)}
            sx={{ my: 3 }}
          >
            Add
          </DSPrimaryButton>
        </DSBox>
        <DSBox>
          <DSTypography>Creator</DSTypography>
          <UserDropdown id={creatorId} onOptionSelect={onUserSelect} />
        </DSBox>
      </DSBox>
      {addKlydosDialogOpen && (
        <KlydosPickDialog
          selectedKlydos={klydosIds}
          onClose={() => setAddKlydosDialogOpen(false)}
          onPickKlydos={(klydos) => {
            setKlydosIds(klydos);
            setAddKlydosDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default PackForm;
