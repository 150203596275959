import { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import KlydoListItem from "../../../KlydoListItem";
import { GiftCategory } from "../../../../api/giftCategories";
import { Klydo } from "../../../../Types";
import useUpdateCategoryMutation from "../hooks/useUpdateCategoryMutation";
import uploadFile from "../../../../utils/uploadFiles";
import ButtonLoader from "../../../../components/Widgets/ButtonLoader";
import KlydosPickDialog from "./KlydosPickDialog";
import DSButton from "../../../system-design/DSButtom";

const GiftCategoryModal = (props: {
  row?: GiftCategory;
  setShowModal: (b: boolean) => void;
}) => {
  const [giftCategoryImageUrl, setGiftCategoryImageUrl] = useState<string>(
    props?.row?.categoryImageUrl || "",
  );
  const [name, setName] = useState<string>(props.row?.name || "");
  const [draftKlydoIds, setDraftKlydoIds] = useState<string[]>(
    props?.row?.klydoIds || [],
  );

  const [removedKlydoIds, setRemovedKlydoIds] = useState<string[]>([]);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [isKlydoModalOpen, setIsKlydoModalOpen] = useState(false);
  const { mutate: updateCategoryMutation } = useUpdateCategoryMutation();

  const handleRemoveKlydo = (klydoId: string) => {
    removedKlydoIds.push(klydoId);
    setRemovedKlydoIds((prev) => prev.slice());
    if (draftKlydoIds) {
      draftKlydoIds.splice(draftKlydoIds.indexOf(klydoId), 1);
    }
    setDraftKlydoIds((prev) => prev?.slice());
  };

  const handleKlydoSelection = (selected: string[]) => {
    setDraftKlydoIds(selected);
  };

  const saveGiftCategory = async () => {
    updateCategoryMutation({
      _id: props.row!._id!,
      name: name,
      categoryImageUrl: giftCategoryImageUrl,
      klydoIds: draftKlydoIds,
    });
  };

  return (
    <>
      <Modal.Header
        style={{
          padding: 0,
          marginLeft: "10px",
          marginRight: "5px",
          marginBottom: "24px",
        }}
      >
        <h4 style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}>
          {"Gift Category"}
        </h4>
        <i
          style={{ bottom: "7px", position: "relative" }}
          className="nc-icon nc-simple-remove"
          onClick={() => {
            props.setShowModal(false);
          }}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row
            style={{
              paddingBottom: 8,
            }}
          >
            <Col>
              <Form noValidate onSubmit={saveGiftCategory} id="newPackForm">
                <Row>
                  <Col sm="6" style={{ width: "100%" }}>
                    <Form.Group
                      controlId="formAddName"
                      style={{ display: "flex" }}
                    >
                      <Form.Label style={{ padding: 5, width: "20%" }}>
                        <b>Gift Category's Name:</b>
                      </Form.Label>
                      <Col>
                        <Form.Control
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="name"
                          placeholder="Enter name..."
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Gift Category logo</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = (e.target as HTMLInputElement)
                            ?.files?.[0];
                          if (file) {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                              uploadFile({
                                file: fileReader.result as string,
                                folder: "giftCategories",
                                upload_preset: "packpendulumconvers",
                              }).then((url) =>
                                setGiftCategoryImageUrl(url as string),
                              );
                            };
                            fileReader.readAsDataURL(file);
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Col>
                  <Form.Label style={{ padding: 5, width: "10%" }}>
                    <b>Klydos:</b>
                  </Form.Label>
                  {draftKlydoIds && draftKlydoIds.length > 0 ? (
                    <div>
                      <ListGroup
                        style={{
                          width: "50%",
                          borderRadius: 0,
                          border: "none",
                        }}
                        className="border-0"
                      >
                        <ListGroup.Item style={{ border: 0, height: "90%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "12px",
                              color: "gray",
                              border: 0,
                            }}
                          >
                            <div style={{ width: 110 }} className="mb-1">
                              IDF
                            </div>
                            <div style={{ width: 110 }} className="mb-1">
                              Author
                            </div>
                            <div
                              style={{
                                width: 35,
                                left: "7px",
                                position: "relative",
                              }}
                              className="mb-1"
                            >
                              GIF
                            </div>
                            <div
                              style={{ width: 25, position: "relative" }}
                              className="mb-1"
                            >
                              Pool
                            </div>
                            <div style={{ width: 6 }} className="mb-1"></div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                      <ListGroup
                        style={{
                          width: "50%",
                          borderRadius: 0,
                          border: "none",
                          maxHeight: "190px",
                          overflowY: "auto",
                        }}
                        className="border-0"
                      >
                        {draftKlydoIds?.map((klydoId) => (
                          <KlydoListItem
                            canRemove
                            key={klydoId}
                            klydoId={klydoId}
                            onRemove={handleRemoveKlydo}
                          />
                        ))}
                      </ListGroup>
                    </div>
                  ) : draftKlydoIds ? (
                    <p>No klydos in draft</p>
                  ) : (
                    <Spinner />
                  )}
                  <Form
                    style={{ width: "90%", flexDirection: "column" }}
                    className="d-flex justify-content-center"
                  >
                    {draftKlydoIds && (
                      <div
                        style={{
                          width: "90%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <DSButton
                          type="button"
                          sx={{ color: "black", backgroundColor: "#f0f0f0" }}
                          onClick={() => {
                            setIsKlydoModalOpen(true);
                          }}
                        >
                          Select Klydos
                        </DSButton>
                        {isKlydoModalOpen && (
                          <KlydosPickDialog
                            selectedKlydos={draftKlydoIds}
                            onPickKlydos={handleKlydoSelection}
                            onClose={() => setIsKlydoModalOpen(false)}
                          />
                        )}
                      </div>
                    )}
                  </Form>
                </Col>
                <Form.Group
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "5px",
                  }}
                >
                  <Button
                    style={{ color: "#4183a8" }}
                    disabled={!name || !giftCategoryImageUrl || !draftKlydoIds}
                    onClick={() => {
                      setShowSaveModal(true);
                    }}
                  >
                    Save
                  </Button>
                  {showSaveModal && (
                    <Modal
                      onHide={() => setShowSaveModal(false)}
                      className="modal-medium"
                      show={true}
                    >
                      <Modal.Body>
                        <Row style={{ margin: 25 }}>
                          <h4 style={{ textAlign: "center" }}>
                            You are about to save changes for {name}
                          </h4>
                        </Row>
                        <Row className="justify-content-between">
                          <Col sm="5">
                            <Button
                              className="w-100 h-100"
                              onClick={() => setShowSaveModal(false)}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col sm="5">
                            <ButtonLoader
                              variant="outline-danger"
                              className="w-100 h-100"
                              title="Save"
                              onClick={() =>
                                saveGiftCategory().then(() => {
                                  setShowSaveModal(false);
                                  props.setShowModal(false);
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </>
  );
};

export default GiftCategoryModal;
