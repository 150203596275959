import firebaseConfig from "./firebaseServiceConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import id from "../friendly-id/id-gen";
import {
  Klydo,
  Log,
  Version,
  Stats,
  KlydoTimes,
  User,
  Device,
  Tag,
  ScheduledTask,
  Command,
  DeviceValue,
  CommandHistory,
  General,
  CommandServer,
  Kpi,
  DeviceReport,
  SpecialPack,
} from "Types";
import {
  arrayUnion,
  arrayRemove,
  DocumentData,
  WhereFilterOp,
} from "firebase/firestore";
import axios from "axios";
import JSZip from "jszip";
import klydoApiClient, { Theme } from "../api/klydo";

const dataRefreshTime = 300_000;
type DataType =
  | Device
  | DeviceReport
  | Log
  | Stats
  | Version
  | Tag
  | CommandServer
  | ScheduledTask
  | User
  | General
  | SpecialPack;
type Listener<T> = (dt: T[]) => void;
class DataHandler<T extends DataType> {
  dependencies: Collection[];
  data?: T[];
  parser: (data: any) => T;
  listeners: Listener<T>[] = [];
  getter: (dh: DataHandler<T>, data: DataType[][]) => Promise<T[]>;
  key: string;
  lastDownloadedData = 0;
  trigger() {
    if (this.data) {
      for (const l of this.listeners) {
        l(this.data);
      }
    }
  }

  async load() {
    if (new Date().getTime() - this.lastDownloadedData < dataRefreshTime) {
      if (this.data) return;
      return new Promise<void>((v, x) => {
        const inv = setInterval(() => {
          if (this.data) {
            clearInterval(inv);
            v();
          }
        }, 500);
      });
    }
    this.lastDownloadedData = new Date().getTime();
    await Promise.all(this.dependencies.map((k) => instance.data[k].load()));
    this.data = await this.getter(
      this,
      this.dependencies.map((k) => instance.data[k].data!),
    );
    for (const l of this.listeners) {
      l(this.data);
    }
  }
  constructor(
    key: Collection,
    getter: (dh: DataHandler<T>, data: DataType[][]) => Promise<T[]>,
    parser: (data: any) => T,
    dependencies: Collection[] = [],
  ) {
    this.key = key;
    this.getter = getter;
    this.dependencies = dependencies;
    this.parser = parser;
    setTimeout(
      () =>
        setInterval(() => {
          if (this.listeners.length) this.load();
        }, dataRefreshTime),
      dataRefreshTime,
    );
  }
}
type Collection =
  | "machines"
  | "machinesReport"
  | "errors"
  | "version"
  | "klydos"
  | "stats"
  | "reviews"
  | "users"
  | "tags2"
  | "scheduledpool"
  | "commandsHistory"
  | "packs"
  | "draftPacks"
  | "packsReviews";
class FirebaseService {
  db: firebase.firestore.Firestore;
  private storage: firebase.storage.Storage;
  private functions: firebase.functions.Functions;
  private auth: firebase.auth.Auth;
  data = {
    machines: new DataHandler<Device>(
      "machines",
      async (dh: DataHandler<Device>) => {
        const data = await this.db
          .collection("machines")
          .where("registerProduct.value", "==", true)
          .get();
        let machines: Device[] = data.docs.map((d) => {
          const data = d.data();
          data.id = d.id;
          return dh.parser(data);
        });
        return machines;
      },
      (data) => ({
        ...data,
        id: data.id,
        klydo: { id: data.klydo?.value, loopUrl: data.loopUrl },
        currentIdf: data.klydo && id.friendlyFromHex(data.klydo.value),
        registerProduct: this.toDate(data.registerProduct.time),
        heartbeat: this.toDate(data.heartbeat),
        clocktime: data.clocktime
          ? {
              local: data.clocktime.local,
              server: this.toDate(data.clocktime.server),
              timeZone: data.clocktime.timeZone,
            }
          : undefined,
        autoCreate: data.autoCreate ?? false,
      }),
    ),

    machinesReport: new DataHandler<DeviceReport>(
      "machinesReport",
      async (dh: DataHandler<DeviceReport>) => {
        const data = await this.db.collection("machinesReport").get();
        let machinesReport: DeviceReport[] = data.docs.map((d) => {
          const data = d.data();
          data.id = d.id;
          return dh.parser(data);
        });
        return machinesReport;
      },
      (data) => ({
        ...data,
        id: data.id,
        heartbeat: this.toDate(data.heartbeat),
        clocktime: data.clocktime
          ? {
              local: data.clocktime.local,
              server: this.toDate(data.clocktime.server),
              timeZone: data.clocktime.timeZone,
            }
          : undefined,
      }),
    ),
    errors: new DataHandler<Log>(
      "errors",
      async (dh: DataHandler<Log>) => {
        const data = await this.db
          .collection("errors")
          .where("dismissed", "==", false)
          .get();
        const logs = data.docs.map((d) => {
          const data = d.data();
          data.id = d.id;
          return dh.parser(data);
        });
        return logs;
      },
      (data: any) => ({
        ...data,
        id: data.id,
        error_time: this.toDate(data.error_time),
      }),
    ),
    version: new DataHandler<Version>(
      "version",
      async (dh: DataHandler<Version>) => {
        const data = await this.db.collection("version").get();
        let versions = data.docs.map((raw) => {
          return dh.parser(raw.data());
        });
        return versions;
      },
      (data: any) => ({ ...data, date: this.toDate(data.upload_date) }),
    ),
    klydos: new DataHandler<Klydo>(
      "klydos",
      async (dh: DataHandler<Klydo>, dependencies: DataType[][]) => {
        const data = await this.db.collection("klydos").get();
        let klydos: Klydo[] = data.docs.map((i) => {
          const raw = i.data();
          raw.id = i.id;
          return dh.parser(raw);
        });
        klydos.forEach((kld) => {
          const stat = (dependencies[0] as Stats[]).find(
            (s) => s.id === kld.id,
          );
          kld.stats = {
            time: stat?.time || 0,
            favorites: stat?.favs || 0,
            viewed: 0,
          };
        });
        return klydos;
      },
      (data: any) => ({
        ...data,
        idf: id.friendlyFromHex(data.id),
        createdAt: this.toDate(data.createdAt),
        updatedAt: this.toDate(data.updatedAt),
        tags: data.tags ?? [],
        featured: data.featured,
        featuredTimes: {
          start: data.featuredTimes?.start
            ? this.toDate(data.featuredTimes.start)
            : undefined,
          end: data.featuredTimes?.end
            ? this.toDate(data.featuredTimes.end)
            : undefined,
        },
        inTask: !!data.inTasks,
        author: data.authorName,
        unlisted: !!data.unlisted,
      }),
      ["stats"],
    ),
    stats: new DataHandler<Stats>(
      "stats",
      async (dh: DataHandler<Stats>) => {
        const data = await this.db.collection("stats").get();
        return data.docs.map((i) => {
          const d = i.data();
          d.id = i.id;
          return dh.parser(d);
        });
      },
      (data: any) => ({
        ...data,
      }),
    ),
    reviews: new DataHandler<Klydo>(
      "reviews",
      async (dh: DataHandler<Klydo>) => {
        const data = await this.db
          .collectionGroup("my_klydos")
          .orderBy("review")
          .get();
        const ids: string[] = [];
        data.docs.forEach((i) => {
          const id = i.ref.parent.parent!.id;
          if (!ids.includes(id)) {
            ids.push(id);
          }
        });
        let usrs: { uid: string; displayName: string }[];
        if (this.data.users.data) {
          usrs = this.data.users.data;
        } else {
          const users = await this.db
            .collection("users")
            .where(firebase.firestore.FieldPath.documentId(), "in", ids)
            .get();
          usrs = users.docs.map((i) => ({
            uid: i.id,
            displayName: i.get("name"),
          }));
        }
        let reviews = data.docs.map((i) => {
          const raw = i.data();
          raw.id = i.id;
          raw.creator = i.ref.parent.parent!.id;
          raw.author = usrs.find(
            (u) => u.uid === i.ref.parent.parent!.id,
          )?.displayName;
          return dh.parser(raw);
        });
        return reviews;
      },
      (data: any) => {
        if (data.review?.date) {
          data.review.date = this.toDate(data.review.date);
        }
        const klydo = {
          ...data,
          idf: id.friendlyFromHex(data.id),
          createdAt: this.toDate(data.createdAt),
          updatedAt: this.toDate(data.updatedAt),
        };
        return klydo;
      },
    ),
    users: new DataHandler<User>(
      "users",
      async (dh: DataHandler<User>) => {
        const data = await Promise.all([
          this.functions.httpsCallable("getUsers2")(),
          this.db.collection("userBasicInfo").get(),
        ]);
        const users: User[] = data[0].data;
        users.forEach((u: any) => {
          u.created = new Date(u.creationTime);
          u.used = new Date(u.lastRefreshTime);
        });
        data[1].docs.forEach((ii) => {
          const f = users.find((i) => i.uid === ii.id);
          if (f) f.basicUserInfo = ii.data() as { tag: string };
        });
        return users;
      },
      (data: any) => ({
        ...data,
      }),
    ),
    tags2: new DataHandler<Tag>(
      "tags2",
      async (dh: DataHandler<Tag>) => {
        const data = await this.db.collection("tags2").get();
        return data.docs.map((i) => {
          const raw = i.data();
          raw.id = i.id;
          return dh.parser(raw);
        });
      },
      (data: any) => ({
        ...data,
        name: data.name,
        total: 0,
      }),
    ),
    scheduledpool: new DataHandler<ScheduledTask>(
      "scheduledpool",
      async (dh: DataHandler<ScheduledTask>, dependencies: DataType[][]) => {
        const data = await this.db.collection("scheduledpool").get();
        const tasksKlydos = await klydoApiClient.getTasksKlydos();
        let scheduledPool: ScheduledTask[] = data.docs.map((i) => {
          const d = i.data();
          d.id = i.id;
          const task = {
            date: new Date(i.id),
            tag: d.tag || "",
            commands: d.commands
              ? d.commands.map((cmd: Command) => {
                  return {
                    ...cmd,
                    time: this.toDate(cmd.time),
                  };
                })
              : [],
            add:
              d.add
                ?.map((k: string) =>
                  tasksKlydos.find((klydo) => klydo.id === k),
                )
                .filter((i: Klydo) => i) || [],
            remove:
              d.remove
                ?.map((k: string) =>
                  tasksKlydos.find((klydo) => klydo.id === k),
                )
                .filter((i: Klydo) => i) || [],
          } as ScheduledTask;
          return task;
        });
        return scheduledPool;
      },
      (data: any) => ({
        ...data,
        date: new Date(data.id),
        tag: data.tag || "",
        commands: data.commands
          ? data.commands.map((cmd: Command) => {
              return {
                ...cmd,
                time: this.toDate(cmd.time),
              };
            })
          : [],
      }),
      ["klydos"],
    ),

    commandsHistory: new DataHandler<CommandServer>(
      "commandsHistory",
      async (dh: DataHandler<CommandServer>, dependencies: DataType[][]) => {
        const data = await this.db.collectionGroup("commandsHistory").get();
        const tasks = dependencies[0] as ScheduledTask[];
        let commandHistories: CommandHistory[] = data.docs.map((doc) => {
          const raw = doc.data();
          return {
            ...raw,
            id: doc.id,
            deviceId: doc.ref.parent.parent?.id,
            receiveTime: this.toDate(raw.receiveTime),
            executeTime: this.toDate(raw.executeTime),
            time: this.toDate(raw.time),
          } as CommandHistory;
        });
        let commandServer: CommandServer[] = [];
        for (const ch of commandHistories) {
          let cs = commandServer.find((i) => i.id === ch.id);
          if (!cs) {
            cs = {
              devices: tasks
                .find(
                  (task) => task.date.toDateString() === ch.time.toDateString(),
                )
                ?.commands.find((cmd) => cmd.id === ch.id)?.devices,
              filter: tasks
                .find(
                  (task) => task.date.toDateString() === ch.time.toDateString(),
                )
                ?.commands.find((cmd) => cmd.id === ch.id)?.filter,
              time: ch.time,
              local: ch.local,
              timeout: ch.timeout,
              name: ch.name,
              params: ch.params,
              id: ch.id,
              cmdHistory: [],
            } as CommandServer;
            commandServer.push(cs);
          }
          cs.cmdHistory.push(ch);
        }
        return commandServer;
      },
      (data: any) => ({
        ...data,
      }),
      ["scheduledpool"],
    ),

    packs: new DataHandler<SpecialPack>(
      "packs",
      async (dh: DataHandler<SpecialPack>) => {
        const data = (await this.db.collection("packs").get()).docs.map(
          (doc) => {
            const raw = doc.data();
            raw.id = doc.id;
            return dh.parser(raw);
          },
        );
        return data;
      },
      (data: any) => ({
        ...data,
      }),
    ),
    draftPacks: new DataHandler<SpecialPack>(
      "draftPacks",
      async (dh: DataHandler<SpecialPack>) => {
        const data = (await this.db.collection("draftPacks").get()).docs.map(
          (doc) => {
            const raw = doc.data();
            raw.id = doc.id;
            return dh.parser(raw);
          },
        );
        return data;
      },
      (data: any) => ({
        ...data,
      }),
    ),
    packsReviews: new DataHandler<SpecialPack>(
      "packsReviews",
      async (dh: DataHandler<SpecialPack>) => {
        const data = (
          await this.db
            .collection("draftPacks")
            .where("review", "!=", null)
            .orderBy("review")
            .get()
        ).docs.map((doc) => {
          const raw = doc.data();
          raw.id = doc.id;
          return dh.parser(raw);
        });
        return data;
      },
      (data: SpecialPack) => {
        if (data.review?.date) {
          data.review.date = this.toDate(data.review.date);
        }
        const pack = {
          ...data,
          createdAt: this.toDate(data.createdAt),
        };
        return pack;
      },
    ),
  };
  listen<T extends DataType>(key: Collection, listener: Listener<T>) {
    const dh = this.data[key] as unknown as DataHandler<T>;
    dh.listeners.push(listener);
    if (dh.data) {
      listener(dh.data);
    }
    dh.load();
  }
  removeListener(key: Collection, listener: Listener<any>) {
    const dh = this.data[key];
    dh.listeners.splice(dh.listeners.indexOf(listener), 1);
  }
  general?: General;

  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.db = firebase.firestore();
    this.functions = firebase.app().functions("us-central1");
    this.storage = firebase.storage();
    this.auth = firebase.auth();
    this.db
      .collection("general")
      .doc("config")
      .onSnapshot((c) => {
        this.general = c.data() as General;
      });
  }
  kpiListeners: { callB: (kpis: Kpi) => void; unsubscribe: () => void }[] = [];
  ordersListeners: {
    callB: (orderDates: Date[]) => void;
    unsubscribe: () => void;
  }[] = [];
  async getKlydoFrames(
    klydoLoopId: string,
    callBack: (frames: { url: string; name: string }[]) => void,
  ) {
    const file = await this.storage.ref(`${klydoLoopId}.zip`).getDownloadURL();
    const response = await axios
      .get(file, { responseType: "arraybuffer" })
      .then((res) => res.data);
    const zip = await JSZip.loadAsync(response);
    const imagePromises: Promise<{ url: string; name: string }>[] = [];
    zip.forEach((relativePath, file) => {
      if (!file.dir && /\.(jpe?g|png|gif|webp)$/i.test(relativePath)) {
        imagePromises.push(
          file.async("blob").then((blob) => ({
            url: URL.createObjectURL(blob),
            name: relativePath,
          })),
        );
      }
    });
    const imageUrls = await Promise.all(imagePromises);
    callBack(imageUrls);
  }
  listenKpis(cb: (kpis: Kpi) => void) {
    this.kpiListeners.push({
      callB: cb,
      unsubscribe: this.db
        .collection("general")
        .doc("kpis")
        .onSnapshot((c) => cb(c.data() as Kpi)),
    });
  }
  stopListenKpis(cb: (kpis: Kpi) => void) {
    const lst = this.kpiListeners.find((listener) => listener.callB === cb);
    if (lst) {
      lst.unsubscribe();
      this.kpiListeners.splice(this.kpiListeners.indexOf(lst), 1);
    }
  }
  listenOrders(cb: (orderDates: Date[]) => void) {
    this.ordersListeners.push({
      callB: cb,
      unsubscribe: this.db
        .collection("general")
        .doc("order_dates")
        .onSnapshot((c) =>
          cb(
            c
              .data()
              ?.dates.map((date: { seconds: number; nanoseconds: number }) =>
                this.toDate(date),
              ),
          ),
        ),
    });
  }
  stopListenOrders(cb: (orderDates: Date[]) => void) {
    const lst = this.ordersListeners.find((listener) => listener.callB === cb);
    if (lst) {
      lst.unsubscribe();
      this.ordersListeners.splice(this.ordersListeners.indexOf(lst), 1);
    }
  }

  genFbID() {
    return this.db.collection("a").doc().id;
  }
  onAuthStateChanged(callback: any) {
    if (!this.auth) {
      return;
    }
    this.auth.onAuthStateChanged(callback);
  }
  getUserName() {
    return this.auth.currentUser?.displayName || this.auth.currentUser?.email;
  }
  async logOut() {
    if (!this.auth) {
      return;
    }
    this.auth.signOut();
  }
  toDate(o: any) {
    if (typeof o == "number") return new Date(o);
    if (!o || !o.seconds) return new Date(0);
    return new Date(o.seconds * 1000);
  }

  loginWithEmail(user: { email: string; password: string }) {
    const { email, password } = user;
    return new Promise((resolve, reject) => {
      this.auth &&
        this.auth
          .signInWithEmailAndPassword(email, password)
          .then((response) => {
            resolve(response);
            // this.getUserDocument(response.user.uid).then((user) => {
            //     resolve(user);
            // }).catch(err => {
            //     reject(err)
            // })
          })
          .catch((error) => {
            const usernameErrorCodes = [
              "auth/email-already-in-use",
              "auth/invalid-email",
              "auth/operation-not-allowed",
              "auth/user-not-found",
              "auth/user-disabled",
            ];
            const passwordErrorCodes = [
              "auth/weak-password",
              "auth/wrong-password",
            ];

            const response = {
              email: usernameErrorCodes.includes(error.code)
                ? error.message
                : "",
              password: passwordErrorCodes.includes(error.code)
                ? error.message
                : "",
            };
            reject(response);
          });
    });
  }
  loginSocial(provider: string) {
    return this.auth.signInWithPopup(
      provider === "facebook"
        ? new firebase.auth.FacebookAuthProvider()
        : new firebase.auth.GoogleAuthProvider(),
    );
  }
  setToFirebase(collection: string, doc: string, data: any) {
    return new Promise((v, x) => {
      this.db.collection(collection).doc(doc).update(data).then(v).catch(x);
    });
  }

  publishVersion(publicNumber: number) {
    return this.db
      .collection("general")
      .doc("config")
      .update({ public: publicNumber });
  }
  setBetaVersion(publicNumber: number) {
    return this.db
      .collection("general")
      .doc("config")
      .update({ beta: publicNumber });
  }
  setDismissed(ids: Array<string>) {
    return new Promise((v, x) => {
      Promise.all(
        ids.map((id) =>
          this.db.collection("errors").doc(id).update({ dismissed: true }),
        ),
      )
        .then(v)
        .catch(x);
    });
  }
  async updateConfig(id: string, config: string, value: any) {
    await this.db
      .collection("machines")
      .doc(id)
      .update({
        [config]:
          value == null
            ? firebase.firestore.FieldValue.delete()
            : {
                time: firebase.firestore.FieldValue.serverTimestamp(),
                value: value,
              },
      });
    this.data.machines.trigger();
  }

  reomveRegister(id: string) {
    const clock = this.data.users.data?.find((u) => u.uid === id)?.email;
    return Promise.all([
      this.db.collection("machines").doc(id).update({
        registerProduct: firebase.firestore.FieldValue.delete(),
        emailSent: firebase.firestore.FieldValue.delete(),
      }),
      this.db.collection("register").doc(clock?.split("@")[0]).delete(),
    ]);
  }
  public getToken() {
    return this.auth.currentUser?.getIdToken();
  }

  public getUserId() {
    return this.auth.currentUser?.uid;
  }

  registerDevice(
    device: Device,
    clientDetails?: {
      country?: string;
      state?: string;
      city?: string;
      name?: string;
      email?: string;
    },
  ) {
    const obj: {
      registerProduct: DeviceValue<true>;
      clientDetails?: {
        country?: string;
        state?: string;
        city?: string;
        name?: string;
        email?: string;
      };
    } = {
      registerProduct: { time: new Date(), value: true },
    };
    if (clientDetails) {
      obj.clientDetails = clientDetails;
    }

    return this.db.collection("machines").doc(device.id).update(obj);
  }

  sendComandToClock(id: string, command: string, param: string) {
    return new Promise<void>((v, x) => {
      this.db
        .collection("machines")
        .doc(id)
        .update({ command: { c: command, p: param } })
        .then((_) => {
          const unreg = this.db
            .collection("machines")
            .doc(id)
            .onSnapshot((doc) => {
              if (!doc.data()?.command) {
                unreg();
                v();
              }
            });
        })
        .catch(x);
    });
  }

  async resetDeviceData(uid: string, idf: string) {
    await this.db.collection("machines").doc(uid).set({ idf: idf });
    const index = this.data.machines.data?.findIndex((d) => d.id === uid);
    this.data.machines.data?.splice(index!, 1);
    this.data.machines.trigger();
  }

  updateUserAnimatedPhoto = async (url: string) => {
    try {
      this.functions.httpsCallable("splitGifBridge")({
        url: url,
        artist: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  getUser(id: string) {
    return;
  }
  getUserProfileFromFb = async (uid: string) => {
    const profile = await this.db.collection("users").doc(uid).get();
    let totalPublic = 0;
    let totalFavs: number = 0;
    let totalTime: number = 0;
    const myKlydos = await this.db
      .collection("users")
      .doc(uid)
      .collection("my_klydos")
      .get();
    const totalKlydos = myKlydos.docs.length;
    myKlydos.docs.forEach((k) => {
      totalPublic += k.data().public ? 1 : 0;
      const s = this.data["stats"].data?.find((st) => st.id === k.id);
      if (s) {
        totalTime += s.time ? s.time : 0;
        totalFavs += s.favs ? s.favs : 0;
      }
    });
    return {
      id: profile.id,
      ...profile.data(),
      hasKlydos: totalKlydos > 0,
      totalPublic: totalPublic,
      totalTime: totalTime,
      totalFavs: totalFavs,
    };
  };

  async changeKlydoCreator(klydo: string, from: string, to: string) {
    const klydoRef = this.db.collection("klydos").doc(klydo);
    const oldRef = this.db
      .collection("users")
      .doc(from)
      .collection("my_klydos")
      .doc(klydo);
    const newRef = this.db
      .collection("users")
      .doc(to)
      .collection("my_klydos")
      .doc(klydo);
    const [klydoDoc, old] = await Promise.all([klydoRef.get(), oldRef.get()]);
    const klydoObj = old.exists ? old.data() : klydoDoc.data();
    const batch = this.db
      .batch()
      .update(klydoRef, { creator: to })
      .set(newRef, klydoObj);
    if (old.exists) batch.delete(oldRef);
    await batch.commit();
    this.data.klydos.trigger();
  }
  getTotalTimeAllKlydos() {
    return new Promise<number>((v) => {
      this.db
        .collection("stats")
        .get()
        .then((s) => {
          const res = s.docs.reduce(
            (a, b) => a + (b.data().time ? (b.data().time as number) : 0),
            0,
          );
          v(res);
        });
    });
  }
  removeAttachedClock(uid: string) {
    return this.functions.httpsCallable("disconnectClock")({ uid: uid });
  }

  saveKlydoTags(klydo: Klydo, tags: string[]) {
    return new Promise<void>(async (v, x) => {
      await this.updateTags(tags);
      this.db
        .collection("klydos")
        .doc(klydo.id)
        .update({ tags: tags })
        .then(() => {
          const klydos = this.data["klydos"].data;
          if (klydos) {
            const found = klydos.find((kld) => kld.id === klydo.id);
            if (found) found.tags = tags;
          }
          v();
        })
        .catch(x);
    });
  }

  async updateTags(tags: string[]) {
    if (tags.length) {
      const cloudTags = (
        await this.db.collection("tags2").where("name", "in", tags).get()
      ).docs.map((doc) => doc.data());
      const filteredTags = tags.filter(
        (tag) => !cloudTags.find((t) => t.name === tag),
      );
      Promise.all(
        filteredTags.map(async (tag) => {
          return {
            id: (await this.db.collection("tags2").add({ name: tag })).id,
            name: tag,
            total: 0,
          };
        }),
      ).then((v) => {
        this.data.tags2.data?.push(...v);
        this.data.tags2.trigger();
      });
    }
  }
  async deleteTag(tag: Tag) {
    let klydoUse = false;
    if (this.data.klydos.data)
      klydoUse = !!this.data.klydos.data.find((klydo) =>
        klydo.tags?.includes(tag.name),
      );
    else
      klydoUse = !(
        await this.db
          .collection("klydos")
          .where("tags", "array-contains", tag.name)
          .limit(1)
          .get()
      ).empty;
    if (!klydoUse) {
      await this.db.collection("tags2").doc(tag.id).delete();
      if (this.data.tags2.data) {
        this.data.tags2.data!.splice(this.data.tags2.data!.indexOf(tag), 1);
        this.data.tags2.trigger();
      }
    } else throw new Error("Tag is used by some klydos");
  }

  saveDevicePremium(device: Device, tags: string[]) {
    return new Promise<void>((v, x) => {
      this.db
        .collection("machines")
        .doc(device.id)
        .update({ premium: tags })
        .then(v)
        .catch(x)
        .then(() => {
          device.premium = [...tags];
          v();
        })
        .catch(x);
    });
  }

  changeKlydoName(
    klydo: Klydo,
    name: string,
    isReview: boolean = false,
  ): Promise<any> {
    const kRef = this.db.collection("klydos").doc(klydo.id);
    const mkRef = this.db
      .collection("users")
      .doc(klydo.creator)
      .collection("my_klydos")
      .doc(klydo.id);
    const batch = this.db.batch();
    if (!isReview) {
      batch.update(kRef, { name: name });
    }
    batch.update(mkRef, { name: name });
    return batch.commit();
  }

  setDials(
    klydo: Klydo,
    isDials: boolean,
    isReview: boolean = false,
  ): Promise<any> {
    const docRef = isReview
      ? this.db
          .collection("users")
          .doc(klydo.creator)
          .collection("my_klydos")
          .doc(klydo.id)
      : this.db.collection("klydos").doc(klydo.id);
    return docRef.update({ dials: isDials });
  }

  changeTheme(
    theme:
      | "handsColor"
      | "backgroundColor"
      | "pendulumColor"
      | "pendulumRodColor"
      | "dialsColor",
    klydo: Klydo,
    color: string,
    isReview: boolean = false,
  ) {
    const tColor = color.trim();
    return new Promise<void>((v, x) => {
      const newTheme: Theme = klydo.theme;
      if (!/^#[0-9A-F]{6}$/i.test(tColor))
        x(
          "incorect format: must be a 6 digit hexadecimal number beginig  with #",
        );
      else newTheme[theme] = tColor!;
      const kRef = this.db.collection("klydos").doc(klydo.id);
      const mkRef = this.db
        .collection("users")
        .doc(klydo.creator)
        .collection("my_klydos")
        .doc(klydo.id);
      const batch = this.db.batch();
      if (!isReview) {
        batch.update(kRef, { theme: newTheme });
      }
      batch.update(mkRef, { theme: newTheme });
      batch
        .commit()
        .then(() => {
          klydo.theme = newTheme;
          v();
        })
        .catch(x);
    });
  }
  async updateKlydoTimes(
    klydo: Klydo,
    timesArray: Array<KlydoTimes>,
    isReview: boolean = false,
  ) {
    const batch = this.db.batch();
    batch.set(
      this.db
        .collection("users")
        .doc(klydo.creator)
        .collection("my_klydos")
        .doc(klydo.id),
      { times: timesArray },
      { merge: true },
    );
    if (!isReview) {
      batch.set(
        this.db.collection("klydos").doc(klydo.id),
        { times: timesArray },
        { merge: true },
      );
    }
    await batch.commit();
    this.data.klydos.trigger();
  }

  async removeKlydoFromTask(
    klydoId: string,
    date: Date,
    add: boolean,
  ): Promise<any> {
    const obj: any = {};
    obj[add ? "add" : "remove"] = arrayRemove(klydoId);
    await this.db
      .collection("scheduledpool")
      .doc(date.toLocaleDateString("iso").replaceAll("/", "-"))
      .update(obj);
    this.data.scheduledpool.trigger();
  }

  async addScheduledTask(date: Date, add: boolean, klydos: Array<Klydo>) {
    const obj: any = {};
    obj[add ? "add" : "remove"] = arrayUnion(...klydos.map((k) => k.id));
    await this.db
      .collection("scheduledpool")
      .doc(date.toLocaleDateString("iso").replaceAll("/", "-"))
      .set(obj, { merge: true });
    if (this.data.scheduledpool.data) {
      let curr = this.data.scheduledpool.data.find(
        (task) => task.date === date,
      );
      if (!curr) {
        obj[add ? "add" : "remove"] = [...klydos];
        obj[add ? "remove" : "add"] = []; //initialize other array
        obj["commands"] = [];
        obj["date"] = date;
        this.data.scheduledpool.data.push(obj);
      } else {
        if (add) curr.add = [...curr.add, ...klydos] as Klydo[];
        else curr.remove = [...curr.remove, ...klydos] as Klydo[];
      }
      this.data.scheduledpool.trigger();
    }
  }

  async scheduleCommandToClocks(data: any) {
    const obj: {
      id: string;
      name: string;
      local: boolean;
      timeout?: number;
      time: Date;
      devices?: Array<string>;
      filter?: Array<string>;
      params?: Array<any>;
    } = {
      id: data.id,
      name: data.name,
      local: data.local,
      time: data.date,
    };
    if (data.params && data.params.length) obj.params = data.params;
    if (data.expire) obj.timeout = data.expire;
    if (data.devices) obj.devices = data.devices;
    if (data.filter) obj.filter = data.filter;
    await this.db
      .collection("scheduledpool")
      .doc(data.date.toLocaleDateString("iso").replaceAll("/", "-"))
      .set(
        {
          commands: arrayUnion(obj),
        },
        { merge: true },
      );
    let scheduledPool = this.data.scheduledpool.data;
    if (scheduledPool) {
      let curr = scheduledPool.find((task) => task.date === data.date);
      if (!curr) {
        let objLocal: ScheduledTask = {
          add: [],
          remove: [],
          commands: [obj as CommandServer],
          date: data.date,
          tag: "",
        };
        scheduledPool.push(objLocal);
      } else {
        if (curr.commands.length > 0)
          curr.commands = [...curr.commands, obj] as Command[];
        else curr.commands = [obj] as Command[];
      }
      this.data.scheduledpool.trigger();
    }
  }
  sendCommandToClocksByBatch(data: any) {
    let batch = this.db.batch();
    let collectionRef = this.db.collection("machines");
    const obj: {
      name: string;
      local?: boolean;
      timeout?: number;
      time: Date;
      params?: Array<string>;
    } = {
      name: data.name,
      local: data.local,
      time: data.date,
    };
    if (!data.local) {
      delete obj.local;
    }
    if (data.params && data.params.length) obj.params = data.params;
    if (data.expire) obj.timeout = data.expire;
    data.devices.forEach((deviceId: string) => {
      batch.set(
        collectionRef.doc(deviceId).collection("commands").doc(data.id),
        obj,
      );
    });

    return batch.commit();
  }

  async deleteScheduledCommand(command: Command): Promise<any> {
    const obj: any = {};
    obj["commands"] = arrayRemove(command);
    await this.db
      .collection("scheduledpool")
      .doc(command.time.toLocaleDateString("iso").replaceAll("/", "-"))
      .update(obj);
    let task = this.data.scheduledpool.data!.find((task) =>
      task.commands.includes(command),
    );
    if (task) {
      let index = task.commands.indexOf(command);
      if (index >= 0) {
        task.commands.splice(index, 1);
        this.data.scheduledpool.trigger();
      }
    }
  }

  async updateScheduledTag(date: Date, tag: string) {
    await this.db
      .collection("scheduledpool")
      .doc(date.toLocaleDateString("iso").replaceAll("/", "-"))
      .set({ tag: tag }, { merge: true });
    const task = this.data.scheduledpool.data!.find(
      (task) => task.date.toDateString() === date.toDateString(),
    );
    if (task) task.tag = tag;
    else {
      this.data.scheduledpool.data!.push({
        add: [],
        remove: [],
        commands: [],
        tag: tag,
        date: date,
      });
    }
    this.data.scheduledpool.trigger();
  }

  addFeatureToKlydos(
    kid: Array<string>,
    featured: boolean,
    times: { start: Date | null; end: Date | null } | null,
  ) {
    const obj: {
      featured: boolean;
      featuredTimes?: { start: Date | null; end: Date | null };
    } = { featured: featured };
    if (times) obj.featuredTimes = times;
    return Promise.all(
      kid.map((id) =>
        this.db.collection("klydos").doc(id).set(obj, { merge: true }),
      ),
    );
  }

  removeFeatureFromKlydos(kid: string, date: { from: Date; until: Date }) {
    return this.db
      .collection("klydos")
      .doc(kid)
      .set(
        { featured: firebase.firestore.FieldValue.arrayRemove(date) },
        { merge: true },
      );
  }

  getKlydoAnalyticsByDevice(deviceId: string) {
    return new Promise<Array<any>>((v, x) => {
      this.functions
        .httpsCallable("queryKlydosAnalytics")({
          uid: deviceId,
        })
        .then((u) => {
          v(u.data);
        })
        .catch(x);
    });
  }

  getKlydoAnalyticsByKlydo(klydoId: any) {
    return new Promise<Array<any>>((v, x) => {
      this.functions
        .httpsCallable("queryKlydosAnalytics")({
          kid: klydoId,
        })
        .then((u) => {
          v(u.data);
        })
        .catch(x);
    });
  }
  private async searchDevice({
    field,
    value,
  }: {
    field: string;
    value: string;
  }): Promise<Device> {
    const machineSnapshot = await this.db
      .collection("machines")
      .where(field, "==", value)
      .limit(1)
      .get();

    if (machineSnapshot.empty) throw new Error("Device not found");

    const machineDoc = machineSnapshot.docs[0];
    const machineData = machineDoc.data();

    const reportSnapshot = await this.db
      .collection("machinesReports")
      .doc(machineDoc.id)
      .get();

    const reportData = reportSnapshot.exists ? reportSnapshot.data() : null;

    return {
      ...machineData,
      id: machineDoc.id,
      registerProduct: machineData.registerProduct
        ? this.toDate(machineData.registerProduct.time)
        : undefined,
      autoCreate: machineData.autoCreate ?? false,
      heartbeat: reportData?.heartbeat || machineData.heartbeat,
      clocktime:
        reportData?.clocktime || machineData.clocktime
          ? {
              local: (reportData?.clocktime || machineData.clocktime).local,
              server: this.toDate(
                (reportData?.clocktime || machineData.clocktime).server,
              ),
              timeZone: (reportData?.clocktime || machineData.clocktime)
                .timeZone,
            }
          : undefined,
      location: reportData?.location || machineData.location,
    } as Device;
  }
  async getDeviceBySerialOrIdf(deviceSerialOrIdf: string): Promise<Device> {
    let idf =
      deviceSerialOrIdf.split("-").length === 3
        ? deviceSerialOrIdf
        : id.friendlyIdFromString(deviceSerialOrIdf);
    return await this.searchDevice({ field: "idf", value: idf });
  }

  public async getDevicesByIdfs(idfs: string[]): Promise<Device[]> {
    const machineSnapshot = await this.db
      .collection("machines")
      .where("idf", "in", idfs)
      .get();
    return machineSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        registerProduct: data.registerProduct
          ? this.toDate(data.registerProduct.time)
          : undefined,
        autoCreate: data.autoCreate ?? false,
      } as Device;
    });
  }
  async getDeviceByEmail(email: string): Promise<Device> {
    return await this.searchDevice({
      field: "clientDetails.email",
      value: email,
    });
  }
  async getDeviceByOrderId(orderId: string): Promise<Device> {
    return await this.searchDevice({
      field: "order",
      value: orderId,
    });
  }

  async findItem<T extends DataType>(
    handler: Collection,
    key: string,
    value: any,
    include: boolean = false,
  ) {
    let data = this.data[handler].data as T[];
    if (data)
      return data.find((item: T) =>
        include ? item[key].includes(value) : item[key] === value,
      );
    return (
      await this.db.collection(handler).where(key, "==", value).limit(1).get()
    ).docs[0]?.data() as T;
  }

  async getList<T extends DataType>(
    handler: Collection,
    key: string,
    list: string[],
  ): Promise<T[]> {
    if (list.length === 0) throw Error("Must provide a non empty list");
    const dh = this.data[handler];
    let dataArray = this.data[handler].data as T[];
    if (dataArray)
      return dataArray.filter((item: T) => list.includes(item[key]));
    const data = await this.db
      .collection(handler)
      .where(
        key === "id" ? firebase.firestore.FieldPath.documentId() : key,
        "in",
        list,
      )
      .get();
    return data.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return dh.parser(data) as T;
    });
  }

  async getUserKlydos(uid: string) {
    let klydos = this.data["klydos"].data;
    if (klydos) return klydos.filter((klydo) => klydo.creator === uid);

    return (
      await this.db
        .collection("klydos")
        .where(firebase.firestore.FieldPath.documentId(), "==", uid)
        .get()
    ).docs.map((raw) => {
      const kld = raw.data();
      kld.id = raw.id;
      return this.data["klydos"].parser(kld);
    });
  }
  async getData<T extends DataType>(handler: Collection) {
    let dataArray = this.data[handler].data as T[];
    if (dataArray) return dataArray;

    return new Promise<T[]>((v) => {
      const l = (dataArr: T[]) => {
        this.removeListener(handler, l);
        v(dataArr);
      };
      this.listen<T>(handler, l);
    });
  }

  deleteGift(deviceId: string) {
    return this.db
      .collection("machines")
      .doc(deviceId)
      .update({ gift: firebase.firestore.FieldValue.delete() });
  }

  async addPack(
    name: string,
    tag: string,
    pub: boolean,
    description?: string,
    imageUrl?: string,
    pendulumImageUrl?: string,
    artists?: string[],
    loopUrl?: string,
    klydos?: string[],
  ) {
    const pack: {
      name: string;
      tag: string;
      pub: boolean;
      artists?: string[];
      description?: string;
      imageUrl?: string;
      klydos?: string[];
    } = {
      name: name,
      tag: tag,
      pub: pub,
      artists,
    };
    if (description) pack.description = description;
    if (imageUrl) pack.imageUrl = imageUrl;
    if (klydos) pack.klydos = klydos;
    try {
      const ref = await this.db.collection("packs").add(pack);
      const data = this.data.packs.data!;
      data.push({
        ...pack,
        id: ref.id,
      });
      this.data.packs.trigger();
    } catch {
      throw new Error(`Failed to add draft`);
    }
  }

  updatePack(packUpdate: SpecialPack) {
    return new Promise<void>((v, x) => {
      const { id, ...rest } = packUpdate;
      this.db
        .collection("packs")
        .doc(packUpdate.id)
        .update(rest)
        .then(() => {
          v();
        })
        .catch(x);
    });
  }

  async fetchDocsByField<T extends DocumentData>(
    collection: string,
    field: string,
    operator: WhereFilterOp,
    value: T[keyof T],
  ): Promise<(T & { id: string })[]> {
    const querySnapshot = await this.db
      .collection(collection)
      .where(field, operator, value)
      .get();

    return querySnapshot.docs.map((doc) => {
      const x: T = doc.data() as T;
      return {
        id: doc.id,
        ...x,
      };
    });
  }

  async deletePack(pack: SpecialPack) {
    const packData = this.data.packs.data!;
    const pubPack = packData.find((p) => p.id === pack.id);
    const prmss: Promise<void>[] = [];
    prmss.push(this.db.collection("packs").doc(pack.id).delete());
    return Promise.all(prmss).then(async () => {
      if (pubPack) {
        const deleteTag = pack.tag;
        let klds;
        if (this.data.klydos.data)
          klds = this.data.klydos.data.filter((kld) =>
            kld.tags!.includes(deleteTag),
          );
        else
          klds = (
            await this.db
              .collection("klydos")
              .where("tags", "array-contains", deleteTag)
              .get()
          ).docs.map((raw) => {
            const kld = raw.data();
            kld.id = raw.id;
            return this.data.klydos.parser(kld);
          });
        const prmss: Promise<void>[] = [];
        klds.forEach(async (kld: Klydo) =>
          prmss.push(
            this.saveKlydoTags(
              kld as Klydo,
              kld.tags!.filter((tag: string) => tag !== deleteTag),
            ),
          ),
        );
        await Promise.all(prmss);
        let tag;
        if (this.data.tags2.data)
          tag = this.data.tags2.data.find((t) => t.name === deleteTag);
        else {
          const doc = (
            await this.db
              .collection("tags2")
              .where("name", "==", deleteTag)
              .limit(1)
              .get()
          ).docs[0];
          tag = {
            id: doc.id,
            name: doc.data(),
            total: 0,
          };
        }
        packData.splice(packData.indexOf(pubPack), 1);
        this.deleteTag(tag as Tag);
        this.data.packs.trigger();
      }
      this.data.draftPacks.trigger();
    });
  }
}

const instance = new FirebaseService();

export default instance;
