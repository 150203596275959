import React, { useCallback, useEffect, useRef, useState } from "react";
import { Klydo } from "../../../../Types";
import DSDialog from "../../../system-design/DSDialog";
import DSDialogTitle from "../../../system-design/DSDialogTitle";
import DSTextField from "../../../system-design/DSTextField";
import DSDialogContent from "../../../system-design/DSDialogContent";
import KlydoCard from "./KlydoCard";
import DSCircularProgress from "../../../system-design/DSCircularProgress";
import DSDialogActions from "../../../system-design/DSDialogActions";
import DSSecondaryButton from "../../../system-design/DSSecondaryButton";
import DSPrimaryButton from "../../../system-design/DSPrimaryButtom";
import { debounce } from "lodash";
import useApprovedKlydos, {
  SearchType,
} from "../../../../components/hooks/useApprovedKlydos";
import DSGrid from "../../../system-design/DSGrid";
import DSListItem from "../../../system-design/DSListItem";
import DSList from "../../../system-design/DSList";
import DSCheckbox from "../../../system-design/DSCheckBox";
import DSBox from "../../../system-design/DSBox";
import DSTypography from "../../../system-design/DSTypography";
import DSStack from "../../../system-design/DSStack";
import DSSwitch from "../../../system-design/DSSwitch";

interface KlydosPickDialogProps {
  onClose: () => void;
  selectedKlydos: string[];
  onPickKlydos: (selectedKlydos: string[]) => void;
  limit?: number;
}

const PAGE_SIZE = 20;

const KlydosPickDialog: React.FC<KlydosPickDialogProps> = ({
  onClose,
  selectedKlydos,
  onPickKlydos,
  limit,
}) => {
  const [selected, setSelected] = useState<string[]>(selectedKlydos);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [textSearch, setTextSearch] = useState<string>("");
  const [pool, setPool] = useState(false);
  const [unlisted, setUnlisted] = useState(false);
  const [searchType, setSearchType] = useState<SearchType>("TEXT");

  const {
    data: klydos,
    dataRef: klydosRef,
    isFetching,
    fetchNextPage,
    isLoading,
  } = useApprovedKlydos(textSearch, PAGE_SIZE, searchType, { pool, unlisted });

  const handleFreeSearch = useCallback(
    debounce((s) => setTextSearch(s), 500),
    [],
  );

  const handleToggleSelection = (id: string) => {
    if (limit && selected.length >= limit && !selected.includes(id)) {
      return;
    }
    setSelected((prev) => {
      const index = prev.findIndex((item) => item === id);
      if (index > -1) {
        return prev.filter((item) => item !== id);
      } else {
        const klydoToAdd = klydos
          ? klydos.find((klydo) => klydo?.id === id)
          : undefined;
        return klydoToAdd ? [...prev, klydoToAdd.id] : prev;
      }
    });
  };

  const handleScroll = () => {
    if (
      listRef.current &&
      listRef.current.scrollTop + listRef.current.clientHeight >=
        listRef.current.scrollHeight - 100 &&
      !isFetching &&
      !isLoading
    ) {
      setCurrentIndex((prev) => prev + 4);
    }
  };

  const handleCancel = () => {
    setTextSearch("");
    setSelected(selectedKlydos);
    onClose();
  };

  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault();
    onPickKlydos(selected);
    onClose();
  };

  useEffect(() => {
    if (klydos) {
      setIsSearching(false);
    }
  }, [klydos]);

  useEffect(() => {
    if (
      klydosRef.current &&
      currentIndex >= klydosRef.current.length - (PAGE_SIZE - 1) &&
      !isFetching
    ) {
      fetchNextPage();
    }
  }, [currentIndex, isFetching]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [textSearch]);

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (listElement) {
        listElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <DSDialog open={true} onClose={handleCancel} fullWidth maxWidth="md">
      <DSDialogTitle>
        {"Klydos"}
        <DSTextField
          fullWidth
          autoFocus
          aria-invalid={isSearching}
          onChange={(e) => {
            setIsSearching(true);
            handleFreeSearch(e.target.value);
          }}
          placeholder="Which klydo you are looking for?"
        />
        <DSStack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
          <DSTypography>Text</DSTypography>
          <DSSwitch
            value={searchType === "TEXT"}
            onClick={() => {
              setUnlisted(false);
              setSearchType((prev) =>
                prev === "TEXT" ? "SIMILARITY" : "TEXT",
              );
            }}
          />
          <DSTypography>Semantic</DSTypography>
        </DSStack>
        <DSBox
          display="flex"
          justifyContent="flex-end"
          sx={{ marginTop: "10px" }}
        >
          <DSCheckbox
            checked={pool}
            onChange={() => setPool(!pool)}
            style={{ marginRight: "8px", flexShrink: 0, padding: "1px" }}
          />
          <DSTypography variant="caption" style={{ marginRight: "8px" }}>
            Pool
          </DSTypography>

          <DSCheckbox
            disabled={searchType === "SIMILARITY"}
            checked={unlisted}
            onChange={() => setUnlisted(!unlisted)}
            style={{ marginRight: "8px", flexShrink: 0, padding: "1px" }}
          />
          <DSTypography variant="caption" style={{ marginRight: "8px" }}>
            unlisted
          </DSTypography>
        </DSBox>
      </DSDialogTitle>
      <DSDialogContent dividers style={{ height: "80vh", overflow: "hidden" }}>
        {!isSearching && klydos && !isLoading ? (
          <DSList ref={listRef} style={{ height: "100%", overflow: "auto" }}>
            <DSGrid container spacing={1}>
              {klydos!.map((klydo: Klydo) => (
                <DSGrid item key={klydo?.id}>
                  <DSListItem disablePadding>
                    <KlydoCard
                      klydoId={klydo.id}
                      isSelected={selected.includes(klydo?.id)}
                      onToggleSelection={() => handleToggleSelection(klydo?.id)}
                    />
                  </DSListItem>
                </DSGrid>
              ))}
            </DSGrid>
          </DSList>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "calc(100vh - 100px)",
              minHeight: "200px",
              flexDirection: "column",
            }}
          >
            <DSCircularProgress />
          </div>
        )}
      </DSDialogContent>
      <DSDialogActions>
        <DSSecondaryButton onClick={handleCancel}>Cancel</DSSecondaryButton>
        <DSPrimaryButton onClick={handleSave}>Save</DSPrimaryButton>
      </DSDialogActions>
    </DSDialog>
  );
};

export default KlydosPickDialog;
