import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { KlydoError } from "./types";

export type UseKlydoMutationOptions<
  TData = unknown,
  TVariables = void,
  TContext = unknown,
> = UseMutationOptions<TData, KlydoError, TVariables, TContext>;

export type UseKlydoMutationResult<
  TData = unknown,
  TError = KlydoError,
  TVariables = void,
  TContext = unknown,
> = UseMutationResult<TData, TError, TVariables, TContext>;

const useKlydoMutation = <
  TData = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: UseKlydoMutationOptions<TData, TVariables, TContext>,
): UseKlydoMutationResult<TData, KlydoError, TVariables, TContext> => {
  return useMutation(options);
};

export default useKlydoMutation;
