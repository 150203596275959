import React from "react";
import InView from "./InView";
import DSCheckbox from "../../../system-design/DSCheckBox";
import { resizeCloudinary } from "../../../../utils/util";
import ClockView from "../../../../components/Widgets/ClockView";
import DSGrid from "../../../system-design/DSGrid";
import useKlydo from "../../../../components/hooks/useKlydo";

interface KlydoCardProps {
  klydoId: string;
  onLoad?: () => void;
  isSelected: boolean;
  onToggleSelection: (id: string) => void;
}

const KlydoCard: React.FC<KlydoCardProps> = ({
  klydoId,
  onLoad,
  isSelected,
  onToggleSelection,
}) => {
  const scale = 0.4;
  const w = scale * 390;
  const h = scale * 643.5;
  const { data: klydo, isLoading } = useKlydo(klydoId);
  return (
    <InView
      onEnterView={onLoad}
      style={{ cursor: "pointer", padding: "4px", borderRadius: "4px" }}
    >
      <DSCheckbox
        checked={isSelected}
        onChange={() => klydo && onToggleSelection(klydo.id)}
        style={{ marginRight: "8px", flexShrink: 0, padding: "1px" }}
      />
      <DSGrid
        xs={6}
        md={8}
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 1 + "rem",
          padding: 0,
        }}
      >
        <div
          style={{
            position: "relative",
            width: w + "px",
            height: h + "px",
            padding: 0,
          }}
        >
          {klydo && !isLoading && (
            <ClockView
              loopUrl={resizeCloudinary(
                klydo.convertedLoopUrl || klydo.loopUrl,
                200,
              )}
              themeName={klydo.name}
              themeID={klydo.id}
              handsColor={klydo.theme?.handsColor}
              backgroundColor={klydo.theme?.backgroundColor}
              pendulumColor={klydo.theme?.pendulumColor}
              pendulumRodColor={
                klydo.theme?.pendulumRodColor || klydo.theme?.pendulumColor
              }
              dialsColor={klydo.theme?.dialsColor || ""}
            />
          )}
        </div>
        <div style={{ textAlign: "center", width: w + "px" }}>
          <p style={{ display: "inline", marginRight: 4 + "px" }}>
            {klydo?.name}
          </p>
        </div>
      </DSGrid>
    </InView>
  );
};

export default KlydoCard;
