import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import klydoApiClient, { Klydo } from "../../../../api/klydo";

const useUpdatDrafteKlydoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["update-klydo"],
    mutationFn: ({
      klydoId,
      klydo,
    }: {
      klydoId: string;
      klydo: Partial<Klydo>;
    }) => {
      return klydoApiClient.updateDraftKlydo(klydoId, klydo);
    },
    onMutate: ({
      klydoId,
      klydo,
    }: {
      klydoId: string;
      klydo: Partial<Klydo>;
    }) => {
      const matchingQueries = queryClient
        .getQueryCache()
        .findAll({ queryKey: ["klydosList"] });

      matchingQueries.forEach(({ queryKey }) => {
        queryClient.setQueryData<InfiniteData<Klydo[]>>(
          queryKey,
          (oldData) => {
            if (!oldData) return oldData;

            return {
              ...oldData,
              pages: oldData.pages.map((page) =>
                page.map((oldklydo) =>
                  oldklydo.id === klydoId
                    ? { ...oldklydo, ...klydo }
                    : oldklydo,
                ),
              ),
            };
          },
        );
      });
    },
    onSettled: () =>
      queryClient.refetchQueries({
        queryKey: ["klydosList"],
      }),
  });
};

export default useUpdatDrafteKlydoMutation;
