import { useQuery } from "@tanstack/react-query";
import adminApiClient from "../../../api/admin";

const useUserQuery = (userId?: string) => {
  return useQuery({
    queryKey: ["user", userId],
    queryFn: () => adminApiClient.getUserById(userId || ""),
    enabled: !!userId,
  });
};

export default useUserQuery;
