import { FieldValue } from "firebase/firestore";
import { Klydo } from "./Types";
export type { Klydo } from "./api/klydo";
type BaseType = {
  [key: string]: any;
};

export enum PackType {
  DRAFT = "draft",
  APPROVED = "approved",
}

export enum PackReviewStatus {
  Approved = "approved",
  Pending = "pending",
  Rejected = "rejected",
  Draft = "draft",
}

export type ReviewFormStatus = {
  state: string;
  message: string;
};

export type timeTypes = "daily" | "weekly" | "monthly" | "yearly";
export type KlydoTimes = {
  type: timeTypes;
  start: string;
  end: string;
  negative: boolean;
};
export type Stats = BaseType & {
  id: string;
  time?: number;
  favs?: number;
};
export type Review = BaseType & {
  started?: Date;
  ended?: Date;
  type: "delete" | "edit" | "publish";
  date?: Date;
  msg?: string;
};
export type ReviewResponse = BaseType & {
  result: Review;
  review: FieldValue;
  edited?: boolean;
};
export type Version = BaseType & {
  id: string;
  version: number;
  description: string;
  date: Date;
  devices: number;
};
export type Tag = BaseType & {
  id: string;
  name: string;
  total: number;
};
export type General = BaseType & {
  last: number;
  public: number;
  beta: number;
  autoUser: boolean;
  autoUserCount: number;
  maxMultipleCommand: number;
};
export type Kpi = {
  activeDevices?: number;
  lastDayRegistered?: number;
  liveDevices?: number;
  connectedDevices?: number;
  versionIssues?: number;
  artists?: number;
  publishedGifs?: number;
  pool?: number;
  favorites?: number;
  time?: number;
  klydosInReview?: number;
  activeEditors?: number;
  artistsInPool?: number;
};
export type Log = BaseType & {
  id: string;
  version: number;
  stack: string;
  message: string;
  error_time: Date;
  dismissed?: boolean;
  debug?: boolean;
};

export type Report = {
  id: string;
  title: string;
  author: string;
  system: string;
  description: string;
  reportTime: any;
  dismissed: boolean;
};

export type TableProps = {
  cols: colType[];
  rowBuild: any;
  onScrollDown?: () => void;
  title: string;
  data: Array<any>;
  cancelSort?: boolean;
  buttons?: any;
  actions?: () => JSX.Element;
  onAction?: (selected: Array<any>) => void;
  onSearchChanged?: (currentSearch: string) => void;
  onSelectedChanged?: (
    currSelected: Array<any>,
    currTableData: Array<any>,
  ) => void;
  search?: (row: any, val: string) => boolean;
  initSort?: string;
  modal?: any;
  handleChangedKlydo?: (klydo: Klydo) => void;
  handleChangedPack?: (pack: SpecialPack) => void;
  id: string;
  actionDisabled?: (selection: Array<any>) => string | undefined;
  shouldHideSearchBar?: boolean;
  onSortClick?: (field: string) => void;
};

export type colType = {
  scroll?: boolean;
  size?: number;
  noHeadline?: boolean;
  sort: boolean;
  label: string;
  field: string;
};
export const roles = ["clock", "admin", "editor", "viewer"];

export type User = BaseType & {
  uid: string;
  password?: boolean;
  displayName: string;
  createdAt?: Date;
  used?: Date;
  role: "admin" | "editor" | "clock";
  email: string;
  photoURL?: string;
  socialMedia?: SocialNetwork[];
  basicUserInfo?: { tag: string };
};

export type Device = BaseType & {
  clientDetails: ClientDetails;
  id: string;
  idf: string;
  currentIdf: string;
  heartbeat: { value: Date };
  klydo?: { id: string; loopUrl: string };
  clocktime?: {
    local: number;
    server: { time: { value: Date } };
    timeZone?: string;
  };
  timeOffset?: DeviceValue<number>;
  registerProduct: Date;
  version?: number;
  location?: {
    latitude: number;
    longitude: number;
    country_code: string;
    city: string;
  };
  hideDev?: DeviceValue<boolean>;
  mode?: DeviceValue<string>;
  sort?: DeviceValue<string>;
  sortDown?: DeviceValue<boolean>;
  chimes?: DeviceValue<boolean>;
  chimesVol?: DeviceValue<number>;
  ticks?: DeviceValue<boolean>;
  ticksVol?: DeviceValue<number>;
  quietTimesOn?: DeviceValue<boolean>;
  quietTimes?: {
    source?: string;
    time: Date;
    value: { from: string; until: string };
  };
  auto?: DeviceValue<boolean>;
  autoRestart?: DeviceValue<number>;
  brightness?: DeviceValue<number>;
  meta?: DeviceValue<boolean>;
  nightMode?: { source?: string; time: Date; value: string };
  nightModeTimes?: DeviceValue<{ from: string; until: string }>;
  premium?: string[];
  autoCreate: boolean;
  beta?: boolean;
  randomLocation?: { latitude: number; longitude: number };
  order?: string;
};

export type ClientDetails = {
  accept: string;
  city: string;
  country: string;
  email: string;
  name: string;
  state: string;
};

export type ClocksListResponse = Pick<
  Device,
  | "id"
  | "idf"
  | "clocktime"
  | "heartbeat"
  | "klydo"
  | "location"
  | "registerProduct"
  | "version"
>;

export type DeviceReport = BaseType &
  Pick<Device, "id" | "heartbeat" | "clocktime" | "randomLocation"> & {
    location: {
      latitude: number;
      longitude: number;
      country_code: string;
      city: string;
    };
  };
export type ScheduledTask = BaseType & {
  date: Date;
  add: Klydo[];
  remove: Klydo[];
  tag: string;
  commands: Array<Command>;
};

export type Command = BaseType & {
  time: Date;
  local: boolean;
  timeout?: number;
  name: string;
  params?: Array<any>;
  id: string;
  devices?: Array<Device> | string[];
  filter?: Array<string>;
};

export type CommandServer = Command & {
  cmdHistory: Array<CommandHistory>;
};

export type CommandHistory = Command & {
  deviceId: string;
  executeTime: Date;
  receiveTime: Date;
  result: string;
};

export type SpecialPack = BaseType & {
  id: string;
  tag: string;
  name: string;
  pendulumImageUrl?: string;
  logoUrl?: string;
  description?: string;
  imageUrl?: string;
  pub: boolean;
  klydos?: string[];
  result?: Review;
  review?: ReviewScheme;
  createdAt?: Date;
  creatorId?: string;
  edited?: boolean;
};

export type BasePack = BaseType & {
  id: string;
  name: string;
  cover?: string;
  logo?: string;
  klydoIds?: string[];
  klydos?: Klydo[];
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  creatorId?: string;
};

export type DeviceValue<T> = {
  time: any;
  value: T;
};

export type ReviewScheme = {
  type: "delete" | "edit" | "publish";
  date: Date;
  msg?: string;
  approved?: boolean;
};

export type SocialNetwork = { network: string; link: string };

export type DraftPack = BasePack & {
  type: PackType.DRAFT;
  reviewStatus: {
    state: PackReviewStatus;
    message: string;
  };
};

export type ApprovedPack = BasePack & {
  type: PackType.APPROVED;
};
