import { Button } from "react-bootstrap";
import { DeviceCommand, MODAL_ACTIONS_INFO } from "../../../utils/util";
import { useState } from "react";
import ScheduleBulkTaskModal from "../../ScheduleBulkTaskModal";

export type DevicesCommandsProps = {
  devicesIds: string[];
};
const DevicesCommands = ({ devicesIds }: DevicesCommandsProps) => {
  const [deviceAction, setDeviceAction] = useState<DeviceCommand | null>(null);
  return (
    <>
      <div style={{ display: "flex" }}>
        {Object.keys(DeviceCommand).map((key) => {
          const command: DeviceCommand =
            DeviceCommand[key as keyof typeof DeviceCommand];
          return (
            <Button
              style={{
                marginBottom: "5px",
                marginRight: "5px",
                fontSize: "80%",
                height: "fit-content",
                padding: "6px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                if (
                  devicesIds.length > 1 &&
                  MODAL_ACTIONS_INFO[command].disableForMultiple
                )
                  return alert(
                    `Only one device can be selected for ${command}`,
                  );
                setDeviceAction(command);
              }}
            >
              {MODAL_ACTIONS_INFO[command].headline}
            </Button>
          );
        })}
      </div>
      {!!deviceAction && (
        <ScheduleBulkTaskModal
          open={true}
          handleClose={() => {
            setDeviceAction(null);
          }}
          action={deviceAction!}
          selectedDevices={devicesIds}
        />
      )}
    </>
  );
};

export default DevicesCommands;
