import { useCallback, useState } from "react";
import { Modal, Button, Row, Form, Spinner } from "react-bootstrap";
import { isVideo, resizeCloudinary } from "../../../utils/util";
import KlydoModal from "./KlydoModal";
import { FaCheckCircle, FaTrash } from "react-icons/fa";
import ButtonLoader from "../../../components/Widgets/ButtonLoader";
import MsTable from "../../../components/Widgets/Table";
import ScheduledTasksAddModal from "../../ScheduledTaskAddModal";
import { BsTag } from "react-icons/bs";
// eslint-disable-next-line import/no-unresolved
import { debounce } from "lodash";
import { FaCircleXmark } from "react-icons/fa6";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import useApprovedKlydos, {
  DisplayType,
  SearchType,
} from "../../../components/hooks/useApprovedKlydos";
import id from "../../../friendly-id/id-gen";
import useDeleteKlydoMutation from "../hooks/useDeleteKlydoMutation";
import DSSwitch from "../../system-design/DSSwitch";
import DSStack from "../../system-design/DSStack";
import DSTypography from "../../system-design/DSTypography";
import { Klydo } from "../../../api/klydo";
import DSBox from "../../system-design/DSBox";
import DSGrid from "../../system-design/DSGrid";
import DSCard from "../../system-design/DSCard";
import DSChip from "../../system-design/DSChip";
import DSDialog from "../../system-design/DSDialog";
import useScrollPagination from "../../Packs/hooks/useScrollPagination";
const filterOptions = ["Pool", "Not Pooled", "Not Scheduled", "Unlisted"];

const PAGE_SIZE = 20;

function Klydos() {
  const { spacing } = useTheme();
  const [data, setData] = useState<Array<Klydo>>();
  const [showDelete, setShowDelete] = useState<boolean | Klydo>(false);
  const [showScheduledTaskAddModal, setShowScheduledTaskAddModal] =
    useState(false);
  const [textSearch, setTextSearch] = useState<string>("");
  const [localSelected, setLocalSelected] = useState<Array<Klydo>>([]);
  const [filterValues, setFilterValues] = useState<string[]>([]);
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDirection, setSortDirection] = useState<number>(-1);
  const [displayType, setDisplayType] = useState<DisplayType>("LIST");
  const [shouldShowKlydoModal, setShouldShowKlydoModal] =
    useState<boolean>(false);
  const [selectedKlydo, setSelectedKlydo] = useState<Klydo | undefined>();

  const handleFreeSearch = useCallback(
    debounce((s) => setTextSearch(s), 500),
    [],
  );
  const [searchType, setSearchType] = useState<SearchType>("TEXT");

  const {
    data: klydos,
    fetchNextPage,
    refetch,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
  } = useApprovedKlydos(
    textSearch,
    PAGE_SIZE,
    searchType,
    {
      pool: filterValues.includes("Pool") ? true : undefined,
      unlisted: filterValues.includes("Unlisted") ? true : undefined,
      notScheduled: filterValues.includes("Not Scheduled") ? true : undefined,
      notPool: filterValues.includes("Not Pooled") ? true : undefined,
    },
    false,
    sortField,
    sortDirection,
  );

  const { scrollRef } = useScrollPagination({
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    dependencies: [displayType],
  });

  const shouldDisableFilterButton = useCallback(
    (buttonValue: string) => {
      return (
        (buttonValue === "Not Pooled" && filterValues.includes("Pool")) ||
        (buttonValue === "Pool" && filterValues.includes("Not Pooled"))
      );
    },
    [filterValues],
  );

  const handleSortClick = useCallback(
    (field: string) => {
      if (field === "favorites") {
        if (sortField === "stats.favorites") {
          setSortDirection(-sortDirection);
        } else {
          setSortField("stats.favorites");
        }
      } else {
        if (field === sortField) {
          setSortDirection(-sortDirection);
        }
        setSortField(field);
      }
    },
    [sortDirection, sortField],
  );

  const GifThumbnail = ({ loopUrl }: { loopUrl: string }) => {
    return !loopUrl || isVideo(loopUrl) === "none" ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>File type not supported</p>
      </div>
    ) : (
      <div
        style={{
          borderRadius: "50%",
          width: 96,
          height: 96,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: 0 + "%",
            left: 0 + "%",
            width: `${100}%`,
            aspectRatio: "1/1",
          }}
        >
          {isVideo(loopUrl) === "image" ? (
            <img
              loading="lazy"
              alt="gif"
              src={resizeCloudinary(loopUrl, 96)}
              style={{ width: "auto", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <video
              muted={true}
              src={resizeCloudinary(loopUrl, 96)}
              loop
              autoPlay
              style={{ width: "auto", height: "100%", objectFit: "cover" }}
            />
          )}
        </div>
      </div>
    );
  };

  const tableColumns: Array<{
    sort: boolean;
    field: string;
    label: string;
    size?: number;
    noHeadline?: boolean;
  }> = [
    {
      sort: false,
      field: "tag",
      label: "",
      size: 32,
    },
    {
      sort: true,
      label: "id",
      field: "id",
      size: 200,
    },
    {
      sort: true,
      label: "author",
      field: "authorName",
      size: 200,
    },
    {
      sort: true,
      label: "name",
      field: "name",
      size: 200,
    },
    {
      sort: false,
      label: "gif",
      field: "image",
    },
    {
      sort: true,
      label: "date",
      field: "createdAt",
      size: 220,
    },
    {
      size: 150,
      sort: true,
      label: "Favorite",
      field: "favorites",
    },
    {
      size: 128,
      sort: false,
      label: "Unlisted",
      field: "unlisted",
    },
    {
      size: 128,
      sort: false,
      label: "Public",
      field: "public",
    },
    {
      size: 128,
      sort: false,
      label: "Pool",
      field: "pool",
    },
    {
      size: 128,
      sort: false,
      label: "task",
      field: "task",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tableRows = (r: Klydo, key: number) => {
    return {
      id: <p>{r.id}</p>,
      image: GifThumbnail({ loopUrl: r.loopUrl }),
      authorName: <p title={r.creator}>{r.authorName}</p>,
      name: r.name,
      createdAt: r.createdAt?.toLocaleString("en-GB"),
      favorites: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {r?.stats?.favorites}
        </div>
      ),
      public: <p>{(!!r.public).toString()}</p>,
      unlisted: <p>{(!!r.unlisted).toString()}</p>,
      pool: <p>{(!!r.pool).toString()}</p>,
      task: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {r.inTasks ? (
            <FaCheckCircle color="green" />
          ) : (
            <FaCircleXmark color="red" />
          )}
        </div>
      ),
      key: r.id,
      tag: r.tags?.length ? (
        <BsTag title={"tags:" + r.tags?.reduce((a, b) => a + "\n" + b, "")} />
      ) : (
        ""
      ),
      delete: (
        <FaTrash
          style={{ margin: "8px" }}
          className="nc-icon nc-simple-remove"
          onClick={(e) => {
            e.stopPropagation();
            setShowDelete(r);
          }}
        />
      ),
    };
  };

  function genActions() {
    return (
      <div
        style={{
          width: "23%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setShowScheduledTaskAddModal(true)}
        >
          Add klydos To Task
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setShowDelete(true)}
        >
          Delete klydos
        </Button>
      </div>
    );
  }

  const handleKlydoUpdate = (klydo: Klydo) => {
    if (klydo) {
      setData((data ?? []).map((k: Klydo) => (k.id === klydo?.id ? klydo : k)));
    }
  };

  const KlydosFilter = () => {
    return (
      <div style={{ marginTop: "10px" }}>
        <FormControl sx={{ m: 1, minWidth: 130 }}>
          <InputLabel id="filter">Filter</InputLabel>
          <Select
            label="Filter"
            labelId="filter"
            multiple
            value={filterValues}
            onChange={(v) => {
              setFilterValues(
                typeof v.target.value === "string"
                  ? [v.target.value]
                  : v.target.value,
              );
            }}
          >
            {filterOptions.map((v) => (
              <MenuItem
                disabled={shouldDisableFilterButton(v)}
                key={v}
                value={v}
              >
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Free Search</Form.Label>
        <Form.Control
          onChange={(e) => {
            handleFreeSearch(e.target.value);
          }}
          type="search"
        />
        <DSBox py={1} />
        <DSBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "4%",
          }}
        >
          <DSStack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
            <DSTypography>Text</DSTypography>
            <DSSwitch
              value={searchType === "TEXT"}
              onClick={() => {
                setFilterValues([]);
                setSearchType((prev) =>
                  prev === "TEXT" ? "SIMILARITY" : "TEXT",
                );
              }}
            />
            <DSTypography>Semantic</DSTypography>
          </DSStack>
          <DSStack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
            <DSTypography>List</DSTypography>
            <DSSwitch
              value={displayType === "LIST"}
              onClick={() => {
                setDisplayType((prev) => (prev === "LIST" ? "GRID" : "LIST"));
              }}
            />
            <DSTypography>Grid</DSTypography>
          </DSStack>
        </DSBox>
        <KlydosFilter />
      </Form.Group>
      {klydos && !isLoading ? (
        displayType === "LIST" ? (
          <MsTable
            id="klydos"
            cancelSort={true}
            actions={() => genActions()}
            onScrollDown={fetchNextPage}
            onAction={(selected: Array<Klydo>) => {
              setLocalSelected(selected);
            }}
            rowBuild={tableRows}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            search={(row: any, val: string) => {
              return (
                id.friendlyFromHex(row.id).includes(val) ||
                id.friendlyFromHex(row.id).includes(val) ||
                row.author?.toLowerCase().includes(val) ||
                row.name.toLowerCase().includes(val)
              );
            }}
            data={klydos}
            initSort={"createdAt"}
            cols={tableColumns}
            title=""
            handleChangedKlydo={handleKlydoUpdate}
            modal={KlydoModal}
            shouldHideSearchBar
            onSortClick={handleSortClick}
          />
        ) : (
          <DSBox
            sx={{ overflowY: "scroll", height: "100%", display: "flex" }}
            ref={scrollRef}
          >
            <DSGrid
              container
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  xs: "repeat(2, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(3, 1fr)",
                  xl: "repeat(5, 1fr)",
                  xxl: "repeat(5, 1fr)",
                },
              }}
            >
              {klydos.map((klydo) => (
                <DSGrid key={klydo.id} sx={{ padding: spacing(1) }}>
                  <DSCard
                    sx={{
                      width: "95%",
                      height: "240px",
                      alignItems: "center",
                      borderRadius: "12px",
                      flexDirection: "column",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    onClick={() => {
                      setShouldShowKlydoModal(true);
                      setSelectedKlydo(klydo);
                    }}
                  >
                    <GifThumbnail loopUrl={klydo.loopUrl} />

                    <DSTypography>{klydo.name}</DSTypography>
                    <DSTypography>{klydo.authorName}</DSTypography>
                    <DSBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: spacing(1),
                      }}
                    >
                      <DSChip
                        variant={klydo.public ? "filled" : "outlined"}
                        label="Public"
                      />
                      <DSChip
                        variant={klydo.pool ? "filled" : "outlined"}
                        label="Pool"
                      />
                      <DSChip
                        variant={klydo.unlisted ? "filled" : "outlined"}
                        label="Unlisted"
                      />
                      <DSChip
                        variant={klydo.inTasks ? "filled" : "outlined"}
                        label="Task"
                      />
                    </DSBox>
                  </DSCard>
                </DSGrid>
              ))}
            </DSGrid>
          </DSBox>
        )
      ) : (
        <Spinner></Spinner>
      )}
      <ScheduledTasksAddModal
        open={showScheduledTaskAddModal}
        handleClose={(add: boolean) => {
          setShowScheduledTaskAddModal(add);
        }}
        klydos={localSelected}
      />
      {selectedKlydo && (
        <DSDialog
          fullWidth
          maxWidth="lg"
          open={shouldShowKlydoModal}
          onClose={() => setShouldShowKlydoModal(false)}
          //TODO: remove zIndex
          sx={{ zIndex: 1 }}
        >
          <KlydoModal
            klydoId={selectedKlydo.id}
            setShowModal={setShouldShowKlydoModal}
          />
        </DSDialog>
      )}
      {showDelete && (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <DeleteModal
          done={() => {
            setShowDelete(false);
            refetch();
          }}
          localSelected={showDelete === true ? localSelected : undefined}
          toRemove={showDelete === true ? undefined : showDelete}
        ></DeleteModal>
      )}
    </>
  );
}

export default Klydos;

function DeleteModal(props: {
  toRemove?: Klydo;
  localSelected?: Klydo[];
  done: () => void;
}) {
  const [deleteMsg, setDeleteMsg] = useState("");
  const { mutateAsync: deleteKlydo } = useDeleteKlydoMutation({
    onSettled: () => props?.done?.(),
  });
  return (
    <Modal show={true} onHide={() => props.done()}>
      <Modal.Header style={{ justifyContent: "right" }}>
        <i
          className="nc-icon nc-simple-remove"
          onClick={() => props.done()}
        ></i>
      </Modal.Header>
      <Modal.Body>
        {props.toRemove ? (
          <h4>
            Are you sure you want to remove klydo "{props.toRemove.name}"?
          </h4>
        ) : (
          <>
            <h4>Are you sure you want to remove these klydos?</h4>
            {props.localSelected!.map((k) => (
              <p key={k.id}>{k.name}</p>
            ))}
          </>
        )}
        <Row className="h-100">
          <Form.Control
            as="textarea"
            rows={4}
            value={deleteMsg}
            onChange={(e) => setDeleteMsg(e.target.value)}
          ></Form.Control>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ width: 180, margin: 10 }} onClick={() => props.done()}>
          No
        </Button>
        <ButtonLoader
          style={{ width: 180, margin: 10 }}
          title="Yes"
          onClick={async () => {
            if (props.toRemove?.id) {
              await deleteKlydo(props.toRemove.id);
            } else if (props.localSelected) {
              await Promise.all(
                props.localSelected.map((k) => deleteKlydo(k.id)),
              );
            }
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
