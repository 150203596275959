import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { Gift } from "../../../../api/giftCategories";
import { NavLink } from "react-router-dom";
import useOrderQuery from "../hooks/useOrderInfoQuery";
import useOrderGiftMutation from "../hooks/useOrderGiftMutation";
import firebaseService from "../../../../firebase_service/firebaseService";
import { useCallback, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DSBox from "../../../system-design/DSBox";
import DSIconButton from "../../../system-design/DSIconButton";
import DSTextField from "../../../system-design/DSTextField";
import DSSwitch from "../../../system-design/DSSwitch";
import DSTypography from "../../../system-design/DSTypography";

const GiftOrderModal = (props: {
  row: Gift;
  data: Gift[];
  setShowModal: (b: boolean) => void;
}) => {
  const [editGift, setEditGift] = useState<boolean>(false);
  const row =
    props.data.find((d) => d.orderId === props.row.orderId) || props.row;
  const [giftOrder, setGiftOrder] = useState<Gift>(props.row);
  const { data: orderInfo } = useOrderQuery(row.orderId);
  const { mutate: editOrderGift } = useOrderGiftMutation(row.orderId);
  const [clockFriendlies, setClockFriendlies] = useState<string>("");
  const handleEditGift = () => {
    setEditGift(!editGift);
  };

  const handleGreetingContentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setGiftOrder({ ...giftOrder, greetingContent: e.target.value });
    },
    [giftOrder, setGiftOrder],
  );

  const handleSenderNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setGiftOrder({ ...giftOrder, senderName: e.target.value });
    },
    [giftOrder, setGiftOrder],
  );

  const handleOrderIdChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setGiftOrder({
        ...giftOrder,
        orderId: e.target.value,
        clocksIds: [],
        submitted: false,
      });
    },
    [giftOrder, setGiftOrder],
  );

  const handleSaveGift = useCallback(() => {
    editOrderGift({ ...giftOrder });
    setEditGift(false);
  }, [giftOrder, editOrderGift]);

  const updateOrderClocks = async () => {
    const idfs = clockFriendlies.split(",").map((c) => c.trim());
    const machines = await firebaseService.getDevicesByIdfs(idfs);
    const clocksIds = machines.map((m) => m.id);
    editOrderGift({ clocksIds });
  };
  return (
    <>
      <Modal.Header>
        <DSBox
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h4>{"Order Gift Details"}</h4>
          <DSIconButton onClick={handleEditGift}>
            <EditIcon />
          </DSIconButton>
        </DSBox>

        <i
          className="nc-icon nc-simple-remove"
          onClick={() => {
            props.setShowModal(false);
          }}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <p>
                <strong>Order Id:</strong>
                {editGift ? (
                  <DSBox>
                    <DSTextField
                      value={giftOrder.orderId}
                      onChange={handleOrderIdChange}
                    />
                    <DSTypography color="error">
                      Once you edit the order ID and Save, the clock’s gift will
                      be removed.
                    </DSTypography>
                  </DSBox>
                ) : (
                  giftOrder.orderId
                )}
              </p>
              <p>
                <strong>Gift Id:</strong> {row.giftId}
              </p>
              <p>
                <strong>
                  Manual override (mark if manually adjusted fields, to avoid
                  alerts):
                </strong>{" "}
                <DSSwitch
                  checked={!!row?.manualOverride}
                  onChange={(event) => {
                    editOrderGift({ manualOverride: event.target.checked });
                  }}
                />
              </p>
              <p>
                <strong>Sender Name:</strong>
                {editGift ? (
                  <DSTextField
                    value={giftOrder.senderName}
                    onChange={handleSenderNameChange}
                  />
                ) : (
                  giftOrder.senderName
                )}
              </p>
              <p>
                <strong>Full Name: </strong>
                {`${orderInfo?.first_name} ${orderInfo?.last_name}`}
              </p>
              <p>
                <strong>Email: </strong>
                {orderInfo?.email}
              </p>
              <p>
                <strong>Greeting:</strong>
                {editGift ? (
                  <DSTextField
                    value={giftOrder.greetingContent}
                    onChange={handleGreetingContentChange}
                    multiline
                    fullWidth
                    rows={5}
                    maxRows={8}
                    slotProps={{
                      input: {
                        inputProps: { maxLength: 160 },
                        style: {
                          padding: "20px",
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        },
                      },
                    }}
                    sx={{
                      ".MuiInputBase-input": {
                        overflowWrap: "break-word",
                        fontSize: "15px",
                        maxWidth: "300px",
                        display: "block",
                      },
                    }}
                  />
                ) : (
                  giftOrder.greetingContent
                )}
              </p>
              <p>
                <strong>Landing Page: </strong>
                {orderInfo?.firstVisit?.landingPage}
              </p>
              <p>
                <strong>Klydo ID:</strong>
                {
                  <NavLink
                    to="/admin/klydos"
                    onClick={() => {
                      localStorage.setItem("klydossearch", row.klydoId);
                    }}
                  >
                    {row.klydoId || "-No klydo available"}
                  </NavLink>
                }
              </p>
              {row.date && (
                <p>
                  <strong>Date:</strong>{" "}
                  {new Date(row.date).toLocaleDateString()}
                </p>
              )}
              {row.clocksIds && (
                <p>
                  <strong>Clock IDs:</strong> {row.clocksIds.join(", ")}
                </p>
              )}
              <Form>
                <Form.Group controlId="add clocks">
                  <Form.Label>Set Clocks</Form.Label>
                  <Form.Control
                    style={{ display: "inline-block" }}
                    type="text"
                    placeholder="Enter clocks friendly ids, seperated by ',' (no spaces)"
                    value={clockFriendlies}
                    onChange={(e) => setClockFriendlies(e.target.value)}
                  />
                  <Button
                    disabled={!clockFriendlies}
                    onClick={updateOrderClocks}
                  >
                    Set
                  </Button>
                </Form.Group>
              </Form>

              {row.senderImageUrl && (
                <div>
                  <p>
                    <strong>Sender Image:</strong>
                  </p>
                  <img
                    src={row.senderImageUrl}
                    alt="Sender"
                    style={{ maxWidth: "100px", borderRadius: "50%" }}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button
          variant="primary"
          onClick={() =>
            window.open(
              `https://editor.klydoclock.com/gift?orderId=${row.orderId}`,
              "_blank",
            )
          }
        >
          Watch Gift
        </Button>
        {editGift && (
          <Button variant="primary" onClick={handleSaveGift}>
            Save
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default GiftOrderModal;
