import { useInfiniteQuery } from "@tanstack/react-query";
import userApiClient from "../../../api/admin";

const LIMIT = 3;
const useUsersQuery = (query: string) => {
  return useInfiniteQuery({
    queryKey: ["users", query],
    queryFn: async ({ pageParam }) => {
      const { skip = 0 } = pageParam as { skip?: number };
      const searchOptions = {
        limit: LIMIT,
        skip,
      };
      const res = await userApiClient.searchUsers({
        query,
        ...searchOptions,
        options: {
          limit: 0,
          skip: 0,
        },
        searchFields: ["name", "email"],
      });

      return res;
    },
    getNextPageParam: (lastPage, allPages) => {
      const currentLength = allPages.flat().length;
      return currentLength < LIMIT ? undefined : { skip: currentLength };
    },
    initialPageParam: { skip: 0 },
    enabled: !!query,
  });
};

export default useUsersQuery;
