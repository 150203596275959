import { Klydo, KlydoTimes, timeTypes } from "Types";
import { useState } from "react";
import { FaEdit, FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import firebaseService from "firebase_service/firebaseService";
import { percentToPresent, WeekEnum } from "../../utils/util";
import { calcTimes } from "./TimeCalc";

const tTypes = ["daily", "weekly", "monthly", "yearly"];
const weekDays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const TimeControl = (props: {
  klydo: Klydo;
  editable?: boolean;
  isReview?: boolean;
}) => {
  const [times, setTimes] = useState(
    props.klydo?.times ?? ([] as KlydoTimes[]),
  );
  const [editTimes, setEditTimes] = useState([] as KlydoTimes[]);
  const [warnings, setWarnings] = useState<Array<string>>([]);
  const [globalWarning, setGlobalWarning] = useState("");
  const [modal, setModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const closeModal = () => {
    setEditTimes([]);
    setModal(false);
  };
  const checkGlobalWarning = (): boolean => {
    let type = undefined as timeTypes | undefined;
    let res = true;
    editTimes.forEach((t) => {
      if (!!!type) {
        if (t.type !== "daily") type = t.type;
      } else {
        if (t.type !== "daily" && t.type !== type) {
          res = false;
        }
      }
    });
    res
      ? setGlobalWarning("")
      : setGlobalWarning("Only one type of date is permitted");
    return res;
  };
  const resetTypeInput = (i: number, type: timeTypes) => {
    switch (type) {
      case "daily": {
        editTimes[i].start = "00:00";
        editTimes[i].end = "23:59";
        break;
      }
      case "weekly": {
        editTimes[i].start = "sunday";
        editTimes[i].end = "saturday";
        break;
      }
      case "monthly": {
        editTimes[i].start = "1";
        editTimes[i].end = "31";
        break;
      }
      case "yearly": {
        editTimes[i].start = "01/01";
        editTimes[i].end = "31/12";
        break;
      }
    }
    setEditTimes([...editTimes]);
  };
  const dateChek = (s: string): string => {
    const chk = s.split("/");
    if (chk.length !== 2) return "Date format is dd/mm";
    if (chk[0].length === 0 || chk[1].length === 0) return "Incomplete date";
    if (
      !(
        /^(([0-2]?[0-9])|(3[0-1]))$/.test(chk[0]) &&
        /^((0?[0-9])|(1[0-2]))$/.test(chk[1])
      )
    )
      return "Incorect Date";
    if (
      (chk[0] === "30" && /^0?2$/.test(chk[1])) ||
      (chk[0] === "31" && /^(0?[2469]|11)$/.test(chk[1]))
    )
      return "Date do not exist";
    return "";
  };
  const inputType = (i: number) => {
    switch (editTimes[i].type) {
      case "daily":
        return (
          <div
            style={{
              width: "500px",
              marginLeft: 12,
              marginRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>start time:</p>
            <Form.Control
              style={{ width: "120px" }}
              value={editTimes[i].start}
              onChange={(e) => {
                editTimes[i].start = e.target.value;
                setEditTimes([...editTimes]);
              }}
              type="time"
            />
            <p>end time:</p>
            <Form.Control
              style={{ width: "120px" }}
              value={editTimes[i].end}
              onChange={(e) => {
                editTimes[i].end = e.target.value;
                setEditTimes([...editTimes]);
              }}
              type="time"
            />
          </div>
        );
      case "weekly":
        return (
          <div
            style={{
              width: "500px",
              marginLeft: 12,
              marginRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>start day:</p>
            <Form.Select
              style={{ width: "120px" }}
              value={editTimes[i].start}
              onChange={(e) => {
                editTimes[i].start = e.target.value;
                setEditTimes([...editTimes]);
              }}
            >
              {weekDays.map((t, i) => (
                <option key={i} value={t}>
                  {t}
                </option>
              ))}
            </Form.Select>
            <p>end day:</p>
            <Form.Select
              style={{ width: "120px" }}
              value={editTimes[i].end}
              onChange={(e) => {
                editTimes[i].end = e.target.value as string;
                setEditTimes([...editTimes]);
              }}
            >
              {weekDays.map((t, i) => (
                <option key={i} value={t}>
                  {t}
                </option>
              ))}
            </Form.Select>
          </div>
        );
      case "monthly":
        return (
          <div
            style={{
              width: "500px",
              marginLeft: 12,
              marginRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>start day:</p>
            <Form.Control
              style={{ width: "120px" }}
              value={editTimes[i].start}
              onChange={(e) => {
                editTimes[i].start = e.target.value;
                setEditTimes([...editTimes]);
              }}
              type="number"
              max={31}
              min={1}
            />
            <p>end day:</p>
            <Form.Control
              style={{ width: "120px" }}
              value={editTimes[i].end}
              onChange={(e) => {
                editTimes[i].end = e.target.value;
                setEditTimes([...editTimes]);
              }}
              type="number"
              max={31}
              min={1}
            />
          </div>
        );
      case "yearly":
        return (
          <div
            style={{
              width: "500px",
              marginLeft: 12,
              marginRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>start date:</p>
            <Form.Control
              style={{ width: "120px" }}
              value={editTimes[i].start}
              onChange={(e) => {
                const w = dateChek(e.target.value);
                if (w === "" || w === "Incomplete date") {
                  editTimes[i].start = e.target.value;
                  setEditTimes([...editTimes]);
                }
              }}
            />
            <p>end date:</p>
            <Form.Control
              style={{ width: "120px" }}
              value={editTimes[i].end}
              onChange={(e) => {
                const w = dateChek(e.target.value);
                if (
                  w === "" ||
                  w === "Incomplete date" ||
                  w === "Date format is dd/mm"
                ) {
                  editTimes[i].end = e.target.value;
                  setEditTimes([...editTimes]);
                }
              }}
            />
          </div>
        );
    }
  };
  const checkWarnings = (): boolean => {
    let res = true;
    let typwarn = !checkGlobalWarning();
    const warnArr = Array<string>(editTimes.length);
    const inOrderDaily = (s: string, e: string): boolean => {
      const st = s.split(":");
      const et = e.split(":");
      return !(
        parseInt(st[0]) > parseInt(et[0]) ||
        (parseInt(st[0]) === parseInt(et[0]) &&
          parseInt(st[1]) > parseInt(et[1]))
      );
    };
    const inOrderYearly = (s: string, e: string): boolean => {
      const st = s.split("/");
      const et = e.split("/");
      return !(
        parseInt(st[1]) > parseInt(et[1]) ||
        (parseInt(st[1]) === parseInt(et[1]) &&
          parseInt(st[0]) > parseInt(et[0]))
      );
    };
    editTimes.forEach((t, i) => {
      let warn = "";
      if (typwarn && t.type !== "daily") {
        warn = "Only one type of date is permitted";
      } else {
        switch (t.type) {
          case "daily": {
            if (!inOrderDaily(t.start, t.end)) warn = "Wrong order";
            break;
          }
          case "yearly": {
            warn = dateChek(t.start) || dateChek(t.end);
            if (warn !== "") break;
            if (!inOrderYearly(t.start, t.end)) warn = "Wrong order";
            break;
          }
          case "monthly": {
            const start = parseInt(t.start);
            const end = parseInt(t.end);
            if (Number.isNaN(start) || Number.isNaN(end)) {
              warn = "Incorect input - must be a number";
            } else if (start > end) warn = "Wrong order";
            else if (start < 1 || start > 31 || end < 1 || end > 31)
              warn = "Incorect input - a number between 1 to 31";
            break;
          }
          case "weekly": {
            if (
              WeekEnum[t.start.toUpperCase() as any] >
              WeekEnum[t.end.toUpperCase() as any]
            )
              warn = "Wrong order";
            break;
          }
        }
      }
      if (warn !== "") res = false;
      warnArr[i] = warn;
    });
    setWarnings([...warnArr]);
    return res;
  };

  return (
    <>
      <div style={{ marginBottom: 14 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h5>
            <b>Best fit at:</b>
          </h5>
          {props.editable && (
            <FaEdit
              size={20}
              style={{ marginLeft: 10 }}
              onClick={() => {
                setEditTimes(JSON.parse(JSON.stringify(times)));
                setModal(true);
              }}
            />
          )}
        </div>
        {times?.length > 0 ? (
          <>
            {times.map((t, i) => (
              <div key={i} style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    margin: 0,
                    paddingLeft: t.negative ? 2 : 0,
                    paddingRight: t.negative ? 10 : 8,
                  }}
                >
                  {t.negative ? "-" : "+"}
                </p>
                <p style={{ margin: 0 }}>
                  type: {t.type}, from: {t.start} to: {t.end}
                </p>
              </div>
            ))}
          </>
        ) : (
          <p style={{ margin: 0 }}>all year round</p>
        )}
        <p>Available {percentToPresent(calcTimes(times))} of the year</p>
      </div>
      <Modal show={modal} onHide={closeModal} size="xl">
        <Modal.Header>Define times this klydo best fit</Modal.Header>
        <Modal.Body>
          {globalWarning && <div style={{ color: "red" }}>{globalWarning}</div>}
          {editTimes?.map((t, i) => (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "12px",
                }}
              >
                {t.negative ? <FaMinus size={24} /> : <FaPlus size={24} />}
                <Form.Select
                  value={editTimes[i].type}
                  style={{ width: 150 }}
                  onChange={(e) => {
                    if (editTimes[i].type !== e.target.value) {
                      checkGlobalWarning();
                      resetTypeInput(i, e.target.value as timeTypes);
                      editTimes[i].type = e.target.value as timeTypes;
                    }
                    setEditTimes([...editTimes]);
                  }}
                >
                  {tTypes.map((t, i) => (
                    <option key={i} value={t}>
                      {t}
                    </option>
                  ))}
                </Form.Select>
                {inputType(i)}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                  }}
                >
                  <Form.Check className="mb-1 pl-0">
                    <Form.Check.Label
                      style={{ color: "black", paddingLeft: 0 }}
                    >
                      <Form.Check.Input
                        checked={editTimes[i].negative}
                        onChange={() => {
                          editTimes[i].negative = !editTimes[i].negative;
                          setEditTimes([...editTimes]);
                        }}
                      />
                      <span className="form-check-sign"></span>
                      <h5>
                        <b>Do not show in this time</b>
                      </h5>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
                <FaTrash
                  onClick={() => {
                    editTimes.splice(i, 1);
                    setEditTimes([...editTimes]);
                  }}
                />
              </div>
              <p style={{ marginLeft: 226, color: "red" }}>
                {warnings[i] ?? ""}
              </p>
            </>
          ))}
          <Button
            onClick={() => {
              editTimes.push({
                type: "daily",
                start: "00:00",
                end: "23:59",
                negative: false,
              });
              setEditTimes([...editTimes]);
            }}
          >
            Add time or date
          </Button>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              disabled={saving}
              onClick={() => {
                if (checkWarnings()) {
                  setSaving(true);
                  props.klydo.times = editTimes;
                  firebaseService
                    .updateKlydoTimes(
                      props.klydo,
                      editTimes,
                      props.isReview ?? false,
                    )
                    .then(() => {
                      setTimes(JSON.parse(JSON.stringify(editTimes)));
                      closeModal();
                    })
                    .catch((e) =>
                      setGlobalWarning("Saving error:" + e.toString()),
                    )
                    .finally(() => setSaving(false));
                }
              }}
            >
              <div style={{ display: "flex" }}>
                {saving && <Spinner />}
                Save
              </div>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TimeControl;
