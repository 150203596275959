import React from "react";
import DSListItem from "../../system-design/DSListItem";
import { SearchUserOutput } from "../../../api/admin";
import DSTextField from "../../system-design/DSTextField";
import DSCircularProgress from "../../system-design/DSCircularProgress";
import DSAutocomplete from "../../system-design/DSAutocomplete";
import useUserDropdown from "../hooks/useUserDropdown";

type UserDropdownProps = {
  onOptionSelect: (value: string) => void;
  id?: string;
};

const UserDropdown: React.FC<UserDropdownProps> = ({ onOptionSelect, id }) => {
  const {
    options,
    handleInputChange,
    handleOptionSelect,
    isLoading,
    spacing,
    dropDownValue,
    searchText,
  } = useUserDropdown({ onOptionSelect, id });
  return (
    <DSAutocomplete
      options={options}
      getOptionLabel={(option: SearchUserOutput) => option.name}
      renderOption={(props, option) => (
        <DSListItem {...props} key={option.id || option.name}>
          {option.name}
        </DSListItem>
      )}
      inputValue={searchText}
      value={dropDownValue}
      onInputChange={(event, value) => {
        handleInputChange(event, value);
      }}
      onChange={handleOptionSelect}
      renderInput={(params) => (
        <DSTextField
          {...params}
          placeholder=""
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <DSCircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            width: spacing(30),
            ".MuiInputBase-root": {
              padding: 0,
            },
          }}
        />
      )}
      sx={{
        minWidth: spacing(18),
      }}
    />
  );
};

export default UserDropdown;
