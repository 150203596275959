import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import MultiSelect from "../../components/forms/MultiSelect";
import isImageUploaded from "../../utils/isImageUploaded";
import uploadFile from "../../utils/uploadFiles";
import KlydoListItem from "../KlydoListItem";
import ButtonLoader from "../../components/Widgets/ButtonLoader";
import SpecialPackview from "./SpecialPackView";
import firebaseService from "../../firebase_service/firebaseService";
import { IoMdAdd } from "react-icons/io";
import { Klydo, SpecialPack } from "../../Types";
import useUsers from "../../components/hooks/useUsers";

const getRandomTag = (): string => {
  let rndTag = "$p_";
  const chars = "abcdefghijklmnopqrstuvwxyz";
  for (let i = 0; i < 5; i++) {
    const rndIndex = Math.floor(Math.random() * chars.length);
    rndTag += chars.charAt(rndIndex);
  }
  return rndTag;
};

const SpecialPackModal = (props: {
  row?: SpecialPack;
  setShowModal: (b: boolean) => void;
  data: SpecialPack[];
}) => {
  const scale = 1;
  const w = scale * 390;
  const h = scale * 643.5;
  const [imageUrl, setImageUrl] = useState<any>(
    props.row && props.row.imageUrl,
  );
  const [logoUrl, setLogoUrl] = useState<any>(props.row && props.row.logoUrl);
  const [pendulumImageUrl, setPendulumImageUrl] = useState<any>(
    props.row && props.row.pendulumImageUrl,
  );
  const [error, setError] = useState<{
    server?: string;
    klydo?: string;
    pic?: string;
  }>({});
  const [name, setName] = useState<string>(props.row?.name || "");
  const [pub, setPub] = useState<boolean>(props.row?.pub || false);
  const [artists, setArtists] = useState<string[]>(props.row?.artists || []);
  const [tag] = useState<string>(props.row ? props.row.tag : getRandomTag());
  const [description, setDescription] = useState<string>(
    props.row?.description || "",
  );
  const [countChar, setCountChar] = useState<number>();
  const [draftKlydoIds, setDraftKlydoIds] = useState<string[]>(
    props.row?.klydos || [],
  );
  const [removedKlydos, setRemovedKlydos] = useState<string[]>([]);
  const [klydoValid, setKlydoValid] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<string>();
  const [inputText, setInputText] = useState<string>("");
  const { data: users } = useUsers();
  const [selectedKlydoId, setSelectedKlydoId] = useState<string>();
  const [suggestions, setSuggestions] = useState<Array<Klydo>>();
  const [showPubModal, setShowPubModal] = useState<boolean>(false);
  let pubKlydos: Klydo[] | undefined = undefined;
  const descriptionMaxLength = 420;

  useEffect(() => {
    let klydo: Klydo | undefined = undefined;
    let validated = false;
    let idf = inputText.split("-").length === 3;
    firebaseService
      .findItem<Klydo>("klydos", idf ? "idf" : "id", inputText)
      .then((v) => {
        klydo = v;
        validated = klydo !== undefined;
        setKlydoValid(validated);

        setError((error) =>
          validated || inputText.length === 0
            ? { ...error, klydo: "" }
            : { ...error, klydo: "There is no such klydo" },
        );
        if (validated) setSelectedKlydoId(klydo!.id);
      });
  }, [inputText]);

  const handleRemoveKlydo = (klydoId: string) => {
    setDeleting(klydoId);
    removedKlydos.push(klydoId);
    setRemovedKlydos((prev) => prev.slice());
    if (draftKlydoIds) {
      draftKlydoIds.splice(draftKlydoIds.indexOf(klydoId), 1);
    }
    setDraftKlydoIds((prev) => prev?.slice());
    setDeleting(undefined);
  };

  const savePack = async () => {
    const prmss: Promise<void>[] = [];
    const [uploadedImageUrl, uploadedPendulumImageUrl, uploadedLogoUrl] =
      await Promise.all([
        isImageUploaded(imageUrl)
          ? imageUrl
          : uploadFile({
              file: imageUrl,
              folder: "pack_covers",
              upload_preset: "coverpacks",
            }),
        isImageUploaded(pendulumImageUrl)
          ? pendulumImageUrl
          : uploadFile({
              file: pendulumImageUrl,
              folder: "pack_pendulum_covers",
              upload_preset: "packpendulumconvers",
            }),
        isImageUploaded(logoUrl)
          ? logoUrl
          : uploadFile({
              file: logoUrl,
              folder: "pack_logos",
              upload_preset: "packlogos",
            }),
      ]);
    if (props.row) {
      const klydoDif =
        props.row.klydos!.length !== draftKlydoIds!.length ||
        props.row.klydos!.some(
          (klydo) => !draftKlydoIds?.find((klydoId) => klydoId === klydo),
        ) ||
        draftKlydoIds!.some(
          (klydoId) => !props.row?.klydos?.find((klydo) => klydo === klydoId),
        );

      if (
        name !== props.row.name ||
        description !== props.row.description ||
        uploadedImageUrl !== props.row.imageUrl ||
        uploadedPendulumImageUrl !== props.row.pendulumImageUrl ||
        uploadedLogoUrl !== props.row.logoUrl ||
        artists.some((val, i) => props.row?.artists?.[i] !== val) ||
        pub !== props.row.pub ||
        klydoDif
      ) {
        prmss.push(
          firebaseService.updatePack({
            id: props.row.id,
            name: name,
            description: description || "",
            imageUrl: uploadedImageUrl || "",
            pendulumImageUrl: uploadedPendulumImageUrl,
            logoUrl: uploadedLogoUrl,
            artists: artists,
            pub: pub,
            tag: tag,
            klydos: draftKlydoIds,
          }),
        );
      }
    } else
      prmss.push(
        firebaseService.addPack(
          name,
          tag!,
          pub,
          description,
          uploadedImageUrl,
          uploadedPendulumImageUrl,
          artists,
          uploadedLogoUrl,
          draftKlydoIds,
        ),
      );
    return Promise.all(prmss).catch((e) => {
      console.log("e:", e);
      setError({ ...error, server: e.toLocaleString() });
    });
  };

  return (
    <>
      <Modal.Header
        style={{
          padding: 0,
          marginLeft: "10px",
          marginRight: "5px",
          marginBottom: "24px",
        }}
      >
        <h4 style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}>
          {props.row ? "Pack Info" : "Add New Pack"}
        </h4>
        <i
          style={{ bottom: "7px", position: "relative" }}
          className="nc-icon nc-simple-remove"
          onClick={() => {
            props.setShowModal(false);
          }}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row
            style={{
              paddingBottom: 8,
            }}
          >
            <Col>
              <Form noValidate onSubmit={savePack} id="newPackForm">
                <Row>
                  <Col sm="6" style={{ width: "100%" }}>
                    <Form.Group
                      controlId="formAddName"
                      style={{ display: "flex" }}
                    >
                      <Form.Label style={{ padding: 5, width: "20%" }}>
                        <b>Pack's Name:</b>
                      </Form.Label>
                      <Col>
                        <Form.Control
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="name"
                          placeholder="Enter name..."
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6" style={{ width: "100%" }}>
                    <Form.Group
                      controlId="formAddTag"
                      style={{ display: "flex", height: "40px" }}
                    >
                      <Form.Label style={{ padding: 5, width: "20%" }}>
                        <b>Tag:</b>
                      </Form.Label>
                      <Col>
                        <Form.Control required value={tag} disabled={true} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group
                  controlId="formAddDescription"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 35,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Label style={{ padding: 5, width: "20%" }}>
                      <b>Description:</b>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="textarea"
                        required
                        style={{ height: "160px" }}
                        maxLength={descriptionMaxLength}
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setCountChar(
                            descriptionMaxLength - e.target.value.length,
                          );
                        }}
                      ></Form.Control>
                      {countChar === undefined ? (
                        <></>
                      ) : (
                        <p
                          style={{
                            color: countChar <= 10 ? "red" : "green",
                            fontSize: "10px",
                          }}
                        >
                          characters left: {countChar}
                        </p>
                      )}
                    </Col>
                  </div>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Pack Cover</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = (e.target as HTMLInputElement)
                            ?.files?.[0];
                          if (file) {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                              setImageUrl(fileReader.result as string);
                            };
                            fileReader.readAsDataURL(file);
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Pack Icon</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png"
                        onChange={(e) => {
                          const file = (e.target as HTMLInputElement)
                            ?.files?.[0];
                          if (file) {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                              setPendulumImageUrl(fileReader.result as string);
                              setLogoUrl(fileReader.result as string);
                            };
                            fileReader.readAsDataURL(file);
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* <Form.Group as={Col} controlId="my_multiselect_field"> */}
                  <Form.Label style={{ padding: 5, width: "10%" }}>
                    <b>Artists:</b>
                  </Form.Label>
                  {users && (
                    <MultiSelect
                      label={"Artists"}
                      // multiple
                      value={artists}
                      onChange={(e) => {
                        setArtists(e);
                      }}
                      options={users.map((user) => ({
                        value: user.id,
                        label: user.name || user.email,
                        text: user.name || user.email,
                        key: user.id,
                      }))}
                    />
                  )}
                </Row>
                <Col>
                  <Form.Label style={{ padding: 5, width: "10%" }}>
                    <b>Klydos:</b>
                  </Form.Label>
                  {draftKlydoIds && draftKlydoIds.length > 0 ? (
                    <div>
                      <ListGroup
                        style={{
                          width: "50%",
                          borderRadius: 0,
                          border: "none",
                        }}
                        className="border-0"
                      >
                        <ListGroup.Item style={{ border: 0, height: "90%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "12px",
                              color: "gray",
                              border: 0,
                            }}
                          >
                            <div style={{ width: 110 }} className="mb-1">
                              IDF
                            </div>
                            <div style={{ width: 110 }} className="mb-1">
                              Author
                            </div>
                            <div
                              style={{
                                width: 35,
                                left: "7px",
                                position: "relative",
                              }}
                              className="mb-1"
                            >
                              GIF
                            </div>
                            <div
                              style={{ width: 25, position: "relative" }}
                              className="mb-1"
                            >
                              Pool
                            </div>
                            <div style={{ width: 6 }} className="mb-1"></div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                      <ListGroup
                        style={{
                          width: "50%",
                          borderRadius: 0,
                          border: "none",
                          maxHeight: "190px",
                          overflowY: "auto",
                        }}
                        className="border-0"
                      >
                        {draftKlydoIds!.map((klydoId) => (
                          <KlydoListItem
                            key={klydoId}
                            klydoId={klydoId}
                            onRemove={handleRemoveKlydo}
                          />
                        ))}
                      </ListGroup>
                    </div>
                  ) : draftKlydoIds ? (
                    <p>No klydos in draft</p>
                  ) : (
                    <Spinner />
                  )}
                  <Form
                    style={{ width: "90%", flexDirection: "column" }}
                    className="d-flex justify-content-center"
                  >
                    {draftKlydoIds && (
                      <div
                        style={{
                          width: "90%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputGroup style={{ width: "53%" }}>
                          <Form.Control
                            style={{
                              zIndex: 0,
                              borderRadius: "0.375rem",
                              borderLeft: "outset",
                              borderRight: "inset",
                              border: "1px solid #969696",
                            }}
                            isValid={klydoValid}
                            placeholder="Add klydo..."
                            required
                            value={inputText}
                            onChange={(e) => {
                              setInputText(e.target.value);
                              let idf = inputText.split("-").length === 3;
                              let klydo: Klydo | undefined = undefined;
                              let validated = false;
                              firebaseService
                                .findItem<Klydo>(
                                  "klydos",
                                  idf ? "idf" : "id",
                                  inputText,
                                )
                                .then((v) => {
                                  klydo = v;
                                  validated = klydo !== undefined;
                                  setKlydoValid(validated);
                                  if (validated) {
                                    setSelectedKlydoId(klydo!.id);
                                  }
                                });
                              setError(
                                klydoValid || inputText.length === 0
                                  ? { ...error, klydo: "" }
                                  : {
                                      ...error,
                                      klydo: "There is no such klydo",
                                    },
                              );
                              if (klydoValid) {
                                setSuggestions(undefined);
                                setError(
                                  draftKlydoIds!.includes(selectedKlydoId!)
                                    ? {
                                        ...error,
                                        klydo: "klydo already listed",
                                      }
                                    : { ...error, klydo: "" },
                                );
                              } else
                                firebaseService
                                  .getData<Klydo>("klydos")
                                  .then((v) =>
                                    setSuggestions(
                                      v.filter(
                                        (klydo: Klydo) =>
                                          !draftKlydoIds.includes(klydo.id) &&
                                          klydo.id.includes(inputText),
                                      ),
                                    ),
                                  );
                            }}
                            type="text"
                          ></Form.Control>

                          {suggestions && (
                            <ul id="suggestionList" className="suggestion-list">
                              {suggestions.map((suggestion) => (
                                <li
                                  className="btn"
                                  style={{
                                    borderRight: 0,
                                    borderRadius: 0,
                                    fontSize: "12px",
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                    color: "#423e3e",
                                    borderBottom: 0,
                                    borderTop: 0,
                                    zIndex: 999,
                                    border: "1px solid #eee",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    setInputText(suggestion.id);
                                    setSuggestions(undefined);
                                  }}
                                >
                                  {suggestion.id}
                                </li>
                              ))}
                            </ul>
                          )}
                        </InputGroup>
                        {error.klydo && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: 0,
                              marginLeft: "10px",
                            }}
                          >
                            {error.klydo}
                          </p>
                        )}
                        {klydoValid && !error.klydo && (
                          <Button
                            variant="outline-dark"
                            style={{
                              color: "black",
                              height: "fit-content",
                              marginTop: "10px",
                              marginLeft: "5%",
                              marginBottom: "10px",
                              fontSize: "87%",
                            }}
                            onClick={() => {
                              if (
                                selectedKlydoId &&
                                !draftKlydoIds.find(
                                  (k) => k === selectedKlydoId!,
                                )
                              ) {
                                draftKlydoIds.push(selectedKlydoId);
                                setDraftKlydoIds((prev) => prev!.slice());
                                if (
                                  removedKlydos.find(
                                    (kld) => kld === selectedKlydoId,
                                  )
                                ) {
                                  const tmp = removedKlydos.filter(
                                    (kld) => kld !== selectedKlydoId,
                                  );
                                  setRemovedKlydos(tmp);
                                }
                                setInputText("");
                                setSuggestions(undefined);
                              } else
                                setError({
                                  ...error,
                                  klydo: "klydo already listed",
                                });
                            }}
                          >
                            <IoMdAdd />
                          </Button>
                        )}
                      </div>
                    )}
                  </Form>
                </Col>
                <Form.Group
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "5px",
                  }}
                >
                  {error.server && (
                    <p
                      style={{
                        marginLeft: "102px",
                        marginBottom: "5px",
                        marginTop: "0.25rem",
                        fontSize: "0.875em",
                        color: "#dc3545",
                      }}
                    >
                      {error.server}
                    </p>
                  )}
                  <Button
                    style={{ color: "#4183a8" }}
                    disabled={
                      !name ||
                      !description ||
                      !imageUrl ||
                      !draftKlydoIds ||
                      !logoUrl ||
                      !pendulumImageUrl
                    }
                    onClick={() => {
                      setShowPubModal(true);
                    }}
                  >
                    Save
                  </Button>
                  {showPubModal && (
                    <Modal
                      onHide={() => setShowPubModal(false)}
                      className="modal-medium"
                      show={true}
                    >
                      <Modal.Body>
                        <Row style={{ margin: 25 }}>
                          <h4 style={{ textAlign: "center" }}>
                            You are about to save changes for {name}
                          </h4>
                        </Row>
                        <Row className="justify-content-between">
                          <Col sm="5">
                            <Button
                              className="w-100 h-100"
                              onClick={() => setShowPubModal(false)}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col sm="5">
                            <ButtonLoader
                              variant="outline-danger"
                              className="w-100 h-100"
                              title="Save"
                              onClick={() =>
                                savePack().then(() => {
                                  setShowPubModal(false);
                                  props.setShowModal(false);
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  )}
                </Form.Group>
              </Form>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: w + "px",
                  height: h + "px",
                  position: "relative",
                }}
              >
                <SpecialPackview
                  clockBackground={imageUrl}
                  pendulumBackground={pendulumImageUrl}
                  packName={name}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </>
  );
};

export default SpecialPackModal;
