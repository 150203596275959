import { Device, Tag } from "Types";
import firebaseService from "firebase_service/firebaseService";
import { useState, useRef, useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Klydo } from "../../api/klydo";

function TagList(props: {
  klydo?: Klydo;
  device?: Device;
  onSave: (list: string[]) => Promise<void>;
  tagList?: string[];
}) {
  const [show, setShow] = useState(false);
  const [allTags, setAllTags] = useState<Array<Tag>>();
  const [myTags, setMyTags] = useState(() => {
    if (props.klydo) return props.klydo.tags ?? ([] as Array<string>);
    return props.device?.premium ?? ([] as Array<string>);
  });
  const [filteredTags, setFilteredTags] = useState<Array<Tag>>();
  const [autoTags, setAutoTags] = useState<Array<Tag>>();
  const [toAddTag, setToAddTag] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!show) return;
    const l = (tags: Array<Tag>) => {
      setAllTags([...tags]);
      setFilteredTags([...tags]);
      setAutoTags([...tags]);
    };
    if (props.klydo) setMyTags(props.klydo.tags!);
    firebaseService.listen("tags2", l);
    return () => firebaseService.removeListener("tags2", l);
  }, [show]);
  useEffect(() => {
    setFilteredTags(
      allTags?.filter(
        (tag) =>
          !myTags?.includes(tag.name) &&
          (toAddTag === "" ||
            tag.name.toLowerCase().includes(tag.name.toLowerCase())),
      ),
    );
  }, [myTags, toAddTag, allTags]);
  const target = useRef(null);
  const tagStyel = (s: string, i: number) => (
    <div
      key={i}
      style={{
        cursor: "pointer",
        display: "flex",
        margin: 5,
        padding: "1px 6px",
        alignItems: "center",
        background: "gray",
        borderRadius: "50vw",
        height: "fit-content",
      }}
    >
      <p
        style={{
          margin: 0,
          padding: "2px",
          fontFamily: "monospace",
          fontSize: "11px",
          color: "white",
        }}
      >
        {s}
      </p>
    </div>
  );

  const addTag = (s: string) => {
    const list = s
      .split(",")
      ?.map((l) => l.trim())
      ?.filter(
        (f) =>
          f !== "" &&
          !myTags?.map((m) => m.toLowerCase())?.includes(f.toLowerCase()) &&
          !f.startsWith("$p"),
      );
    if (list.length === 0) return;
    setMyTags([...(myTags ?? []), ...list]);
  };

  const removeTag = (s: string) => {
    let n = myTags.findIndex((f) => f === s);
    if (n === -1) {
      console.log("NOT SPOS 2 HAPPEN");
      return;
    }
    const tmpMyTags = myTags?.filter((_, i) => i !== n);
    setMyTags([...tmpMyTags]);
  };
  const tagList = props?.klydo?.tags?.filter((tag) => !tag.startsWith("$p"));
  return (
    <>
      <div>
        <div
          style={{ display: "flex", flexWrap: "wrap", flex: "1 1 auto" }}
          ref={target}
          onClick={(e) => {
            e.stopPropagation();
            setShow(!show);
          }}
        >
          {(props.klydo
            ? tagList
            : props.device
              ? props.device.premium
              : props.tagList
          )?.map(tagStyel)}
          {((props.klydo && (tagList === undefined || tagList.length === 0)) ||
            (props.device &&
              (props.device.premium === undefined ||
                props.device.premium.length === 0))) && (
            <span
              className="nc-icon nc-simple-add"
              style={{
                cursor: "pointer",
                padding: 5,
                fontSize: 10,
                fontWeight: 700,
                background: "gray",
                color: "white",
                alignSelf: "center",
                borderRadius: "50%",
              }}
            ></span>
          )}
          {props.klydo?.tags?.length! > 0 && navigator?.clipboard && (
            <i
              title="Copy tags"
              className={`nc-icon ${copied ? "nc-check-2" : "nc-single-copy-04"}`}
              style={{
                position: "absolute",
                right: "1px",
                top: "25px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard
                  .writeText(
                    props
                      .klydo!.tags!.filter((tag) => !tag.startsWith("$p"))
                      .join(","),
                  )
                  .then(() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  })
                  .catch(console.log);
              }}
            ></i>
          )}
        </div>
        {show && (props.device || props.klydo) && (
          <Modal show={true} onHide={() => setShow(false)}>
            <div
              tabIndex={1}
              onKeyDown={(ev) => {
                ev.stopPropagation();
                if (ev.key !== "Enter" || toAddTag.startsWith("$p")) return;
                ev.preventDefault();
                if (toAddTag === "")
                  props.onSave(myTags).then(() => setShow(false));
                else addTag(toAddTag);
                setToAddTag("");
              }}
              style={{
                position: "absolute",
                top: 200,
                width: 400,
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "rgba(200, 200, 200, 0.95)",
                padding: "2px 10px",
                color: "black",
                borderRadius: 3,
                zIndex: 1100,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Form
                    onSubmit={(a) => {
                      if (props.klydo) {
                        a.preventDefault();
                        if (toAddTag === "" || toAddTag.startsWith("$p"))
                          return;
                        else addTag(toAddTag);
                        setToAddTag("");
                      }
                    }}
                  >
                    <Form.Control
                      onKeyDown={(ev) => {
                        if (toAddTag === "") return;
                        ev.stopPropagation();
                        if (ev.key === "Enter" && !toAddTag.startsWith("$p")) {
                          addTag(toAddTag);
                          setToAddTag("");
                        }
                      }}
                      autoFocus
                      placeholder={props.klydo ? "New tag" : "Search"}
                      value={toAddTag}
                      style={{
                        background: "transparent",
                        margin: 5,
                        padding: 0,
                        border: 0,
                        fontSize: "14px",
                        color: "gray",
                        maxWidth: "350px",
                        width:
                          toAddTag === ""
                            ? "90px"
                            : "calc(12px *" + toAddTag.length + ")",
                      }}
                      onChange={(e) => {
                        if (e.target.value.startsWith("$p")) {
                        }

                        const autoCTags = filteredTags?.filter((mt) =>
                          mt.name?.includes(e.target.value),
                        );
                        if (autoCTags?.length) {
                          setAutoTags(autoCTags);
                        } else setAutoTags([]);
                        setToAddTag(e.target.value);
                      }}
                    />
                  </Form>
                  {props.klydo &&
                    (!toAddTag.startsWith("$p") ? (
                      <span
                        title="add"
                        className="nc-icon nc-simple-add"
                        style={{
                          padding: 5,
                          fontSize: 10,
                          fontWeight: 700,
                          background: "gray",
                          color: "white",
                          alignSelf: "center",
                          borderRadius: "50%",
                        }}
                        onClick={(e) => {
                          if (toAddTag === "") return;
                          else addTag(toAddTag);
                          setToAddTag("");
                        }}
                      ></span>
                    ) : (
                      <p
                        style={{
                          color: "red",
                          fontSize: "10px",
                          alignSelf: "center",
                          margin: 0,
                          marginLeft: "10px",
                        }}
                      >
                        Cannot add $p... tags
                      </p>
                    ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <p style={{ fontSize: 11, padding: 5 }}>
                    {props.klydo ? "Klydo tags:" : "Premium packages:"}
                  </p>
                  {(props.klydo
                    ? myTags?.filter((tag) => !tag.startsWith("$p"))
                    : myTags
                  )?.map((t, i) => (
                    <div
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTag(t);
                      }}
                    >
                      {tagStyel(t, i)}
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  background: "rgba(20,0,150,1)",
                }}
              ></div>
              <div
                style={{
                  maxHeight: 350,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  overflow: "auto",
                }}
              >
                <p style={{ fontSize: 11, padding: 5 }}>All tags:</p>
                {props.device ? (
                  filteredTags ? (
                    (autoTags!.length ? autoTags! : filteredTags!)?.map(
                      (t, i) => (
                        <div
                          key={i}
                          onClick={(e) => {
                            addTag(t.name);
                          }}
                        >
                          {tagStyel(t.name, i)}
                        </div>
                      ),
                    )
                  ) : (
                    <Spinner />
                  )
                ) : filteredTags ? (
                  (autoTags!.length ? autoTags! : filteredTags!)
                    ?.filter((tag) => !tag.name.startsWith("$p"))
                    ?.map((t, i) => (
                      <div
                        key={i}
                        onClick={(e) => {
                          addTag(t.name);
                        }}
                      >
                        {tagStyel(t.name, i)}
                      </div>
                    ))
                ) : (
                  <Spinner />
                )}
              </div>
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  background: "rgba(20,150,0,1)",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    margin: 5,
                    justifySelf: "right",
                    background: "#FF1020",
                    border: 0,
                    borderRadius: "50%",
                    padding: "3px 5px 0px 5px",
                    height: "fit-content",
                  }}
                  onClick={(e) => {
                    setMyTags(
                      props.klydo
                        ? (props.klydo.tags ?? ([] as Array<string>))
                        : (props.device?.premium ?? ([] as Array<string>)),
                    );
                    setShow(false);
                  }}
                >
                  <span
                    className="nc-icon nc-simple-remove"
                    style={{ color: "#FFFFFF", fontWeight: 900 }}
                  ></span>
                </Button>
                <Button
                  style={{
                    margin: 5,
                    justifySelf: "right",
                    background: "#11FF11",
                    border: 0,
                    borderRadius: "50%",
                    padding: "3px 5px 0px 5px",
                    height: "fit-content",
                  }}
                  onKeyDown={(ev) => {
                    ev.stopPropagation();
                    if (ev.key === "Enter")
                      props.onSave(myTags).then(() => setShow(false));
                  }}
                  onClick={(e) => {
                    props.onSave(myTags).then(() => setShow(false));
                  }}
                >
                  <span
                    className="nc-icon nc-check-2"
                    style={{ color: "#FFFFFF", fontWeight: 900 }}
                  ></span>
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default TagList;
