import { KlydoTimes } from "../Types";
import { AddFeaturePayload } from "../views/Scheduled/useAddFeatureToKlydoMutation";
import { baseClient } from "./baseRequest";

export enum KlydoType {
  Draft = "DraftKlydo",
  Approved = "ApprovedKlydo",
}

export enum KlydoReviewStates {
  Approved = "approved",
  Pending = "pending",
  Rejected = "rejected",
  Draft = "draft",
}

export type KlydoReviewStatus = {
  state: KlydoReviewStates;
  message: string;
};

export type Theme = {
  backgroundColor: string;
  handsColor: string;
  pendulumColor: string;
  pendulumRodColor: string;
  dialsColor?: string;
};

export type FeartureTimes = {
  start: string;
  end: string;
};

export type Klydo = {
  name: string;
  tags: string[];
  loopUrl: string;
  convertedLoopUrl?: string;
  theme: Theme;
  times: KlydoTimes[]; // Not used currently. was in firestore
  id: string;
  creator: string;
  dials: boolean;
  klydoSecureUrl?: string;
  createdAt: Date;
  updatedAt: Date;
  featured: boolean;
  featuredTimes: FeartureTimes;
  authorName: string;
  unlisted: boolean;
  inTasks: number;
  pool: boolean;
  type: KlydoType;
  reviewStatus: KlydoReviewStatus;
  public: boolean;
  stats: {
    favorites: number;
    viewed: number;
    time: number;
  };
};

export type AnalyticsRow = {
  clock: string;
  favorite: boolean;
  clock_time: number;
  display_time: number;
  auto_switch: boolean;
  collection_type: number;
};

export type KlydoSearchFilter = {
  pool?: boolean;
  unlisted?: boolean;
  excludeIds?: string[];
  notScheduled?: boolean;
  notPool?: boolean;
  inReview?: boolean;
};

export type KlydoApiClient = {
  updateKlydo: (id: string, klydo: Partial<Klydo>) => Promise<void>;
  deleteKlydo: (id: string) => Promise<void>;
  reviewTextSearch: (
    query: string,
    limit: number,
    skip: number,
    parseData?: boolean,
    filters?: {
      pool?: boolean;
      unlisted?: boolean;
      inTasks?: boolean;
    },
    sort?: { [key: string]: number },
  ) => Promise<Klydo[]>;

  similaritySearch: (
    query: string,
    filters: {
      pool?: boolean;
      unlisted?: boolean;
      inTasks?: boolean;
      excludeIds?: string[];
    },
  ) => Promise<Klydo[]>;

  approvedTextSearch: (
    query: string,
    limit: number,
    skip: number,
    parseData?: boolean,
    filters?: {
      pool?: boolean;
      unlisted?: boolean;
      inTasks?: boolean;
    },
    sort?: { [key: string]: number },
  ) => Promise<Klydo[]>;

  getKlydosAnalytics: (
    klydoId: string[],
    deviceId: string,
  ) => Promise<AnalyticsRow[]>;
  getKlydoById: (klydoId: string) => Promise<Klydo>;
  approveKlydo: (klydoId: string) => Promise<void>;
  updateDraftKlydo: (klydoId: string, klydo: Partial<Klydo>) => Promise<void>;
  addFeaturedToKlydos: ({
    klydoIds,
    featured,
    featuredTimes,
  }: AddFeaturePayload) => Promise<void>;
  getTasksKlydos: () => Promise<Klydo[]>;
};

const klydoApiClient: KlydoApiClient = {
  updateKlydo: async (id, klydo) => {
    return baseClient.put({
      url: `/admin/klydos/${id}`,
      body: klydo,
    });
  },
  similaritySearch: async (query, filters) => {
    return baseClient.post<Klydo[]>({
      url: `/admin/klydos/.search/similarity/approved`,
      body: {
        query: query,
        filters: filters,
      },
    });
  },
  deleteKlydo: async (id) => {
    return baseClient.delete({
      url: `/admin/klydos/${id}`,
    });
  },
  approvedTextSearch: async (
    query,
    limit,
    skip,
    includeMeteData,
    filters,
    sort,
  ) => {
    return baseClient.post<Klydo[]>({
      url: `/admin/klydos/.search/text/approved?query=${encodeURIComponent(query)}`,
      body: {
        limit: limit,
        skip: skip,
        includeMeteData: includeMeteData,
        filters: filters,
        sort: sort,
      },
    });
  },
  reviewTextSearch: async (
    query,
    limit,
    skip,
    includeMeteData,
    filters,
    sort,
  ) => {
    return baseClient.post<Klydo[]>({
      url: `/admin/klydos/.search/review?query=${encodeURIComponent(query)}`,
      body: {
        limit: limit,
        skip: skip,
        filters: filters,
        sort: sort,
      },
    });
  },

  getKlydosAnalytics: async (klydoId: string[], deviceId: string) => {
    return baseClient.post<AnalyticsRow[]>({
      url: `/klydos/get-klydos-analytics`,
      body: {
        klydoId,
        deviceId,
      },
    });
  },

  getKlydoById: async (klydoId: string) => {
    return baseClient.get<Klydo>({
      url: `/admin/klydos/${klydoId}`,
    });
  },

  approveKlydo: async (klydoId: string) => {
    return baseClient.put({
      url: `/admin/klydos/approve/${klydoId}`,
    });
  },
  updateDraftKlydo: async (klydoId: string, klydo: Partial<Klydo>) => {
    return baseClient.put({
      url: `/admin/klydos/update-draft/${klydoId}`,
      body: { klydo },
    });
  },
  addFeaturedToKlydos: async ({ klydoIds, featured, featuredTimes }) => {
    return baseClient.put({
      url: `/admin/klydos/add-featured`,
      body: {
        klydoIds,
        featured,
        featuredTimes,
      },
    });
  },
  getTasksKlydos: async () => {
    return baseClient.get<Klydo[]>({
      url: `/admin/klydos/tasks-klydos`,
    });
  },
};
export default klydoApiClient;
