import { useState, useEffect } from "react";
import { Card, Form, Modal, Button, Row, Col, Spinner } from "react-bootstrap";
import firebaseService from "../firebase_service/firebaseService";
import { ScheduledTask } from "../Types";
import { isVideo, myColor, resizeCloudinary } from "../utils/util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import placeholderImage from "assets/img/placeholderImage.png";
import { Klydo } from "../api/klydo";
import useAddFeatureToKlydoMutation from "./Scheduled/useAddFeatureToKlydoMutation";


export const descriptionMaxLength = 420;
function ScheduledTasksAddModal({
  open,
  handleClose,
  klydos,
}: {
  open: boolean;
  handleClose: (b: boolean) => void;
  klydos: Klydo[];
}) {
  const { mutateAsync: addFeatureToKlydos } = useAddFeatureToKlydoMutation();

  const [scheduledPool, setScheduledPool] = useState<Array<ScheduledTask>>();
  const [date, setDate] = useState<Date>();
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(true);
  const [error, setError] = useState("");
  const [existedTask, setExistedTask] = useState<ScheduledTask>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [featured, setFeatured] = useState(false);
  useEffect(() => {
    firebaseService
      .getData<ScheduledTask>("scheduledpool")
      .then(setScheduledPool);
  }, []);
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if ((!startDate && endDate) || (startDate && !endDate)) {
      window.alert("Please enter both start and end date");
      return;
    }
    let filteredKlydos = klydos;
    if (existedTask) {
      filteredKlydos = klydos.filter(
        (k) =>
          !(existedTask.add as Klydo[]).includes(k) &&
          !(existedTask.remove as Klydo[]).includes(k),
      );
    }
    const today = new Date();
    today.setHours(today.getHours() - 1);
    if (!date) {
      setError("Please enter a date");
      event.stopPropagation();
    } else if (date < today) {
      setError("Date must be in the future");
      event.stopPropagation();
    } else if (filteredKlydos.length === 0) {
      setError("no valid klydos to save to task");
      event.stopPropagation();
    } else {
      setError("");
      setLoading(true);
      const prmss = [];
      prmss.push(firebaseService.addScheduledTask(date, add, filteredKlydos));
      if (add && featured) {
        prmss.push(
          await addFeatureToKlydos({
            klydoIds: klydos.map((k) => k.id),
            featured,
            featuredTimes:
              startDate && endDate
                ? { start: startDate.getTime(), end: endDate.getTime() }
                : {
                    start: date.getTime(),
                    end: date.getTime() + 1000 * 60 * 60 * 24,
                  },
          }),
        );
      }
      Promise.all(prmss)
        .then(() => {
          setLoading(false);
          handleClose(false);
        })
        .catch((error) => {
          console.log(error);
          setError("failed to add");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      setDate(undefined);
      setAdd(true);
      setError("");
      setExistedTask(undefined);
    }
  }, [open]);
  useEffect(() => {
    if (
      existedTask &&
      klydos.filter(
        (k) =>
          !(existedTask.add as Klydo[]).includes(k) &&
          !(existedTask.remove as Klydo[]).includes(k),
      ).length === 0
    )
      setError("no klydos to save");
  }, [existedTask]);

  return (
    <Modal show={open} onHide={() => handleClose(false)}>
      <Modal.Header style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}>
        <h4>Add klydos to a scheduled task</h4>
        <i
          className="nc-icon nc-simple-remove"
          style={{ marginTop: 15 }}
          onClick={() => handleClose(false)}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Card className="card-stats shadow" style={{ background: myColor }}>
          <Card.Body>
            <Row>
              <Col
                style={{ overflowY: "scroll", maxHeight: "400px" }}
                className="pr-1"
                md="12"
              >
                {klydos.map((item, index) => (
                  <Row style={{ alignItems: "center" }} key={index}>
                    <Col sm={5}>{item.id}</Col>
                    <Col sm={2}>
                      <div
                        style={{
                          borderRadius: "50%",
                          width: 48,
                          height: 48,
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            top: 0 + "%",
                            left: 0 + "%",
                            width: `${100}%`,
                            aspectRatio: "1/1",
                          }}
                        >
                          {isVideo(item.loopUrl) === "image" ? (
                            <img
                              loading="lazy"
                              alt="gif"
                              src={resizeCloudinary(item.loopUrl, 48)}
                              style={{
                                width: "auto",
                                height: "100%",
                                objectFit: "cover",
                                background: `url(${placeholderImage}) no-repeat scroll 0 0`,
                              }}
                            />
                          ) : (
                            <video
                              poster={placeholderImage}
                              muted={true}
                              src={resizeCloudinary(item.loopUrl, 48)}
                              loop
                              autoPlay
                              style={{
                                width: "auto",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col sm={5}>
                      {existedTask &&
                        (existedTask.add.find(
                          (klydo) => klydo.id === item.id,
                        ) ||
                          existedTask.remove.find(
                            (klydo) => klydo.id === item.id,
                          )) && (
                          <p style={{ color: "red", fontSize: "smaller" }}>
                            Klydo already exists (will be ignored)
                          </p>
                        )}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {!loading && (
          <Form noValidate onSubmit={handleSubmit} id="newUserForm">
            <Row className="justify-content-between">
              <Col sm="6">
                <Form.Group controlId="formAddDate" style={{ display: "flex" }}>
                  <Form.Label style={{ padding: 5 }}>
                    <b>Date</b>
                  </Form.Label>
                  <Col>
                    <>
                      <DatePicker
                        selected={date}
                        onChange={(chosenDate) => {
                          try {
                            setDate(chosenDate!);
                            const dt = new Date(chosenDate!);
                            setError("");
                            setExistedTask(
                              scheduledPool?.find(
                                (task) =>
                                  task.date.getDate() === dt.getDate() &&
                                  task.date.getMonth() === dt.getMonth() &&
                                  task.date.getFullYear() === dt.getFullYear(),
                              ),
                            );
                          } catch (e) {}
                        }}
                        showIcon
                        placeholderText="Enter date"
                        dateFormat="yyyy-MM-dd"
                        className={`form-control ${error ? "is-invalid" : ""}`}
                        highlightDates={scheduledPool
                          ?.filter(
                            (task) =>
                              task.add.length > 0 || task.remove.length > 0,
                          )
                          .map((task) => task.date)}
                        renderDayContents={(day, date) => {
                          const foundTask = scheduledPool?.find(
                            (task) =>
                              task.date.toDateString() === date?.toDateString(),
                          );
                          return (
                            <div
                              title={foundTask?.tag}
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <span>{date && date.getDate()}</span>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {foundTask && foundTask.add.length > 0 && (
                                  <p style={{ fontSize: "xx-small" }}>
                                    +{foundTask.add.length}
                                  </p>
                                )}
                                {foundTask && foundTask.remove.length > 0 && (
                                  <p style={{ fontSize: "xx-small" }}>
                                    -{foundTask?.remove.length}
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        }}
                        required
                      />
                      <p>{error}</p>
                    </>
                  </Col>
                </Form.Group>
              </Col>

              <Col sm="5">
                <Form.Group
                  controlId="formAddOrRemove"
                  style={{ display: "flex", height: "40px" }}
                >
                  <Form.Label style={{ padding: 5 }}>
                    <b>Add Or Remove</b>
                  </Form.Label>
                  <Form.Select
                    value={String(add)}
                    onChange={(e) => {
                      setAdd(e.target.value === "true");
                    }}
                  >
                    <option value={"true"}>Add</option>
                    <option value={"false"}>Remove</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {add && (
              <div style={{ marginTop: 15 }}>
                <Col sm="8">
                  <Form.Group
                    controlId="featured"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Check
                      checked={featured}
                      onChange={(e) => {
                        setFeatured(!featured);
                      }}
                    ></Form.Check>
                    <Form.Label style={{ padding: 5 }}>
                      <b>Featured</b>
                    </Form.Label>
                  </Form.Group>
                </Col>
                {featured && (
                  <>
                    <Row className="justify-content-between">
                      <Col sm="8">
                        <Form.Group
                          controlId="formAddDate"
                          style={{ display: "flex" }}
                        >
                          <Form.Label style={{ padding: 5 }}>
                            <b>From:</b>
                          </Form.Label>
                          <Col>
                            <>
                              <DatePicker
                                selected={startDate}
                                onChange={(chosenDate) => {
                                  try {
                                    setStartDate(chosenDate);
                                  } catch (e) {}
                                }}
                                showIcon
                                showTimeSelect
                                placeholderText="Enter date"
                                dateFormat="yyyy-MM-dd HH:mm"
                                className={`form-control`}
                                required
                              />
                            </>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="8">
                        <Form.Group
                          controlId="formAddDate"
                          style={{ display: "flex" }}
                        >
                          <Form.Label style={{ padding: 5 }}>
                            <b>Until:</b>
                          </Form.Label>
                          <Col>
                            <>
                              <DatePicker
                                selected={endDate}
                                onChange={(chosenDate) => {
                                  try {
                                    setEndDate(chosenDate);
                                  } catch (e) {}
                                }}
                                showIcon
                                showTimeSelect
                                placeholderText="Enter date"
                                dateFormat="yyyy-MM-dd HH:mm"
                                className={`form-control`}
                                required
                              />
                            </>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            )}
            <Form.Group style={{ display: "flex", justifyContent: "right" }}>
              <Button title="add" type="submit">
                <>
                  {loading ? <Spinner animation="border" size="sm" /> : <></>}
                  add
                </>
              </Button>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ScheduledTasksAddModal;
