/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/Dashboard";
import Devices from "./views/Device/components/Devices";
import Versions from "./views/Versions";
import Logs from "./views/Logs";
import Klydos from "./views/Klydos/components/Klydos";
import Users from "./views/Klydos/Users/components/Users";
// import Reviews from "./views/Reviews";
import Tags from "./views/TagsView";
import ScheduledTasks from "./views/ScheduledTasks";
import KlydoMap from "./views/KlydoMap";
import Events from "./views/Events";

import FeatureFlags from "./views/FeatureFlags";
import Gifts from "./views/Gifts/components/Gifts";
import SpecialPacks from "./views/Special/SpecialPacks";
import ApprovedPacks from "./views/Packs/components/ApprovedPacks";
import ReviewsSection from "./views/Reviews/components/Reviews";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    element: Dashboard,
    layout: "/admin",
  },
  {
    path: "/feature-flags",
    name: "Feature Flags",
    icon: "nc-icon nc-tag-content",
    element: FeatureFlags,
    layout: "/admin",
  },
  {
    path: "/greets",
    name: "Greets",
    icon: "nc-icon nc-bag",
    element: Gifts,
    layout: "/admin",
  },
  {
    path: "/devices",
    name: "Devices",
    icon: "nc-icon nc-tablet-2",
    element: Devices,
    layout: "/admin",
  },
  {
    path: "/versions",
    name: "Versions",
    icon: "nc-icon nc-light-3",
    element: Versions,
    layout: "/admin",
  },
  {
    path: "/crashes",
    name: "Crashes",
    icon: "nc-icon nc-paper-2",
    element: Logs,
    layout: "/admin",
  },
  {
    path: "/klydos",
    name: "Klydos",
    icon: "nc-icon nc-palette",
    element: Klydos,
    layout: "/admin",
  },
  // {
  //   path: "/klydo-reviews",
  //   name: "Klydo Reviews",
  //   icon: "nc-icon nc-ruler-pencil",
  //   element: Reviews,
  //   layout: "/admin",
  // },
  {
    path: "/packs",
    name: "Packs",
    icon: "nc-icon nc-ruler-pencil",
    element: ApprovedPacks,
    layout: "/admin",
  },
  {
    path: "/reviews",
    name: "Reviews",
    icon: "nc-icon nc-ruler-pencil",
    element: ReviewsSection,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-single-02",
    element: Users,
    layout: "/admin",
  },
  {
    path: "/tags",
    name: "Tags",
    icon: "nc-icon nc-tag-content",
    element: Tags,
    layout: "/admin",
  },
  {
    path: "/scheduledPool",
    name: "Scheduled Pool",
    icon: "nc-icon nc-puzzle-10",
    element: ScheduledTasks,
    layout: "/admin",
  },
  {
    path: "/events",
    name: "Events",
    icon: "nc-icon nc-single-copy-04",
    element: Events,
    layout: "/admin",
  },
  {
    path: "/specialPacks",
    name: "Special Packs",
    element: SpecialPacks,
    icon: "nc-icon nc-backpack",
    layout: "/admin",
  },
  {
    path: "/map",
    name: "Device Map",
    element: KlydoMap,
    icon: "nc-icon nc-map-big",
    layout: "/admin",
  },
];

export default dashboardRoutes;
