import { useQuery } from "@tanstack/react-query";
import adminApiClient from "../../api/admin";

export const useStudioUsersCount = () => {
  return useQuery<number>({
    queryKey: ["studioUsersCount"],
    queryFn: async () => {
      return adminApiClient.getNumberOfStudioUsers();
    },
  });
};
