import { useState } from "react";
import DSModal from "../../../system-design/DSModal";
import KlydoPreview from "../../../../components/klydoPreview/KlydoPreview";
import KlydoReviewFormModal from "../../../../components/forms/ReviewFormModal";
import { Klydo, KlydoReviewStates } from "../../../../api/klydo";
import useUpdatDrafteKlydoMutation from "../hooks/useUpdateDraftKlydoMutation";
import DSGrid from "../../../system-design/DSGrid";
import DSTypography from "../../../system-design/DSTypography";
import DSBox from "../../../system-design/DSBox";
import DSPaper from "../../../system-design/DSPaper";
import DSPrimaryButton from "../../../system-design/DSPrimaryButtom";
import { KLYDO_BLACK } from "../../../../consts/colors";
import useApproveKlydoMutation from "../hooks/useApproveKlydoMutation";
import DSErrorButton from "../../../system-design/DSErrorButton";
import { useQueryClient } from "@tanstack/react-query";

export type KlydoReviewModalProps<T> = {
  open: boolean;
  onClose: () => void;
  row: T;
};

const KlydoReviewModal = <T,>({
  open,
  onClose,
  row,
}: KlydoReviewModalProps<T>): JSX.Element => {
  const draftKlydo = row as Klydo;
  const queryClient = useQueryClient();
  const [reviewOpen, setReviewOpen] = useState(false);
  const { mutate: approveKlydoMutation } = useApproveKlydoMutation();
  const { mutate: updateKlydoMutation } = useUpdatDrafteKlydoMutation();
  const onSubmit = () => {
    queryClient.invalidateQueries({ queryKey: ["klydos"] });
    onClose();
  };
  const handleSubmitReview = (message: string) => {
    updateKlydoMutation(
      {
        klydoId: draftKlydo.id,
        klydo: { reviewStatus: { state: KlydoReviewStates.Rejected, message } },
      },
      {
        onSuccess: () => {
          onSubmit();
        },
      },
    );
  };

  const handleApproveKlydo = () => {
    approveKlydoMutation(
      { klydoId: draftKlydo.id },
      {
        onSuccess: () => {
          onSubmit();
        },
      },
    );
  };

  const handleReviewOpen = () => setReviewOpen(true);
  const handleReviewClose = () => setReviewOpen(false);

  return (
    <DSModal
      open={open}
      onClose={onClose}
      aria-labelledby="klydo-modal-title"
      aria-describedby="klydo-modal-description"
    >
      <DSBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          overflowY: "auto",
          maxHeight: "90%",
          p: 4,
        }}
      >
        <DSTypography id="klydo-modal-title" variant="h4" gutterBottom>
          Draft Klydo Details
        </DSTypography>
        <DSGrid container>
          <DSGrid item xs={12} md={6}>
            <DSBox
              sx={{
                width: "345px",
                height: "540px",
                position: "relative",
                boxSizing: "border-box",
                border: "1px solid white",
                backgroundColor: KLYDO_BLACK,
                borderRadius: "12px",
              }}
            >
              <KlydoPreview klydo={draftKlydo} />
            </DSBox>
          </DSGrid>

          <DSGrid item xs={12} md={6}>
            <DSGrid container spacing={2}>
              <DSGrid item xs={12}>
                <DSPaper variant="outlined" sx={{ p: 2 }}>
                  <DSTypography variant="h6">Basic Information</DSTypography>
                  <DSTypography>Name: {draftKlydo?.name}</DSTypography>
                  <DSTypography>Creator ID: {draftKlydo?.creator}</DSTypography>
                  <DSTypography>
                    Creator Name: {draftKlydo?.authorName}
                  </DSTypography>
                  <DSTypography>
                    Created At: {draftKlydo?.createdAt?.toString()}
                  </DSTypography>
                  <DSTypography>
                    Updated At: {draftKlydo?.updatedAt?.toString()}
                  </DSTypography>
                </DSPaper>
              </DSGrid>

              <DSGrid item xs={12}>
                <DSPaper variant="outlined" sx={{ p: 2 }}>
                  <DSTypography variant="h6">Theme</DSTypography>
                  <DSTypography>
                    Background Color: {draftKlydo?.theme?.backgroundColor}
                  </DSTypography>
                  <DSTypography>
                    Hands Color: {draftKlydo?.theme?.handsColor}
                  </DSTypography>
                  <DSTypography>
                    Pendulum Color: {draftKlydo?.theme?.pendulumColor}
                  </DSTypography>
                  <DSTypography>
                    Pendulum Rod Color: {draftKlydo?.theme?.pendulumRodColor}
                  </DSTypography>
                  {draftKlydo?.theme?.dialsColor && (
                    <DSTypography>
                      Dials Color: {draftKlydo?.theme?.dialsColor}
                    </DSTypography>
                  )}
                </DSPaper>
              </DSGrid>

              <DSGrid item xs={12}>
                <DSPaper variant="outlined" sx={{ p: 2 }}>
                  <DSTypography variant="h6">Review Status</DSTypography>
                  <DSTypography>
                    State: {draftKlydo?.reviewStatus?.state}
                  </DSTypography>
                  <DSTypography>
                    Message: {draftKlydo?.reviewStatus?.message}
                  </DSTypography>
                </DSPaper>
              </DSGrid>
            </DSGrid>
          </DSGrid>
        </DSGrid>

        <DSBox
          sx={{ display: "flex", justifyContent: "flex-end", mt: 4, gap: 2 }}
        >
          <DSErrorButton onClick={handleReviewOpen}>Reject</DSErrorButton>
          <DSPrimaryButton onClick={handleApproveKlydo}>
            Approve
          </DSPrimaryButton>
        </DSBox>

        <KlydoReviewFormModal
          open={reviewOpen}
          handleClose={handleReviewClose}
          review={draftKlydo?.reviewStatus}
          handleSubmitReview={handleSubmitReview}
        />
      </DSBox>
    </DSModal>
  );
};

export default KlydoReviewModal;
