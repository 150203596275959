import { useCallback, useEffect, useState } from "react";
import useUsersQuery from "./useUsersQuery";
import { useTheme } from "@mui/material";
import { SearchUserOutput } from "../../../api/admin";
import useUserQuery from "./useUserQuery";

const useUserDropdown = ({
  onOptionSelect,
  id,
}: {
  onOptionSelect: (value: string) => void;
  id?: string;
}) => {
  const { spacing } = useTheme();
  const [searchText, setSearchText] = useState("");
  const { data: users, isLoading } = useUsersQuery(searchText);
  const { data: user } = useUserQuery(id);
  const [dropDownValue, setDropDownValue] = useState<SearchUserOutput | null>(
    null,
  );

  useEffect(() => {
    if (user) {
      setDropDownValue({ name: user.name, id: user.id });
    }
  }, [user]);

  const handleInputChange = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      setSearchText(value);
    },
    [],
  );

  const handleOptionSelect = useCallback(
    async (event: React.SyntheticEvent, value: SearchUserOutput | null) => {
      if (value) {
        setSearchText("");
        setDropDownValue(value);
        onOptionSelect(value.id);
      }
    },
    [],
  );

  const options = users?.pages.flatMap((page) => page) || [];

  return {
    options,
    handleInputChange,
    handleOptionSelect,
    isLoading,
    spacing,
    searchText,
    dropDownValue,
  };
};

export default useUserDropdown;
