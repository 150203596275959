import { useQuery } from "@tanstack/react-query";
import adminApiClient from "../../../api/admin";

const useDeviceQuery = (deviceId: string) => {
  return useQuery({
    queryKey: ["machine", deviceId],
    queryFn: async () => {
      return adminApiClient.getClock(deviceId);
    },
    refetchOnWindowFocus: false,
  });
};

export default useDeviceQuery;
