import { useCallback, useState } from "react";
import { debounce } from "@mui/material";
import useKlydosReviewQuery from "../../../../components/hooks/useKlydosReviewQuery";
import { Column } from "../../../system-design/Table/DSBaseTable/components/DSBaseTable";
import { Klydo } from "../../../../api/klydo";

const PAGE_SIZE = 20;

const useKlydoReviewsContainer = () => {
  const [textSearch, setTextSearch] = useState<string>("");
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDirection, setSortDirection] = useState<number>(-1);

  const handleFreeSearch = useCallback(
    debounce((s) => setTextSearch(s), 500),
    [],
  );

  const {
    data: klydos,
    fetchNextPage,
    isLoading,
  } = useKlydosReviewQuery(
    textSearch,
    PAGE_SIZE,
    {},
    false,
    sortField,
    sortDirection,
  );

  const handleSortClick = useCallback(
    (field: string) => {
      if (field === "favorites") {
        if (sortField === "stats.favorites") {
          setSortDirection(-sortDirection);
        } else {
          setSortField("stats.favorites");
        }
      } else {
        if (field === sortField) {
          setSortDirection(-sortDirection);
        }
        setSortField(field);
      }
    },
    [sortDirection, sortField],
  );

  const columns: Column<Klydo>[] = [
    { field: "name", label: "Name" },
    { field: "loopUrl", label: "LoopUrl" },
    { field: "authorName", label: "Author" },
    { field: "reviewStatus", label: "Status" },
    { field: "createdAt", label: "Created At" },
  ];
  return {
    klydos,
    isLoading,
    fetchNextPage,
    handleFreeSearch,
    handleSortClick,
    columns,
  };
};
export default useKlydoReviewsContainer;
