import { useQuery } from "@tanstack/react-query";
import adminApiClient from "../../api/admin";

export const useArtistsCount = () => {
  return useQuery<number>({
    queryKey: ["artistsCount"],
    queryFn: async () => {
      return adminApiClient.getNumberOfArtists();
    },
  });
};
