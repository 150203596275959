import { useQuery, useQueryClient } from "@tanstack/react-query";
import packApiClient, { Pack } from "../../../api/pack";

const usePack = (packId?: string) => {
  const queryClient = useQueryClient();
  return useQuery<Pack>({
    queryKey: ["pack", packId],
    queryFn: async () => {
      const pack = await packApiClient.getPackById(packId!);
      if (pack.klydos) {
        for (const klydo of pack.klydos) {
          queryClient.setQueryData(["klydo", klydo.id], klydo);
        }
      }
      return pack;
    },
    enabled: !!packId,
  });
};

export default usePack;
