import { useMutation, useQueryClient } from "@tanstack/react-query";
import giftCategoriesClient, { Gift } from "../../../../api/giftCategories";

const useOrderGiftMutation = (orderId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (gift: Partial<Gift>) =>
      giftCategoriesClient.editOrderGift({ updatedOrderId: orderId, gift }),
    onSuccess: (gift: Gift) => {
      queryClient.setQueryData(["giftOrder", orderId], gift);
      queryClient.invalidateQueries({
        queryKey: ["giftOrders"],
      });
    },
  });
};

export default useOrderGiftMutation;
