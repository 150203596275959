import { useQueryClient } from "@tanstack/react-query";
import { ClientDetails, Device } from "../../../Types";
import useKlydoMutation, {
  UseKlydoMutationOptions,
} from "../../../components/hooks/useKlydoMutation";
import firebaseService from "../../../firebase_service/firebaseService";

const useUpdateClientDetailsMutation = (
  options?: UseKlydoMutationOptions<
    void,
    { device: Device; clientDetails: ClientDetails },
    void
  >,
) => {
  const queryClient = useQueryClient();
  return useKlydoMutation({
    mutationKey: ["update-client-details"],
    mutationFn: async ({ device, clientDetails }) => {
      return firebaseService.registerDevice(device, clientDetails);
    },
    onSettled: (data, error, { device }) =>
      queryClient.refetchQueries({
        queryKey: ["machine", device.id],
      }),
    ...options,
  });
};

export default useUpdateClientDetailsMutation;
