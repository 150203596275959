import { useCallback, useState } from "react";
import MsTable from "../../../../components/Widgets/Table";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { colType } from "../../../../Types";
import FloatAddButton from "../../../../components/Widgets/FloatAddButton";
import { Gift } from "../../../../api/giftCategories";
import useGiftOrdersQuery from "../hooks/useGiftOrdersQuery";
import GiftOrderModal from "./GiftOrderModal";
import { FaTrash } from "react-icons/fa";
import useDeleteOrderGiftMutation from "../hooks/useDeleteOrderGiftMutation";
import DSCircularProgress from "../../../system-design/DSCircularProgress";
import { useTheme } from "@mui/material";
import DSBox from "../../../system-design/DSBox";
import { debounce } from "lodash";

export type GiftRow = {
  orderId: string;
  date: string;
  numOfClocks: number;
  name: string;
  image: JSX.Element;
  delete: JSX.Element;
};
function GiftOrders() {
  const [query, setQuery] = useState<string>();
  const {
    data: giftOrders,
    fetchNextPage,
    refetch,
    isLoading,
  } = useGiftOrdersQuery(query);
  const handleFreeSearch = useCallback(
    debounce((s) => setQuery(s), 500),
    [],
  );
  const theme = useTheme();
  const { mutate: deleteOrderGiftMutation } = useDeleteOrderGiftMutation();
  const [addNewOrderGift, setAddNewOrderGift] = useState<boolean>(false);
  const [giftToDelete, setGiftToDelete] = useState("");

  const tableColumns: Array<colType> = [
    { label: "order id", field: "orderId", sort: false },
    { label: "name", field: "name", sort: false },
    { label: "Author Image", field: "image", sort: false },
    { label: "Date", field: "date", sort: false },
    { label: "Number of clocks", field: "numOfClocks", sort: false },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];

  const tableRows = (gift: Gift): GiftRow => {
    return {
      name: gift.senderName,
      numOfClocks: gift.clocksIds?.length || 0,
      orderId: gift.orderId,
      date: gift.createdAt.toString(),
      image: (
        <img width={100} src={gift.senderImageUrl} alt={gift.senderName} />
      ),
      delete: (
        <FaTrash
          style={{ margin: "8px" }}
          className="nc-icon nc-simple-remove"
          onClick={(e) => {
            e.stopPropagation();
            setGiftToDelete(gift.orderId);
          }}
        />
      ),
    };
  };

  const DeleteGiftModal = () => {
    return (
      <>
        {giftToDelete && (
          <Modal onHide={() => setGiftToDelete("")} show>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              "Are you sure you want to delete this gift ?"
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setGiftToDelete("")}>
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  deleteOrderGiftMutation(giftToDelete);
                  setGiftToDelete("");
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  };

  const AddGiftOrderModal = () => {
    const [orderId, setOrderId] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const addNewCategory = async () => {
      if (!orderId) {
        setShowWarning(true);
        return;
      }
      setShowWarning(false);
      window.open(
        `https://editor.klydoclock.com/gift?orderId=${orderId}`,
        "_blank",
        "noreferrer",
      );
    };
    return (
      <>
        <Modal.Header>
          <Modal.Title>Add Gift to order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showWarning && (
            <Alert variant="danger">Enter Order Id to add gift to order</Alert>
          )}
          <Form>
            <Form.Group controlId="formGiftCategoryName">
              <Form.Label>Order Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Order Id"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAddNewOrderGift(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              addNewCategory();
              setAddNewOrderGift(false);
            }}
          >
            Add Gift to Order
          </Button>
        </Modal.Footer>
      </>
    );
  };

  return (
    <>
      <DSBox py={1} />
      <Form.Control
        onChange={(e) => {
          handleFreeSearch(e.target.value);
        }}
        type="search"
      />
      {giftOrders && !isLoading ? (
        <>
          <MsTable
            id="Greet Orders"
            data={giftOrders}
            cols={tableColumns}
            rowBuild={tableRows}
            onScrollDown={fetchNextPage}
            title="Greet Orders"
            shouldHideSearchBar
            initSort=""
            cancelSort={true}
            modal={GiftOrderModal}
          />
          <FloatAddButton onClick={() => setAddNewOrderGift(true)} />
        </>
      ) : (
        <DSBox
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: theme.zIndex.modal,
          }}
        >
          <DSCircularProgress />
        </DSBox>
      )}
      <DeleteGiftModal />
      {addNewOrderGift && (
        <Modal
          show={true}
          size="xl"
          onHide={() => {
            setAddNewOrderGift(false);
            refetch();
          }}
        >
          <AddGiftOrderModal />
        </Modal>
      )}
    </>
  );
}

export default GiftOrders;
