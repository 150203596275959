import { useCallback, useState } from "react";
import { ApprovedPack } from "../../../Types";
import { debounce } from "lodash";
import { Column } from "../../system-design/Table/DSBaseTable/components/DSBaseTable";
import useApprovedPacksInfiniteQuery from "./usePublicPacksInfiniteQuery";

const usePacksList = () => {
  const [textSearch, setTextSearch] = useState<string>("");

  const { data, fetchNextPage, ...rest } =
    useApprovedPacksInfiniteQuery(textSearch);

  const handleTextSearch = useCallback(
    debounce((text: string) => setTextSearch(text), 500),
    [],
  );

  const columns: Column<ApprovedPack>[] = [
    { field: "name", label: "Name" },
    { field: "cover", label: "Cover" },
    { field: "creatorId", label: "Creator" },
    { field: "reviewStatus", label: "Status" },
    { field: "createdAt", label: "Created At" },
  ];

  return {
    data,
    fetchNextPage,
    ...rest,
    handleTextSearch,
    columns,
  };
};

export default usePacksList;
