import { useState } from "react";
import MsTable from "../../../../components/Widgets/Table";
import GiftCategoryModal from "./GiftCategoryModal";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { colType } from "../../../../Types";
import FloatAddButton from "../../../../components/Widgets/FloatAddButton";
import useCategoriesQuery from "../hooks/useCategoriesQuery";
import useAddCategoryMutation from "../hooks/useAddCategoryMutation";
import useRemoveCategoryMutation from "../hooks/useRemoveCategoryMutation";
import { GiftCategory } from "../../../../api/giftCategories";
import uploadFile from "../../../../utils/uploadFiles";
import useUpdateCategoryMutation from "../hooks/useUpdateCategoryMutation";

export type CategoryRow = {
  index: JSX.Element;
  name: string;
  numKlydos: number;
  image: JSX.Element;
  delete: JSX.Element;
};
function GiftCategories() {
  const { data: categories } = useCategoriesQuery();
  const { mutate: addCategoryMutation } = useAddCategoryMutation();
  const { mutate: removeCategoryMutation } = useRemoveCategoryMutation();
  const { mutate: updateCategoryMutation } = useUpdateCategoryMutation();
  const [showAddGiftCategory, setShowAddGiftCategory] =
    useState<boolean>(false);
  const [giftToDelete, setGiftToDelete] = useState("");

  const tableColumns: Array<colType> = [
    { label: "index", field: "index", sort: false },
    { label: "category name", field: "name", sort: true },
    { label: "logo", field: "image", sort: false },
    { label: "num klydos", field: "numKlydos", sort: true },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];
  const onIndexChange = (id: string, newIndex: number) => {
    if (isNaN(newIndex)) return;
    const currentIndex = categories!.find((c) => c._id === id)!.index;
    const categoriesAndIndex = categories!.map((category) => ({
      _id: category._id,
      index: category.index,
    }));
    const [movedItem] = categoriesAndIndex.splice(currentIndex, 1);

    categoriesAndIndex.splice(newIndex, 0, movedItem);

    categoriesAndIndex.forEach((item, index) => {
      item.index = index;
    });
    const changedCategories = categoriesAndIndex.filter(
      (category) =>
        categories?.find((c) => c._id === category._id)?.index !==
        category.index,
    );
    changedCategories.forEach((category) => {
      updateCategoryMutation({
        _id: category._id,
        index: category.index,
      });
    });
  };
  const tableRows = (category: GiftCategory): CategoryRow => {
    return {
      index: (
        <input
          defaultValue={category.index}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => onIndexChange(category._id, +e.target.value)}
          style={{ width: "60px" }}
        />
      ),
      name: category.name,
      image: (
        <img width={100} src={category.categoryImageUrl} alt={category.name} />
      ),
      numKlydos: category.klydoIds?.length || 0,
      delete: (
        <FaTrash
          style={{ margin: "8px" }}
          className="nc-icon nc-simple-remove"
          onClick={(e) => {
            e.stopPropagation();
            setGiftToDelete(category._id);
          }}
        />
      ),
    };
  };

  const DeleteGiftModal = () => {
    return (
      <>
        {giftToDelete && (
          <Modal onHide={() => setGiftToDelete("")} show>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              "Are you sure you want to delete the Greet Category?"
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setGiftToDelete("")}>
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  removeCategoryMutation(giftToDelete);
                  setGiftToDelete("");
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  };

  const AddNewCategoryModal = () => {
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategoryIcon, setNewCategoryIcon] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const addNewCategory = async () => {
      if (!newCategoryName || !newCategoryIcon) {
        setShowWarning(true);
        return;
      }
      setShowWarning(false);
      addCategoryMutation({
        categoryImageUrl: newCategoryIcon,
        name: newCategoryName,
      });
    };
    return (
      <>
        <Modal.Header>
          <Modal.Title>Add New Greet Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showWarning && (
            <Alert variant="danger">
              Both Category Name and Category Icon are required!
            </Alert>
          )}
          <Form>
            <Form.Group controlId="formGreetCategoryName">
              <Form.Label>Git Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Greet category name"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGreetCategoryValue">
              <Form.Label>Category Icon</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = (e.target as HTMLInputElement)?.files?.[0];
                  if (file) {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                      uploadFile({
                        file: fileReader.result as string,
                        folder: "giftCategories",
                        upload_preset: "packpendulumconvers",
                      }).then((url) => setNewCategoryIcon(url));
                    };
                    fileReader.readAsDataURL(file);
                  }
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddGiftCategory(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              addNewCategory();
              setShowAddGiftCategory(false);
            }}
          >
            Add Gift Category
          </Button>
        </Modal.Footer>
      </>
    );
  };

  return (
    <>
      {categories && (
        <>
          <MsTable
            id="Greet Categories"
            data={categories}
            cols={tableColumns}
            rowBuild={tableRows}
            title="Greet Categories"
            search={(row: CategoryRow, val: string) => {
              return row.name.includes(val);
            }}
            cancelSort
            modal={GiftCategoryModal}
          />
          <FloatAddButton onClick={() => setShowAddGiftCategory(true)} />
        </>
      )}
      <DeleteGiftModal />
      {showAddGiftCategory && (
        <Modal
          show={true}
          size="xl"
          onHide={() => setShowAddGiftCategory(false)}
        >
          <AddNewCategoryModal />
        </Modal>
      )}
    </>
  );
}

export default GiftCategories;
