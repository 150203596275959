import DSCircularProgress from "../../system-design/DSCircularProgress";
import DSBox from "../../system-design/DSBox";
import usePacksList from "../hooks/usePacksList";
import DSTypography from "../../system-design/DSTypography";
import DSTextField from "../../system-design/DSTextField";
import { DSBaseTable, DSTableCell } from "../../system-design/Table";
import { ApprovedPack } from "../../../Types";
import { Column } from "../../system-design/Table/DSBaseTable/components/DSBaseTable";
import PacksListAvatar from "./PacksListAvatar";
import { useState } from "react";
import PackDialog from "./PackDialog";
import DSPrimaryButton from "../../system-design/DSPrimaryButtom";
import ObjectID from "bson-objectid";

const ApprovedPacks = () => {
  const {
    data: packs,
    fetchNextPage,
    isLoading,
    handleTextSearch,
    columns,
  } = usePacksList();

  const [chosenPack, setChosenPack] = useState("");

  const buildTableRows = (
    pack: ApprovedPack,
    columns: Column<ApprovedPack>[],
  ) => (
    <>
      {columns.map((col) => (
        <DSTableCell
          key={col.field as string}
          style={{
            width: col.size || `${100 / columns?.length}%`,
            whiteSpace: "nowrap",
          }}
        >
          {col.field === "cover" ? (
            <PacksListAvatar packCover={pack.cover} />
          ) : col.field === "reviewStatus" ? (
            pack.reviewStatus?.state || "N/A"
          ) : (
            String(pack[col.field])
          )}
        </DSTableCell>
      ))}
    </>
  );

  return (
    <>
      <DSBox mb={3} sx={{ marginRight: "20px" }}>
        <DSTextField
          fullWidth
          variant="outlined"
          placeholder="Search Packs"
          onChange={(e) => {
            handleTextSearch(e.target.value);
          }}
        />
      </DSBox>
      <DSPrimaryButton
        onClick={() => setChosenPack(new ObjectID().toHexString())}
        sx={{
          width: "110px",
        }}
      >
        Add Pack
      </DSPrimaryButton>
      {packs && !isLoading ? (
        <>
          <DSBaseTable
            columns={columns}
            onPressRow={({ id }: ApprovedPack) => {
              setChosenPack(id);
            }}
            rowBuilder={buildTableRows}
            fetchMore={fetchNextPage}
            data={packs}
          />
        </>
      ) : (
        !isLoading && (
          <DSTypography variant="body1" textAlign="center">
            <p>No Packs found</p>
          </DSTypography>
        )
      )}
      {isLoading && (
        <DSBox
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DSCircularProgress />
        </DSBox>
      )}
      {chosenPack && (
        <PackDialog
          fullWidth
          maxWidth="xl"
          packId={chosenPack}
          open={!!chosenPack}
          onClose={() => setChosenPack("")}
        />
      )}
    </>
  );
};

export default ApprovedPacks;
