import React, { useEffect, useState } from "react";
import DSDialog from "../../system-design/DSDialog";
import PackForm from "./PackForm";
import DSDialogActions from "../../system-design/DSDialogActions";
import DSSecondaryButton from "../../system-design/DSSecondaryButton";
import DSPrimaryButton from "../../system-design/DSPrimaryButtom";
import usePack from "../hooks/usePack";
import uploadFile from "../../../utils/uploadFiles";
import { useUpdateApprovedPackMutation } from "../hooks/useUpdatePackMutation";
import DSButton from "../../system-design/DSButtom";
import DSBox from "../../system-design/DSBox";
import useRemovePackMutation from "../hooks/useRemovePackMutation";
import DSDialogContent from "../../system-design/DSDialogContent";

const PackDialog = ({
  packId,
  onClose,
  ...rest
}: { packId: string; onClose: () => void } & Omit<
  React.ComponentProps<typeof DSDialog>,
  "onClose"
>) => {
  const { mutateAsync: upsertPack } = useUpdateApprovedPackMutation();
  const { mutateAsync: deletePack } = useRemovePackMutation();
  const { data: pack, isLoading } = usePack(packId);
  const [name, setName] = useState(pack?.name || "");
  const [description, setDescription] = useState(pack?.description || "");
  const [cover, setCover] = useState(pack?.cover || "");
  const [logo, setLogo] = useState(pack?.logo || "");
  const [klydosIds, setKlydosIds] = useState<string[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [userCreator, setUserCreator] = useState<string>(pack?.creatorId || "");
  const handleFileChange = (
    folder: string,
    event: React.ChangeEvent<HTMLInputElement>,
    callBack: (url: string) => void,
  ) => {
    const file = event.target.files?.[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      uploadFile({
        file: reader.result as string,
        folder: folder,
      }).then((url) => {
        callBack(url);
      });
    };
    reader.readAsDataURL(file!);
  };
  useEffect(() => {
    setName(pack?.name || "");
    setDescription(pack?.description || "");
    setCover(pack?.cover || "");
    setLogo(pack?.logo || "");
    setKlydosIds(pack?.klydoIds || []);
  }, [pack]);
  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <DSDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DSDialogContent>Are you sure?</DSDialogContent>
        <DSDialogActions>
          <DSSecondaryButton onClick={() => setIsDeleteDialogOpen(false)}>
            No
          </DSSecondaryButton>
          <DSPrimaryButton
            onClick={() => {
              deletePack(packId);
              onClose();
            }}
          >
            Yes
          </DSPrimaryButton>
        </DSDialogActions>
      </DSDialog>
      <DSDialog {...rest}>
        <PackForm
          logo={logo}
          cover={cover}
          name={name}
          description={description}
          klydosIds={klydosIds}
          setName={setName}
          setDescription={setDescription}
          setCover={setCover}
          setLogo={setLogo}
          handleFileChange={handleFileChange}
          setKlydosIds={setKlydosIds}
          onUserSelect={(user) => setUserCreator(user)}
          creatorId={userCreator}
        />
        <DSDialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <DSButton
            onClick={() => setIsDeleteDialogOpen(true)}
            color="error"
            variant="contained"
          >
            Delete
          </DSButton>
          <DSBox>
            <DSSecondaryButton onClick={() => onClose?.()}>
              close
            </DSSecondaryButton>
            <DSPrimaryButton
              onClick={() => {
                upsertPack({
                  id: packId,
                  name,
                  description,
                  cover,
                  logo,
                  klydoIds: klydosIds,
                  creatorId: userCreator,
                });
                onClose?.();
              }}
            >
              Save
            </DSPrimaryButton>
          </DSBox>
        </DSDialogActions>
      </DSDialog>
    </>
  );
};
export default PackDialog;
