import { useEffect, useRef } from "react";

type UseScrollPaginationProps = {
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  dependencies: any[];
};

const useScrollPagination = ({
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  dependencies,
}: UseScrollPaginationProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollRef.current || isFetchingNextPage) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      if (scrollHeight - scrollTop <= clientHeight + 5 && hasNextPage) {
        fetchNextPage();
      }
    };

    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasNextPage, fetchNextPage, isFetchingNextPage, ...dependencies]);

  return { scrollRef };
};
export default useScrollPagination;
