import { resizeCloudinary } from "../../../../utils/util";
import DSBox from "../../../system-design/DSBox";
import DSCircularProgress from "../../../system-design/DSCircularProgress";
import useKlydoReviewsContainer from "../hooks/useKlydoReviewsContainer";
import DSTypography from "../../../system-design/DSTypography";
import { Klydo, KlydoReviewStates } from "../../../../api/klydo";
import DSTextField from "../../../system-design/DSTextField";
import KlydoLoopUrl from "../../components/KlydoLoopUrl";
import { DSBaseTable, DSTableCell } from "../../../system-design/Table";
import { Column } from "../../../system-design/Table/DSBaseTable/components/DSBaseTable";
import KlydoReviewModal from "./KlydoReviewModal";
import { useCallback, useState } from "react";
import { useTheme } from "@mui/material";
import DSSwitch from "../../../system-design/DSSwitch";

const KlydoReviewsContainer = () => {
  const [onlyPending, setOnlyPending] = useState(false);
  const { klydos, isLoading, fetchNextPage, handleFreeSearch, columns } =
    useKlydoReviewsContainer();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Klydo | null>(null);
  const onPressRow = useCallback(
    (row: Klydo) => {
      setIsModalOpen(true);
      setSelectedRow(row);
    },
    [setIsModalOpen, selectedRow],
  );
  const { spacing } = useTheme();

  const buildTableRows = (klydo: Klydo, columns: Column<Klydo>[]) => (
    <>
      {columns.map((col) => (
        <DSTableCell
          key={col.field as string}
          style={{
            width: col.size || `${100 / columns?.length}%`,
            whiteSpace: "nowrap",
          }}
        >
          {col.field === "loopUrl" ? (
            <DSBox
              sx={{
                width: spacing(10),
                height: spacing(10),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <KlydoLoopUrl loopUrl={resizeCloudinary(klydo.loopUrl, 96)} />
            </DSBox>
          ) : col.field === "reviewStatus" ? (
            klydo.reviewStatus?.state || "N/A"
          ) : (
            String(klydo[col.field])
          )}
        </DSTableCell>
      ))}
    </>
  );

  return (
    <DSBox>
      <DSBox mb={3}>
        <DSTypography variant="h6" mb={1}>
          Free Search
        </DSTypography>
        <DSTextField
          fullWidth
          variant="outlined"
          placeholder="Happy Klydo"
          onChange={(e) => {
            handleFreeSearch(e.target.value);
          }}
        />
        <DSBox mt={1}>
          <DSSwitch
            title="Only Pending"
            checked={onlyPending}
            onChange={(e) => setOnlyPending(e.target.checked)}
          />
          <DSTypography variant="caption">
            Only show pending reviews
          </DSTypography>
        </DSBox>
      </DSBox>
      {klydos ? (
        <DSBaseTable
          data={klydos.filter((klydo) =>
            onlyPending
              ? klydo.reviewStatus?.state === KlydoReviewStates.Pending
              : true,
          )}
          columns={columns}
          rowBuilder={buildTableRows}
          fetchMore={fetchNextPage}
          onPressRow={onPressRow}
        />
      ) : (
        !isLoading && (
          <DSTypography variant="body1" textAlign="center">
            <p>No Klydos found in review</p>
          </DSTypography>
        )
      )}
      {isLoading && (
        <DSBox
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DSCircularProgress />
        </DSBox>
      )}
      <KlydoReviewModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        row={selectedRow}
      />
    </DSBox>
  );
};

export default KlydoReviewsContainer;
