import { useEffect, useRef } from "react";
import { Klydo } from "../../../api/klydo";
import { useOnScreen } from "../../hooks";
import { parseCloudinaryUrl } from "../../../utils/util";

const secondsForMin = 3600;
const secondsForHour = 43200;
type UseKlydoPreviewProps = {
  klydo: Partial<Klydo>;
  minutesValue?: number;
  hoursValue?: number;
};

const useKlydoPreview = ({
  klydo,
  minutesValue,
  hoursValue,
}: UseKlydoPreviewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handMin = useRef<HTMLDivElement>(null);
  const handHour = useRef<HTMLDivElement>(null);
  const handHourS = useRef<HTMLDivElement>(null);
  const handMinS = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const loopUrl = parseCloudinaryUrl({
    url: klydo?.convertedLoopUrl || klydo?.loopUrl || "",
    isStatic: false,
    size: 200,
  });

  useEffect(() => {
    const updHands = () => {
      const dt = new Date();
      if (hoursValue !== undefined && minutesValue !== undefined) {
        dt.setHours(hoursValue);
        dt.setMinutes(minutesValue);
      }

      const seconds = dt.getMinutes() * 60 + dt.getSeconds();
      const dgmin = (seconds / secondsForMin) * 360;
      if (handMin.current) {
        handMin.current.style.transform = `rotate(${dgmin}deg)`;
      }
      if (handMinS.current) {
        handMinS.current.style.transform = `rotate(${dgmin}deg)`;
      }
      const hours = (dt.getHours() % 12) * 3600 + seconds;
      const dgh = (hours / secondsForHour) * 360;
      if (handHour.current) {
        handHour.current.style.transform = `rotate(${dgh}deg)`;
      }
      if (handHourS.current) {
        handHourS.current.style.transform = `rotate(${dgh}deg)`;
      }
    };
    updHands();
  }, [isVisible, minutesValue, hoursValue]);

  return {
    ref,
    handMin,
    handHour,
    handHourS,
    handMinS,
    isVisible,
    loopUrl,
    handsColor: klydo?.theme?.handsColor || "",
    backgroundColor: klydo?.theme?.backgroundColor || "",
    pendulumColor: klydo?.theme?.pendulumColor || "",
    pendulumRodColor: klydo?.theme?.pendulumRodColor || "",
    dialsColor: klydo?.theme?.dialsColor || "",
  };
};
export default useKlydoPreview;
